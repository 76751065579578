import EmployerPendingsUI from '../../../dictionaries/EmployerPendingsUI.dic';
import Ui3DotsMenu from '../../../dictionaries/Ui3DotsMenu.dic';
import {
  NOT
} from '../../../lib/Boolean.lib';
import Core from '../../../lib/Core';
import Definition, {
  ACCOUNT_ACTION__EDIT_JOB,
  ACCOUNT_ACTION__EMPLOYER_PENDINGS,
  ACCOUNT_ACTION__LIST_ENGAGEMENTS,
  ACCOUNT_ACTION__MATCH_JOB
} from '../../../lib/Definition';
import Engagement from '../../../lib/Engagement';
import Job from '../../../lib/Job';
import {
  getJobModel
} from '../../../lib/models/job';
import { compileText } from '../../../lib/String.lib';
import copyHtml from '../../../lib/tools/copyHtml';
import {
  matchLocation,
  reloadLocation
} from '../../../lib/URL.lib';
import openDialogAccountEmployerSelector from '../../Accounts/Libraries/openDialogAccountEmployerSelector.fun';
import TriggerWaitingEmployerSummary from '../../Employers/EmployerPendings/TriggerWaitingEmployerSummary';
import {
  openCloseJob
} from '../../Engagements/CloseJob';
import Button from '../../Layout/Wrappers/Button';
import Typography from '../../Layout/Wrappers/Typography';

export const moreMenuOptionsJobs = [
  {
    acl: (job) => (
      Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_JOB }) &&
      NOT(matchLocation(/job\/(create|edit)/i))
    ),
    label: 'Edit Job',
    to: (job) => (`/job/edit/${job.id}`),
  },
  {
    acl: (job) => (
      Core.isAdmin({ action: ACCOUNT_ACTION__MATCH_JOB }) &&
      NOT(matchLocation(/\/(create|edit|match)/i))
    ),
    to: job => (`/job/match/${job.id}`),
    label: 'Match',
  },
  {
    acl: (job) => Core.isAdmin({ action: ACCOUNT_ACTION__EMPLOYER_PENDINGS }),
    label: 'Employer Portal',
    to: (job) => (`/employer/pendings/?employerId=${job.employerId}`),
    target: (job) => '_blank',
  },
  {
    acl: () => Core.isAdmin(),
    label: EmployerPendingsUI.urlGeneratorDialog.title,
    action: (job) => openDialogAccountEmployerSelector({ employerId: job.employerId })
  },
  {
    acl: (job) => Core.isAdmin(),
    render: (job, { key, ...props }) => (
      <TriggerWaitingEmployerSummary {...props}
        employerId={job.employerId}
        type='MenuItem'
      />
    )
  },
  {
    acl: job => Core.isAdmin({ action: ACCOUNT_ACTION__LIST_ENGAGEMENTS }),
    label: job => `Engagements Details${job.engagements?.length ? `(${job.engagements.length})` : ''}`,
    action: job => job.openEngagementsDetails(),
  },
  {
    acl: job => Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_JOB }),
    label: 'Clone',
    action: cloneJob,
  },
  {
    label: 'Copy JD - long',
    action: copyJobLarge
  },
  {
    label: 'Copy JD - short',
    action: copyJobShort
  },
  {
    acl: candidate => Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_JOB }),
    label: 'Close Job',
    action: job => openCloseJob({ jobId: job.id }),
  },
  {
    acl: job => Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_JOB }),
    label: (
      <Typography className='c-red'>
        {compileText(Ui3DotsMenu.Common.deleteEntity, {
          ENTITY: matchLocation(/\/(create|edit)/i) ? '' : Ui3DotsMenu.Job.ENTITY
        })}
      </Typography>
    ),
    action: job => deleteJob(job)
  }
];

export function copyJobLarge(job) {
  copyHtml(Job.getPreview(job, 'large'))
    .then((em) => {
      Core.log('Copy email command was successful');
      Core.showSuccess(
        'Copied - Job Description copied for you to paste elsewhere!'
      );
    })
    .catch((ex) => {
      Core.log("Oops, unable to copy");
      Core.showError("Fail copy!");
    })
}

export function copyJobShort(job) {
  copyHtml(Job.getPreview(job, 'short'))
    .then((em) => {
      Core.log('Copy email command was successful');
      Core.showSuccess('Copied - Job Description copied for you to paste elsewhere!');
    })
    .catch((ex) => {
      Core.log("Oops, unable to copy");
      Core.showError("Fail copy!");
    })
}

function getEditJobModel(job) {
  const newJobModel = {};
  Object.keys(getJobModel()).forEach((key) => {
    if (
      typeof job[key] === "boolean" ||
      key === "visaTransfer" ||
      !!job[key]
    ) {
      newJobModel[key] = job[key];
    }
  });
  delete newJobModel.id;
  newJobModel.state = Definition.getId("state", "Draft");
  Core.log({ newJobModel });
  return newJobModel;
}

function cloneJob(job) {
  let _job = { ...job };
  delete _job.createdAt;
  delete _job.updatedAt;
  Job.post(
    getEditJobModel(_job),
    (response) => Core.go({ to: `/job/edit/${response.id}` })
  );
}

function deleteJob(job) {
  Engagement.getWhere({ jobId: job.id }).then(
    engagements =>
      Core.dialog.open({
        title: 'Confirm',
        message: `Delete "${job._name}"${!!engagements.length
          ? ` and ${engagements.length} engagement${engagements.length === 1 ? "" : "s"
          }?`
          : ""
          }`,
        paperStyle: { width: sizes.m6 },
        actions: [
          <Button outlined minW120
            onClick={Core.dialog.close}
          >
            Cancel
          </Button>,
          <Button primary minW120
            onClick={
              ev =>
                Job.delete(job.id,
                  response => {
                    Core.showSuccess(
                      "Job was deleted successfully"
                    );
                    reloadLocation();
                  },
                  error => {
                    if (!!error) {
                      Core.showWarning('Can not delete job. Please contact support.');
                    }
                  }
                )
            }
          >
            Delete
          </Button>

        ]
      })
  )
}