import {
  sanitizeArr
} from '../../../lib/Array.lib';
import {
  NOT
} from '../../../lib/Boolean.lib';
import Definition, {
  DEFINITION_CATEGORY__NEGATIVE_SIGNALS,
  DEFINITION_CATEGORY__POSITIVE_SIGNALS
} from '../../../lib/Definition';
import useState from '../../../lib/hooks/useState.hook';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  matchLocation
} from '../../../lib/URL.lib';
import Box from '../../Layout/Wrappers/Box';
import Menu from '../../Layout/Wrappers/Menu';
import {
  MatchPage__addKeyword,
  MatchPage__removeKeyword,
  MatchPageController
} from '../MatchPage';


/**
 * MenusSignals
 * @param {object} props - Component props
 * @param {boolean} [props.acl=matchLocation(/job\/match/i)] - Whether to show this component based on the current location
 * @returns {ReactElement} - A component that renders two menus of positive and negative signals.
 *
 * This component renders two menus of positive and negative signals, which are used to filter the job/candidate list.
 * The menus are populated with all the possible values from the DEFINITION_CATEGORY__POSITIVE_SIGNALS and DEFINITION_CATEGORY__NEGATIVE_SIGNALS categories.
 * When the user changes the value of a menu, the state is updated with the new value, and then goes through each option in the menu.
 * If the option is in the new value and not in the previous value, it adds it as a keyword.
 * If the option is in the previous value and not in the new value, it removes it as a keyword.
 */
export default function MenusSignals({
  acl = matchLocation(/job\/match/i),
  ...props
}) {
  const [{
    menus
  }, updateState] = useState({
    menus: [
      {
        key: DEFINITION_CATEGORY__POSITIVE_SIGNALS,
        label: 'Positive signals',
        options: Definition.get(DEFINITION_CATEGORY__POSITIVE_SIGNALS),
      },
      {
        key: DEFINITION_CATEGORY__NEGATIVE_SIGNALS,
        label: 'Negative signals',
        options: Definition.get(DEFINITION_CATEGORY__NEGATIVE_SIGNALS),
      }
    ]
  });
  if (NOT(acl)) { return null; }

  // SELECTED POSITIVE SIGNALS
  menus[0].value = sanitizeArr(
    MatchPageController().state.keywords.map(
      (keyword) => Definition.getId(
        menus[0].key,
        keyword.name
      )
    )
  );

  // SELECTED NEGATIVE SIGNALS
  menus[1].value = sanitizeArr(
    MatchPageController().state.keywords.map(
      (keyword) => Definition.getId(
        menus[1].key,
        keyword.name
      )
    )
  );

  const _onChangeOptions = (menu) => async (value) => {
    const { options } = Obj(menu);
    const prevValue = sanitizeArr([...menu.value]);
    await updateState({ menus });
    options.forEach((tag) => {
      if (value.includes(tag.id)) {
        if (!prevValue.includes(tag.id)) {
          MatchPage__addKeyword(tag.label);
        }
      }
      else if (prevValue.includes(tag.id)) {
        MatchPage__removeKeyword(tag.label);
      }
    });
  };
  return (
    <Box row noWrap>
      <Menu filter multiple small
        label='Positive signals'
        value={menus[0].value}
        options={menus[0].options}
        onChange={_onChangeOptions(menus[0])}
        className='pointer mr-1 c-purple-hover w-auto'
      />
      <Menu filter multiple small
        label='Negative signals'
        value={menus[1].value}
        options={menus[1].options}
        onChange={_onChangeOptions(menus[1])}
        className='pointer mr-1 c-purple-hover w-auto'
      />
    </Box>
  );
}
