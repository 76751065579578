
import moment from "moment";
import {
  TIMEZONE__LA
} from '../../../lib/Constants';
import {
  formatDate
} from '../../../lib/Date.lib';
import {
  Fun
} from '../../../lib/Function.lib';
import useState from '../../../lib/hooks/useState.hook';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  REACT_TABLE__INPUT_RANGE_DATE
} from '../../Home/useEnhancedReactTable.hook';
import Box from './Box';
import DatePicker from './DatePicker';
import Menu from './Menu';
import Typography from './Typography';

export const DATE_FORMAT = `MM-DD-YYYY`;

/**
 * A range date picker that allows the user to select a range of dates within a year.
 *
 * @param {Object} props - The props object.
 * @param {Boolean} [props.v2=false] - Whether to use the version 2 of the component.
 * @param {Array} [props.values=[]] - The selected date range.
 * @param {Function} [props.onChange=Fun] - The function to call when the user selects a new date range.
 * @param {Function} [props.reference=Fun] - A function that receives an object with a reset function to call when the user wants to reset the date range.
 */
export default function DatePickerRange({
  v2 = false,
  values = [],
  onChange = Fun,
  reference = Fun
}) {

  // INITIAL STATE
  const yearOptionId = findYearOptionId({
    startDate: values[0],
    endDate: values[1]
  });
  const monthOptionId = findMonthOptionId({
    yearOptionId,
    startDate: values[0],
    endDate: values[1]
  });

  // STATE
  const [state, updateState] = useState({
    yearOptionId,
    monthOptionId
  });


  /**
   * Creates a function that can be used as an onChange handler for a DatePicker
   * component. The function updates the `values` array by setting the value at
   * the specified `index` to the provided `value`.
   *
   * @param {Number} index - The index of the value to update.
   * @returns {Function} A function that can be used as an onChange handler.
   */
  const _onChange = (index) => (value) => {
    values = values.map(value => ((value === undefined) ? null : value));
    values[index] = value;
    onChange(values);
  };


  /**
   * Updates the state with the provided year and month option IDs,
   * generates month options for the given year, and triggers the
   * onChange callback with the start and end dates of the matching
   * month option.
   *
   * @async
   * @param {Object} params - The parameters object.
   * @param {string} params.yearOptionId - The ID of the year option to update.
   * @param {string} params.monthOptionId - The ID of the month option to find.
   */
  const _onChangeOption = async ({
    yearOptionId,
    monthOptionId
  }) => {
    await updateState({
      yearOptionId: yearOptionId,
      monthOptionId: monthOptionId
    });
    const monthOptions = generateMonthOptions(yearOptionId);
    const monthOption = Obj(
      monthOptions.find(
        (monthOption) => (
          monthOption.id === monthOptionId
        )
      )
    );
    onChange([
      monthOption.startDate,
      monthOption.endDate
    ]);
  }

  const _reset = async () => {
    await _onChangeOption(state.yearOptionId)(null);
  }

  // GENERATE OPTIONS
  const yearOptions = generateYearOptions();
  const monthOptions = generateMonthOptions(state.yearOptionId);


  // REFERENCE (exposed methods)
  reference({
    reset: _reset
  });

  // RENDER
  return (
    (
      v2 === true
    ) ? (
      // V2 (new)
      <Box column w100 scrollX>
        <Box row noWrap>
          <Menu dropdown
            options={yearOptions}
            value={state.yearOptionId}
            onChange={(yearOptionId) => _onChangeOption({
              yearOptionId,
              monthOptionId: state.monthOptionId
            })}
            className='mr-1 min-w-120'
          />
          <Menu dropdown
            disabled={!state.yearOptionId}
            options={monthOptions}
            value={state.monthOptionId}
            onChange={(monthOptionId) => _onChangeOption({
              yearOptionId: state.yearOptionId,
              monthOptionId
            })}
            className='min-w-120'
          />
        </Box>
        <Box row noWrap>
          <Typography sub
            className='m-05'
          >
            Selected Range:
          </Typography>
          <Typography bold
            className='m-05'
          >
            {formatDate(values[0])} - {formatDate(values[1])}
          </Typography>
        </Box>
      </Box>
    ) : (
      // V1 (legacy)
      <Box row noWrap scroll>
        <DatePicker
          placeholder={`${moment().subtract(185, 'days').format(DATE_FORMAT)}`}
          value={values[0]}
          onChange={_onChange(0)}
          className="mr-1"
          style={{
            minWidth: REACT_TABLE__INPUT_RANGE_DATE
          }}
        />
        <Typography mr>to</Typography>
        <DatePicker
          placeholder={`${moment().add(1, 'days').format(DATE_FORMAT)}`}
          value={values[1]}
          onChange={_onChange(1)}
          className="mr-1"
          style={{
            minWidth: REACT_TABLE__INPUT_RANGE_DATE
          }}
        />
      </Box>
    )
  );
}


/**
 * Generates year options for the Year dropdown in the Date Range picker.
 * For each of the last 10 years, generates two options: H1 and H2.
 * H1 is the first half of the year (January to June), and H2 is the second half of the year (July to December).
 * 
 * @returns {Array} An array of objects, each containing:
 *  - {string} id: The ISO string representing the start date of the half year.
 *  - {string} label: A label describing the year and half year (e.g. 2020 H1).
 */
export function generateYearOptions() {
  const today = moment().tz(TIMEZONE__LA);
  const options = [];
  for (let i = 0; i <= 9; i++) {
    const currentYearDate = today.clone().subtract(i, 'years');
    const firstHalfDate = currentYearDate.clone().startOf('year');
    const secondHalfDate = firstHalfDate.clone().add(6, 'months');

    // First half (H1)
    options.push({
      id: firstHalfDate.toISOString(),
      label: `${firstHalfDate.year()} H1`,
    });

    // Second half (H2)
    options.push({
      id: secondHalfDate.toISOString(),
      label: `${secondHalfDate.year()} H2`,
    });

  }
  return options;
};


/**
 * Generates options for month ranges based on a given start date.
 * Each option represents a range of months with an ID, label, 
 * start date, and end date.
 *
 * @param {string} yearOptionId - The start date in ISO format, used 
 * to calculate the month range options.
 * 
 * @returns {Array} An array of objects, each containing:
 *  - {number} id: The identifier for the month range.
 *  - {string} label: A label describing the range of months.
 *  - {string} startDate: The ISO string representing the start date of the range.
 *  - {string} endDate: The ISO string representing the end date of the range.
 */
export function generateMonthOptions(yearOptionId) {
  yearOptionId = moment.tz(yearOptionId, TIMEZONE__LA);
  return [
    {
      id: 1,
      label: '1-3 month',
      startDate: yearOptionId.toISOString(),
      endDate: yearOptionId.clone().add(3, 'months').subtract(1, 'day').toISOString()
    },
    {
      id: 2,
      label: '4-6 month',
      startDate: yearOptionId.clone().add(3, 'months').toISOString(),
      endDate: yearOptionId.clone().add(6, 'months').subtract(1, 'day').toISOString()
    },
    {
      id: 3,
      label: '1-6 month',
      startDate: yearOptionId.toISOString(),
      endDate: yearOptionId.clone().add(6, 'months').subtract(1, 'day').toISOString()
    },
  ];
};


/**
 * Determines the option ID for a year based on a given start date.
 * The year is divided into two halves: H1 (January to June) and H2 (July to December).
 * 
 * @param {Object} options - Options object.
 * @param {string} options.startDate - The start date in ISO format. Defaults to the start of the current year.
 * 
 * @returns {string} The ISO string representing the start date of either the first or second half of the year.
 */
export function findYearOptionId({
  startDate = moment().tz(TIMEZONE__LA).startOf('year').toISOString(),
}) {
  startDate = moment.tz(startDate, TIMEZONE__LA);
  const firstHalfYearDate = startDate.clone().startOf('year');
  const secondHalfYearDate = firstHalfYearDate.clone().add(6, 'months');
  return (
    startDate.isBefore(secondHalfYearDate)
      ? firstHalfYearDate.toISOString()
      : secondHalfYearDate.toISOString()
  );
}


/**
 * Finds the month option id that corresponds to the specified start and end dates
 * within a given year's options.
 *
 * @param {object} params - The parameters object.
 * @param {string} params.yearOptionId - The ID of the year option to generate month options from.
 * @param {string} params.startDate - The start date to match against the month options.
 * @param {string} params.endDate - The end date to match against the month options.
 * @returns {number|null} The ID of the matching month option, or null if no match is found.
 */
export function findMonthOptionId({
  yearOptionId,
  startDate,
  endDate
}) {
  startDate = moment.tz(startDate, TIMEZONE__LA);
  endDate = moment.tz(endDate, TIMEZONE__LA);
  const monthOptions = generateMonthOptions(yearOptionId);
  const result = Obj(monthOptions.find((monthOption) => (
    (moment.tz(monthOption.startDate, TIMEZONE__LA).month() === startDate.month()) &&
    (moment.tz(monthOption.endDate, TIMEZONE__LA).month() === endDate.month())
  ))).id;
  console.debug(
    'monthOptions',
    { startDate: startDate.month(), endDate: endDate.month() },
    monthOptions.flat(),
    result
  );
  return result;
}
