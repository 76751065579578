import {
  join
} from '../../../../lib/Array.lib';
import Core from '../../../../lib/Core';
import Employer from '../../../../lib/Employer';
import {
  compileText
} from '../../../../lib/String.lib';
import copyHtml from '../../../../lib/tools/copyHtml';
import getHtmlLinkEmployerPendings from './getHtmlLinkEmployerPendings.fun';

export default async function copySummaryEmployerPendings({
  accountId,
  employerId
}) {
  const result = [];
  const data = await Employer.getPendings({ employerId });
  data.processedJobs.forEach((processedJob) => {
    processedJob.processedEngagements.forEach((processedEngagement) => {
      processedEngagement.engagements.forEach((engagement) => {
        result.push(
          compileText(
            '{{CANDIDATE__FULLNAME}}{{RATING_FLAG}}, {{JOB_TITLE}} - {{ENGAGEMENT_LABEL}}{{SUBMITTED_LABEL}}',
            {
              CANDIDATE__FULLNAME: engagement.candidateName,
              RATING_FLAG: engagement.ratingFlag,
              JOB_TITLE: engagement.jobTitle,
              ENGAGEMENT_LABEL: join(
                [
                  /**
                   * @note
                   * LABEL is built based on the `msg` template from...
                   * overriding `getEmployerProcessingModels`...
                   * for the `getEngagementPendings` endpoint.
                   * @see https://github.com/10By10/10by10-web-app/blob/19cc8f88ef8923c9b180ab88b2e0a225a33d48f0/common/Helpers/emails/getEmployerProcessingModels.function.js#L29
                   * @see https://github.com/10By10/10by10-web-app/blob/4fdcf659211aada54a06af4b38ca30a7b1d00fe3/common/Helpers/emails/employerTodoReminder.helper.js#L138
                   */
                  engagement.label,
                  (
                    engagement.agoDateB
                  ) ? (
                    // DAYS IF agoDate LESS THAN overdueDays
                    `${engagement.agoDateB}d ago`
                  ) : (
                    // DAYS (calculated from date)
                    !!engagement.agoDate && ` ${engagement.agoDate}d ago`
                  ),
                  // INTERVIEW DATE? format: D/M
                  engagement.date && `(${engagement.date})`
                ],
                ' '
              ),
              SUBMITTED_LABEL: (
                (
                  // SUBMITTED DATE
                  engagement.submittedLabel
                ) ? (
                  ` ${engagement.submittedLabel}`
                ) : (
                  ''
                )
              ),
            }
          )
        );
      });
    });
  });
  const joinedResult = result.join('<br/>');
  if (joinedResult) {
    await copyHtml(
      join(
        [
          await getHtmlLinkEmployerPendings({ accountId }),
          '<br/>',
          ...result,
        ],
        '<br/>'
      )
    );
    console.debug('Copied', joinedResult);
    Core.showSuccess('Copied!');
  }
  else {
    Core.showWarning('Nothing to do to copy!');
  }
}
