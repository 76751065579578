import Ui3DotsMenu from '../../../dictionaries/Ui3DotsMenu.dic';
import Core from '../../../lib/Core';
import {
  ACCOUNT_ACTION__EDIT_ACCOUNT
} from '../../../lib/Definition';
import { compileText } from '../../../lib/String.lib';
import { matchLocation } from '../../../lib/URL.lib';
import Typography from '../../Layout/Wrappers/Typography';

export const moreMenuOptionsAccounts = [
  {
    acl: account => (Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_ACCOUNT }) || (Core.isRecruiter() && Core.isOnDev())),
    to: account => (`/account/edit/${account.id}`),
    label: (
      <Typography>
        {compileText(Ui3DotsMenu.Common.editEntity, {
          ENTITY: matchLocation(/\/(create|edit)/i) ? '' : Ui3DotsMenu.Account.ENTITY
        })}
      </Typography>
    ),
  }
];
