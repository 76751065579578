import Fieldset from '../../Layout/Wrappers/Fieldset';
import RichTextBox from '../../Layout/Wrappers/RichTextBox';
import {
  CANDIDATE_UI
} from '../CandidateUI.dic';
import {
  CandidateEditController
} from '../Edit/CandidateEdit';

export default function NoteToAccountManagerFieldset({
  ...props
}) {
  return (
    <Fieldset
      title={CANDIDATE_UI.noteToAccountManager.title}
      info={CANDIDATE_UI.noteToAccountManager.placeholder}
      {...props}
    >
      <RichTextBox
        name='noteToAccountManager'
        placeholder={CANDIDATE_UI.noteToAccountManager.placeholder}
        value={CandidateEditController().state.noteToAccountManager}
        onChange={value => {
          CandidateEditController().setStateStore({
            noteToAccountManager: value
          });
        }}
      />
    </Fieldset>
  );
}
