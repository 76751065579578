import {
  compile
} from 'handlebars';
import {
  Arr
} from '../../../../lib/Array.lib';
import Core from '../../../../lib/Core';
import {
  REC_MSG_TYPE__ALL_ID,
  REC_MSG_TYPE__MESSAGE_ID
} from '../../../../lib/Definition';
import globalErrorHandler from '../../../../lib/Error/globalErrorHandler.fun';
import {
  getHTMLLinkString
} from '../../../../lib/HTML.lib';
import {
  getPersonName,
  Obj
} from '../../../../lib/Object.lib';
import {
  COLLECTION__ACCOUNTS,
  COLLECTION__CANDIDATES,
  readLoopbackRecord
} from '../../../../lib/services/BE/loopback.api';
import {
  mapContactsToStrings,
  mapEmailsListToRecipients,
  sendSafeEmail
} from '../../../../lib/services/Email/Email.lib';
import {
  compileText
} from '../../../../lib/String.lib';
import {
  EmployerPendings_updateState
} from '../EmployerPendings';
import {
  ACTION_TYPE__FEEDBACK_TO_RECRUITER
} from './EmployerPendings.dic';
import {
  onFeedbackSent
} from './EmployerPendings.lib';
import prependLogEngagementFeedbackToRecruiter from './prependLogEngagementFeedbackToRecruiter.fun';


/**
 * Send a feedback email to the recruiter, which includes the feedback link.
 *
 * @param {object} options - Options for the function.
 * @param {string} options.actionType - The type of action, e.g. ACTION_TYPE__FEEDBACK_TO_RECRUITER.
 * @param {object} options.selected - The current selected engagement.
 * @param {string} options.feedback.text - The feedback text.
 *
 * @returns {Promise<void>} The result of the email sending operation.
 * 
 * Note: This function will first try to send the email to the recruiter, and if successful, it will then
 * prepend the log entry to the recruiter's feedback text.
 */
export default async function sendOnlyFeedbackToRecruiterEmail(options) {
  // console.debug('sendOnlyFeedbackToRecruiterEmail', options);
  try {
    let {
      actionType = ACTION_TYPE__FEEDBACK_TO_RECRUITER,
      selected,
      feedback,
    } = options;

    if (!feedback.text) {
      return Core.showWarning('Type some feedback to share with us');
    }

    let context = {
      ...options,
      // we need to preserve current selected in context since user can go to select a diff one before process done
      selected
    };

    // GETTING RECRUITER RECIPIENTS
    const recruiter = Obj(
      await readLoopbackRecord({
        collection: COLLECTION__ACCOUNTS,
        where: { id: selected.recruiterId },
        fields: ['id', 'emailsList', 'email', 'firstName', 'lastName'],
        limit: 1
      })
    );
    const candidateRecruiterContacts = Arr(
      Obj(
        await readLoopbackRecord({
          collection: COLLECTION__CANDIDATES,
          where: { id: selected.candidateId },
          fields: ['id', 'emailsList'],
          limit: 1
        })
      ).emailsList
    );
    recruiter.emailsList = [...recruiter.emailsList, ...candidateRecruiterContacts];
    const recipients = mapEmailsListToRecipients({
      primaryContact: {
        name: getPersonName(recruiter),
        email: recruiter.email
      },
      emailsList: Arr(
        recruiter?.emailsList
      ),
      messageTypes: [
        REC_MSG_TYPE__ALL_ID,
        REC_MSG_TYPE__MESSAGE_ID
      ],
    });

    if (
      !recipients.emails.length
    ) {
      return Core.showWarning('No recruiter email found');
    }

    // TO RECRUITER sending email
    const emailSent = await sendSafeEmail({
      to: mapContactsToStrings(recipients.to),
      cc: mapContactsToStrings(recipients.cc),
      bcc: mapContactsToStrings(recipients.bcc),
      subject: compileText('[ 10x10 ] {{NAME__CANDIDATE}} additional feedback notification', {
        NAME__CANDIDATE: getPersonName(selected.candidate)
      }),
      html: compile(`
        Feedback link: {{{HTML__LINK__CANDIDATE_VIEW}}}
        `)({
        HTML__LINK__CANDIDATE_VIEW: getViewCandidateHTML({
          candidateId: selected.candidateId
        })
      }),
      boxKey: selected.boxKey,
    });

    if (!!emailSent) {
      emailSent.actionType = actionType;
      onFeedbackSent({ context, emailSent });
    }

    // TO RECRUITER prepending log
    selected.toRecruiterFBText = Obj(
      await prependLogEngagementFeedbackToRecruiter({
        engagementId: selected.id,
        context: {
          ACTION: actionType,
          NOTE: context.feedback.text
        },
      })
    )['toRecruiterFBText'];

    // UPDATING STATE
    EmployerPendings_updateState({ selected });
  }
  catch (error) {
    globalErrorHandler(error);
  }
}

export function getViewCandidateHTML({ candidateId }) {
  return getHTMLLinkString({
    url: Core.getPath(
      `candidate/view/${candidateId}`
    ),
    name: 'View Candidate'
  });
}
