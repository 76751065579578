import getJobEntityName from '../../../lib/Job/getJobEntityName.fun';
import Box from '../../Layout/Wrappers/Box';
import TableCollection, {
  KEY__TABLE_COLLECTION__VIEW_TYPE__JOB
} from '../../Layout/Wrappers/TableCollection';

export function JobEngagementsDetails({
  job = {},
  isDrawer = false,
}) {
  return (
    <TableCollection
      where={{ jobId: job.id }}
      title={
        <Box row w100 noWrap className='bg-white f-lg p-2'>
          <div className='fw-600 c-cyan-darker mr-1 nowrap'>
            Engagements for job:
          </div>
          <div className='c-black'>
            {getJobEntityName(job)}
          </div>
        </Box>
      }
      isDrawer={isDrawer}
      viewType={KEY__TABLE_COLLECTION__VIEW_TYPE__JOB}
    />
  );
}
