import Calendar from '../components/Calendar/Calendar';
import Candidates from '../components/Candidates/Candidates';
import MainConfiguration from '../components/Configuration/MainConfiguration';
import Employers from '../components/Employers/Employers';
import Engagements from '../components/Engagements/Engagements';
import EngagementsV3 from '../components/Engagements/EngagementsV3';
import ListPages from '../components/Home/ListPages';
import Jobs from '../components/Jobs/Jobs';
import JobsV3 from '../components/Jobs/JobsV3';
import TagManagement from '../components/Management/TagManagement';
import RecruiterHome from '../components/Recruiter/Home';
import Reports from '../components/Reports/Reports';
import Settings from '../components/Settings/Settings';
import Tools from '../components/Tools/Tools';
import Core from '../lib/Core';
import {
  ACCOUNT_ACTION__ACCESS_ADMIN_TOOLS,
  ACCOUNT_ACTION__CREATE_ANNOUNCEMENTS,
  ACCOUNT_ACTION__LIST_ACCOUNTS,
  ACCOUNT_ACTION__LIST_CANDIDATES,
  ACCOUNT_ACTION__LIST_EMPLOYERS,
  ACCOUNT_ACTION__LIST_ENGAGEMENTS,
  ACCOUNT_ACTION__LIST_JOBS,
  ACCOUNT_ACTION__MANAGE_CONFIGURATIONS,
  ACCOUNT_ACTION__MANAGE_TAGS,
  ACCOUNT_ACTION__VIEW_REPORTS
} from '../lib/Definition';
import {
  NOT
} from '../lib/GenericTools.lib';

/**
 * @see 
 * Wrappers > Router
 * Home > Main
 */
export default function getMainRoutes() {
  const _routesV3 = [
    {
      path: '/pro/jobs',
      render: JobsV3
    },
    {
      path: '/v3/jobs',
      render: ListPages
    },
    {
      path: '/jobs',
      render: Jobs
    },
    {
      path: '/pro/engagements',
      render: EngagementsV3,
      acl: Core.isAdminOrRecruiterOnDev()
    },
    {
      path: '/v3/engagements',
      render: ListPages,
      acl: Core.isAdminOrRecruiterOnDev()
    },
    {
      path: '/v3/candidates',
      render: ListPages
    },
    {
      path: '/v3/employers',
      render: ListPages,
      acl: Core.isAdminOrCoordinator()
    },
        {
      path: '/matcher',
      render: ListPages,
      acl: Core.isAdminOrCoordinator()
    },
    {
      path: '/events',
      render: ListPages,
      acl: Core.isAdminOrCoordinator()
    },
  ];
  const _routesV1 = [
    {
      path: '/job/view/',
      render: Jobs
    },
    {
      path: '/candidate/view/',
      render: Candidates
    },
    {
      path: '/home',
      render: RecruiterHome,
      acl: Core.isTrusted()
    },
    {
      path: '/recruiter',
      render: RecruiterHome,
      acl: Core.isTrusted()
    },
    {
      path: '/engagements',
      render: Engagements,
      acl: Core.isAdminOrRecruiterOnDev()
    },
    {
      path: '/engagement/view/',
      render: Engagements,
      acl: Core.isAdminOrRecruiterOnDev()
    },
    {
      path: '/engagement/represent/',
      render: Engagements,
      acl: Core.isAdminOrRecruiterOnDev()
    },
    {
      path: '/candidates',
      render: Candidates
    },
    {
      path: '/accounts',
      render: ListPages,
      acl: Core.isAdminOrCoordinator()
    },
    {
      path: '/calendar',
      render: Calendar,
      acl: Core.isAdminOrCoordinator()
    },
    {
      path: '/employers',
      render: Employers,
      acl: Core.isAdminOrCoordinator()
    },
    {
      path: '/reports',
      render: Reports,
      acl: Core.isAdminOrCoordinator()
    },
    {
      path: '/admin/tools',
      render: Tools,
      acl: Core.isAdminOrCoordinator()
    },
    {
      path: '/settings',
      render: Settings
    },
    {
      path: '/management/tags',
      render: TagManagement,
      acl: Core.isAdmin({ action: ACCOUNT_ACTION__MANAGE_TAGS })
    },
    {
      path: '/configurations',
      render: MainConfiguration,
      acl: Core.isAdmin({ action: ACCOUNT_ACTION__MANAGE_CONFIGURATIONS })
    }
  ];
  return ([
    ..._routesV3,
    ..._routesV1
  ])
}

export function getNavigationList({ extended = false } = {}) {
  const _mainNavigationConfig = [
    {
      icon: 'home',
      label: 'Home',
      to: '/recruiter',
      acl: Core.isTrusted()
    },
    {
      icon: 'sync',
      label: 'Engagements',
      acl: (
        Core.isRecruiterOnDev() ||
        Core.isAdmin({ action: ACCOUNT_ACTION__LIST_ENGAGEMENTS })
      )
    },
    {
      icon: 'face',
      label: 'Candidates',
      acl: (
        NOT(Core.isAdmin()) ||
        Core.isAdmin({ action: ACCOUNT_ACTION__LIST_CANDIDATES })
      )
    },
    {
      icon: 'business_center',
      label: 'Jobs',
      acl: (
        NOT(Core.isAdmin()) ||
        Core.isAdmin({ action: ACCOUNT_ACTION__LIST_JOBS })
      )
    },
    {
      icon: 'business',
      label: 'Employers',
      acl: Core.isAdmin({ action: ACCOUNT_ACTION__LIST_EMPLOYERS })
    },
    {
      icon: 'people',
      label: 'Accounts',
      acl: Core.isAdmin({ action: ACCOUNT_ACTION__LIST_ACCOUNTS })
    },
    {
      icon: 'insert_chart_outlined',
      label: 'Reports',
      acl: Core.isAdmin({ action: ACCOUNT_ACTION__VIEW_REPORTS }),
      divider: true
    }
  ];
  const _extendedNavigationConfig = [
    {
      icon: 'settings',
      label: 'Tag Management',
      to: '/management/tags',
      acl: Core.isAdmin({ action: ACCOUNT_ACTION__MANAGE_TAGS })
    },
    {
      icon: 'settings',
      label: 'Configurations',
      acl: Core.isAdmin({ action: ACCOUNT_ACTION__MANAGE_CONFIGURATIONS })
    },
    {
      icon: 'settings',
      label: 'Create Announcements',
      to: '/create/announcements',
      acl: Core.isAdmin({ action: ACCOUNT_ACTION__CREATE_ANNOUNCEMENTS })
    },
    {
      icon: 'settings',
      label: 'Admin Tools',
      to: '/admin/tools',
      acl: Core.isAdmin({ action: ACCOUNT_ACTION__ACCESS_ADMIN_TOOLS })
    },
    {
      icon: 'settings',
      label: 'Settings',
      divider: true
    },
    {
      icon: 'exit_to_app',
      label: 'Logout',
      onClick: (event) => Core.logout({ redirect: '/' }),
      divider: true
    }
  ];
  if (extended) {
    return [
      ..._mainNavigationConfig,
      ..._extendedNavigationConfig,
    ]
  }
  return _mainNavigationConfig
}
