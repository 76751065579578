import queryString from 'query-string';
import {
  Component
} from 'react';
import {
  Arr
} from '../../lib/Array.lib';
import {
  NOT,
  YES
} from '../../lib/Boolean.lib';
import Candidate from '../../lib/Candidate';
import {
  accountIncludeForCandidate,
  fieldsForCandidatePage
} from '../../lib/Constants';
import Core from '../../lib/Core';
import {
  ACCOUNT_ACTION__EDIT_CANDIDATE
} from '../../lib/Definition';
import {
  Obj
} from '../../lib/Object.lib';
import Store from '../../lib/Store';
import {
  getLocation,
  getParams,
  getSearch
} from '../../lib/URL.lib';
import {
  getVariableFromURLQuery
} from '../../lib/URLQuery.lib';
import onReady from '../../lib/tools/onReady';
import FilterControl from '../FilterControl/FilterControl';
import {
  THEME__COLOR__SECONDARY
} from '../Layout/Libraries/Theme.lib';
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import Checkbox from '../Layout/Wrappers/Checkbox';
import FloatingActionButton from '../Layout/Wrappers/FloatingActionButton';
import NavLink from '../Layout/Wrappers/NavLink';
import Snackbar from '../Layout/Wrappers/Snackbar';
import Switch from '../Layout/Wrappers/Switch';
import List, {
  ListController
} from '../List/List';
import {
  CandidatesExportCSV
} from './CandidatesExportCSV';
import CandoDuplicateDetails from './CandoDuplicateDetails';
import CandidateCard from './Card/CandidateCard';

export const KEY__CANDIDATES_LIST = 'candidates_list';

class Candidates extends Component {
  data;
  constructor() {
    super(...arguments);
    this.name = 'Candidates';
    this.state = {
      showAll: false,
      snackBarMessage: '',
      snackBarOpen: false,
      allCandidates: [],
      expanded: Core.isRecruiter(),
    };
    Store.set('path', getLocation());
    this.reloadData = (ev) => this.loadData();

    //for rake task
    this.founded = 0;
    this.notFounded = 0;
    this.passed = 0;
  }

  componentDidMount() {
    this.setState({ queryParams: queryString.parse(getSearch()) });
    setTimeout(() => this.loadData(), 111);
  }

  jsonEncode = (object) => {
    return JSON.stringify(object).replace('{', '%7B').replace('}', '%7D').replace(':', '%3A');
  };

  deleteCandidateFromCollection = (candId) => {
    const { allCandidates } = this.state;
    const candidatesWithDeleted = allCandidates.filter((candidate) => candidate.id !== candId);
    this.setState({ allCandidates: candidatesWithDeleted }, () => {
      this.filterControl && this.filterControl.setItems(candidatesWithDeleted);
    });
  };

  loadData() {
    const accountId = getVariableFromURLQuery('accountId');
    onReady(this, 'filterControl').then((em) => {

      // CANDIDATE VIEW
      const {
        candidateId
      } = getParams({
        pattern: '/candidate/view/:candidateId'
      });
      if (candidateId) {
        const opts = { commonQuery: { engagements: true } };
        const where = { id: candidateId };
        Candidate.getWhere(
          where,
          (candidates) => {
            const allCandidates = candidates
              .filter((candidate) => candidate.id === candidateId)
              .map((job) => {
                job.expanded = true;
                return job;
              });
            this.List.setItems(allCandidates);
            this.setState({
              allCandidates,
              expanded: true,
            });
          },
          opts
        );
        return;
      }

      // CANDIDATE LIST
      let params = queryString.parse(getSearch());
      let filters = params.count
        ? {
          filters: {
            include: [{ engagements: { job: 'employer' } }],
          },
        }
        : {
          filters: {
            fields: fieldsForCandidatePage
          },
        };
      this.setState({ params });
      if (this.state.showAll) {
        params = {
          include: [
            ...accountIncludeForCandidate,
            // { engagements: { job: "employer" } },    // not required for card information, on expand, it calls for engagements
            {
              relation: 'candidateStarreds',
              scope: {
                where: { accountId: Core.getUserId() },
              },
            },
          ],
          fields: fieldsForCandidatePage,
        };
        Candidate.getAll(
          (candidates) => {
            this.setState({ allCandidates: candidates }, () => {
              this.filterControl && this.filterControl.setItems(candidates);
            });
          },
          params,
          true
        );
      }
      else {
        Candidate.getActiveCandidates(
          (candidates) => {
            if (!Core.isRecruiter() && accountId) {
              candidates = candidates.filter((candidate) => candidate.accountId === accountId);
            }
            this.setState({ allCandidates: candidates }, () => {
              this.filterControl && this.filterControl.setItems(candidates);
            });
          },
          {
            dateRange: Arr(this.state.dateRange),
            filters
          }
        );
      }
    });
  }
  showMessage = (msg) => {
    this.setState({
      snackBarMessage: msg,
      snackBarOpen: true,
    });
  };
  hideMessage = () => {
    this.setState({
      snackBarMessage: '',
      snackBarOpen: false,
    });
  };
  render() {
    const queryParams = this.state.queryParams || {};

    Core.setKeyValue(KEY__CANDIDATES_LIST, this);

    // console.debug('Candidates', this.state);

    const _isQueryViewDups = !!queryParams.viewDup && Core.isAdmin();

    return (
      <Box column h100 w100>
        <Box column h100 w100
          role='ViewDups'
          acl={YES(_isQueryViewDups)}
        >
          <CandoDuplicateDetails
            candidateId={queryParams.cId}
          />
        </Box>
        <Box column h100 w100
          role='CandidatesList'
          acl={NOT(_isQueryViewDups)}
        >
          <FilterControl
            Controller={this}
            menus={Candidate.menus}
            more={Candidate.more}
            onChange={(filtered, filter) => {
              this.List.setItems(filtered);
            }}
            source={'Candidates'}
            toolBarLeft={
              <>
                {Core.isAdmin() && (
                  <>
                    <Checkbox small
                      checked={!!this.state.showAll}
                      onChange={event => {
                        this.List && this.List.restart();
                        this.setState({ showAll: !this.state.showAll });
                        setTimeout((st) => this.loadData());
                      }}
                      className='d-none'
                    >
                      Show All
                    </Checkbox>
                  </>
                )}
              </>
            }
            toolBarRight={
              <>

                <Switch small
                  checked={this.state.expanded}
                  onChange={event => {
                    this.setState(
                      { expanded: !this.state.expanded },
                      () => ListController().loadData()
                    );
                  }}
                  className='mr-2'
                >
                  {!!this.state.expanded ? 'Collapse all' : 'Expand all'}
                </Switch>

                <CandidatesExportCSV
                  acl={Core.isAdmin()}
                />

                <NavLink
                  acl={
                    NOT(Core.isAdmin()) ||
                    Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE })
                  }
                  to='/candidate/create'
                  className='ui-m-min'
                >
                  <Button small
                    label='+ New Candidate'
                    className='c-white c-white-hover'
                  />
                </NavLink>

              </>
            }
          />
          <Box column h100 w100 scrollY>
            <List
              ref={(List) => (this.List = List)}
              tabs={Core.isRecruiter() ? Candidate.listTabsRecruiters : Candidate.listTabs}
              tab={Candidate.listTab}
              name='Candidate'
              card={CandidateCard}
              params={this.state.params}
              source={'candidates'}
              parent={this}
              floatingButton={
                (!Core.isAdmin() || Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE })) && (
                  <NavLink className='ui-m-max' to={`/candidate/create`}>
                    <FloatingActionButton
                      className='list-floating-button'
                      color={THEME__COLOR__SECONDARY}
                    />
                  </NavLink>
                )
              }
            />
            <Snackbar
              open={this.state.snackBarOpen}
              message={this.state.snackBarMessage}
              className='snack-bar'
              autoHideDuration={7000}
              onClose={this.hideMessage}
            />
          </Box>
        </Box>
      </Box>
    );
  }
}

export default Candidates;

export function CandidatesController() {
  return Obj(Core.getKeyValue(KEY__CANDIDATES_LIST));
}

export function Candidates__state(update = {}, callback = () => null) {
  return Obj(CandidatesController().state);
}

export function Candidates__updateState(update = {}, callback = () => null) {
  return CandidatesController().setState ? CandidatesController().setState(update, callback) : callback(false);
}
