import Core from '../../../lib/Core';
import useState from '../../../lib/hooks/useState.hook';
import Job from '../../../lib/Job';
import { Obj } from '../../../lib/Object.lib';
import JobFeedbacks from '../../Jobs/Edit/Forms/JobFeedbacks';

export default function NavTabFeedbackTo10x10(props) {
  const { jobId } = props;
  let [{ job = {} }, updateState] = useState();
  (async () => {
    if (jobId && !job.id && !NavTabFeedbackTo10x10.busy && !NavTabFeedbackTo10x10.error) {
      NavTabFeedbackTo10x10.busy = true;
      job = Obj(
        await Job.get(jobId, null, {
          fields: ['id', 'feedbackLogResume', 'feedbackSummaryResume', 'feedbackLogInterview', 'feedbackSummaryInterview']
        }).catch(Core.showError)
      );
      if (!job.id) {
        NavTabFeedbackTo10x10.error = true;
      }
      else {
        await updateState({ job });
      }
      delete NavTabFeedbackTo10x10.busy;
    }
  })();
  return (
    <JobFeedbacks
      job={job}
      onChange={async (update) => new Promise(async (resolve) => {
        await updateState({ job: Object.assign(job, update) });
        clearTimeout(NavTabFeedbackTo10x10.timeout);
        NavTabFeedbackTo10x10.timeout = setTimeout(async () => {
          await Job.update(job.id, update).catch(Core.showError).catch(Core.showError);
        }, 3000);
        resolve();
      })}
      className='m-0'
      readOnly={!Core.isAdmin()}
    />
  );
}
