import Typography from '../../Layout/Wrappers/Typography';

export default function CounterSingleCard({
  index
}) {
  return (
    <Typography
      className='f-sm fw-600 bg-yellow-medium px-05'
      style={{
        position: 'absolute',
        top: 0,
        right: '0.25rem',
        zIndex: 1
      }}
    >
      {index + 1}
    </Typography>
  )
}
