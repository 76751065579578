import {
  TableCell
} from "@mui/material";
import moment from "moment";
import {
  Component
} from "react";
import Core from "../../../lib/Core";
import {
  isValidDate
} from '../../../lib/Date.lib';
import {
  ACCOUNT_ACTION__RESUME_SUBMISSION
} from '../../../lib/Definition';
import {
  isEnded,
  isOnHold
} from '../../../lib/Engagement';
import {
  NOT
} from '../../../lib/GenericTools.lib';
import {
  trim
} from '../../../lib/String.lib';
import {
  ENGAGEMENT__ACTIVE_REJECTION_REASONS,
  ENGAGEMENT__ACTIVE_STATUSES,
  ENGAGEMENT__STAGE_STATUSES,
  ENGAGEMENT__STATE_CLOSED,
  ENGAGEMENT__STATE_OPEN,
  STAGE_CONFIRMATION,
  STAGE_END,
  STAGE_GUARANTEE,
  STAGE_HIRE,
  STAGE_OFFER,
  STAGE_ONSITE,
  STAGE_REVIEW,
  STAGE_SCREEN,
  STAGE_SUBMISSION,
  STATUS_E_SUCCESS,
  STATUS_W_10X10,
  STATUS_W_CANDIDATE,
  STATUS_W_CANDIDATE_SCHEDULE,
  STATUS_W_CANDIDATE_SUBMIT_FORM,
  STATUS_W_CANDIDATE_SUBMIT_HOMEWORK,
  STATUS_W_EMPLOYER,
  STATUS_W_EMPLOYER_SCHEDULE,
  STATUS_W_GUARANTEE,
  STATUS_W_ONSITE,
  STATUS_W_SCREEN,
  STATUS_W_START_DATE
} from "../../../dictionaries/Engagement.dic";
import {
  openRecruiterStageTransitionEmailPreview,
  openRejectionEmailPreview
} from "../../../lib/services/Email/EmailRecruiter.lib";
import Box from '../../Layout/Wrappers/Box';
import IconButton from '../../Layout/Wrappers/IconButton';
import NavLink from '../../Layout/Wrappers/NavLink';
import EngagementUI from '../../../dictionaries/EngagementUI.dic';
import CustomPicker from "./CustomPicker";
import {
  HoldStatusActionItem,
  openHoldStatusConfig
} from './HoldStatus';
import RejectionDropdown from './RejectionDropdown';
import StatusDropdown from './StatusDropdown';

class StageColumn extends Component {
  constructor() {
    super(...arguments);
    this.parent = this.props.parent;
  }
  render() {
    const EngagementCardController = this.props.parent;
    const engagement = EngagementCardController.state;
    let {
      ref, // identifier of the column.
      label, // initial label to show.
      stage, // stage which it belongs.
      next, // next stage on click next button.
      next2, // next stage on click next button (exception for hire).
      next3, // next stage on click next button (exception for guarantee).
      value, // default Engagement date value to show.
      edit, // Engagement field to save updates.
      streak, // Streak field to save updates.
      colSpan, // Column Span.
      width = 170,
    } = this.props.args;
    const dropdowns = this.parent.dropdowns;
    // eslint-disable-next-line no-unused-vars
    const pickers = this.parent.pickers;
    const updateField = this.parent.updateField;
    const engStage = engagement.stage;
    const engStatus = engagement.status;
    const _selected = stage.test(engStage);
    const _value = engagement[value];

    /** 
     * [2024-05-17]
     * @todo To review following piece of code that seemed to have a wrong logic.
     */
    const _label = (
      NOT(ref === STAGE_OFFER)
        ? label
        : (engStage === STAGE_HIRE)
          ? next
          : (engStage === STAGE_GUARANTEE)
            ? next2
            : label
    );
    const _field = edit || value;
    const _next = (engStage === STAGE_HIRE)
      ? next2
      : (engStage === STAGE_GUARANTEE)
        ? next3
        : next;
    let _statusArr = [...(
      ENGAGEMENT__STAGE_STATUSES[engStage] ||
      ENGAGEMENT__ACTIVE_STATUSES
    )];
    let _rejectionReasons = [...ENGAGEMENT__ACTIVE_REJECTION_REASONS];
    if (
      (engagement.state === ENGAGEMENT__STATE_CLOSED) &&
      (engStage === STAGE_GUARANTEE)
    ) {
      _statusArr = [];
    }
    const nextStage = (ev) => {
      const nextStatusArr = [...(
        ENGAGEMENT__STAGE_STATUSES[_next] ||
        ENGAGEMENT__ACTIVE_STATUSES
      )];
      const update = { stage: _next, status: nextStatusArr[0] };
      const newDate = moment().tz("America/Los_Angeles");
      if (_next === STAGE_HIRE) {
        update.offered = newDate.toISOString();
        update.status = STATUS_W_START_DATE;
      }
      else if (_next === STAGE_GUARANTEE) {
        update.status = STATUS_W_GUARANTEE;
        update.hired = newDate.toISOString();
        update.startDate = newDate.toISOString();
      }
      else if (_next === STAGE_END) {
        delete update.stage;
        update.state = ENGAGEMENT__STATE_CLOSED;
        update.status = STATUS_E_SUCCESS;
        update.closed = newDate.toISOString();
      } else {
        update[_field] = newDate.toISOString();
      }
      if (update.state === ENGAGEMENT__STATE_CLOSED) {
        update.closed = newDate.toISOString();
      }
      updateField(update, null, (update) => {
        if (
          NOT(
            [
              STAGE_HIRE,
              STAGE_GUARANTEE,
              STAGE_END
            ].includes(_next)
          )
        ) {
          setTimeout((st) => {
            const dropdown = dropdowns[next];
            if (/offer/i.test(_next)) {
              openRecruiterStageTransitionEmailPreview({
                engagement,
                eventType: "offer",
                previousStage: engStage,
                dropdown,
              });
            } else {
              dropdown && dropdown.open();
            }
          });
        }
      });
    };
    const onChangeStatus = update => {
      let { status, statusNote = '' } = update;
      if (!status) {
        if (statusNote !== engagement.statusNote) {
          updateField({ statusNote });
        }
        return;
      }
      if (/e - /i.test(status)) {
        /** END STATUS */
        update[_field] = new Date().toISOString();
        Core.log("END STATUS", { update, _field });
      }
      updateField(
        update,
        [
          STATUS_W_SCREEN,
          STATUS_W_ONSITE
        ].includes(status),
        (update) => {

          if (/e - /i.test(status)) {
            /** END STATUS */
            const dropdown = dropdowns[`${stage}Rejection`];
            dropdown && dropdown.open();
          }

          else if (/h -/i.test(status)) {
            openHoldStatusConfig({
              engagement,
              onDone: update => {
                updateField(update);
              }
            });
          }

          else if (
            (ref === STAGE_SCREEN) &&
            (status === STATUS_W_SCREEN)
          ) {
            this.parent.parent.parent.CalendarEvent.open({
              engagement: engagement,
              callback: (newDate, isFullday) => {
                /**
                 * On close Drawer of Screen Event
                 * Set new date in the UI item then update Engagement
                 */
                let index = !engagement["screen1"]
                  ? 1
                  : !engagement["screen2"]
                    ? 2
                    : !engagement["screen3"] && 3;

                if (!!index) {
                  const sField = "screen" + index;
                  const update = {};
                  update[sField] = newDate.toISOString();
                  updateField(update);
                  openRecruiterStageTransitionEmailPreview({
                    engagement,
                    eventType: index === 1 ? "firstScreenInterview" : "interview",
                    previousStatus: engStatus,
                    interviewDate: newDate.toISOString(),
                    isFullday,
                  });
                }
              },
            });
          }

          else if (
            (ref === STAGE_ONSITE) &&
            (status === STATUS_W_ONSITE)
          ) {
            this.parent.parent.parent.CalendarEvent.open({
              engagement: engagement,
              callback: (newDate, isFullday) => {
                /**
                 * On close Drawer of Onsite Event
                 * Set new date in the UI item then update Engagement
                 */
                const index = !engagement["onsite1"]
                  ? 1
                  : !engagement["onsite2"] && 2;
                if (!!index) {
                  updateField({ ["onsite" + index]: newDate.toISOString() });
                  openRecruiterStageTransitionEmailPreview({
                    engagement,
                    eventType: "interview",
                    previousStatus: engStatus,
                    interviewDate: newDate.toISOString(),
                    isFullday,
                  });
                }
              },
            });
          }

          else if (
            [
              STAGE_SCREEN,
              STAGE_ONSITE
            ].includes(ref) &&
            [
              STATUS_W_CANDIDATE,
              STATUS_W_CANDIDATE_SUBMIT_HOMEWORK,
              STATUS_W_CANDIDATE_SCHEDULE,
              STATUS_W_CANDIDATE_SUBMIT_FORM,
              STATUS_W_EMPLOYER,
              STATUS_W_EMPLOYER_SCHEDULE
            ].includes(status)
          ) {
            openRecruiterStageTransitionEmailPreview({
              engagement,
              eventType: "status",
              previousStatus: engStatus
            });
          }

          else if (
            (ref === STAGE_REVIEW) &&
            (status === STATUS_W_CANDIDATE_SUBMIT_FORM)
          ) {
            openRecruiterStageTransitionEmailPreview({
              engagement,
              eventType: "status",
              previousStatus: engStatus
            });
          }

        }
      );
    };
    const onChangeRejectionReason = ({
      update = {},
      rejectionReason
    }) => {
      updateField(
        {
          ...update,
          state: "Closed",
          closed: new Date().getTime(),
          rejectionReason,
          rejectionReasonAdditionalInfo: trim(
            EngagementCardController.state.rejectionReasonAdditionalInfo
          )
        },
        null,
        () => {
          openRejectionEmailPreview({ EngagementCardController });
        }
      );
    };
    return (
      <TableCell
        colSpan={colSpan || 1}
        rowSpan={Core.isAdmin() ? 1 : 2}
        className={`mid-col blocks ${_selected ? "stage-selected" : ""}`}
        style={{
          minWidth: width,
          maxWidth: width,
          border: 'none'
        }}
      >
        <div className="card-col-header v-align-mid">
          {_label}
          {
            /** NEXT ICON */
            _selected && Core.isAdminOrCoordinator() && (
              <IconButton className="icon16" onClick={nextStage}>
                <i className="material-icons">arrow_forward</i>
              </IconButton>
            )
          }
        </div>
        {
          /** COMMON DATE PICKER */
          NOT(
            [
              STAGE_CONFIRMATION,
              STAGE_SCREEN,
              STAGE_ONSITE,
              STAGE_OFFER
            ].includes(ref)
          ) && (
            <CustomPicker
              parent={this}
              args={{
                field: _field,
                streakField: streak,
                ...(
                  _value ? { value: new Date(_value) } : {}
                ),
                width
              }}
            />
          )
        }
        {
          /** OFFER DATE PICKERS */
          (ref === STAGE_CONFIRMATION) && (
            <>
              <CustomPicker
                parent={this}
                args={{
                  value: !!engagement.introduced && new Date(engagement.introduced),
                  label: "Introduced",
                  readOnly: true
                }}
              />
              {!!engagement.matched ? (
                <CustomPicker
                  parent={this}
                  args={{
                    value:
                      !!engagement.matched && new Date(engagement.matched),
                    field: "matched",
                    streakField: "Matched",
                    label: "Matched"
                  }}
                />
              ) : (
                <CustomPicker
                  parent={this}
                  args={{
                    value:
                      !!engagement.createdAt &&
                      new Date(engagement.createdAt),
                    field: "matched",
                    streakField: "Matched",
                    label: "Matched ?"
                  }}
                />
              )}
              <CustomPicker
                parent={this}
                args={{
                  value:
                    !!engagement.confirmed && new Date(engagement.confirmed),
                  field: "confirmed",
                  streakField: "confirmed",
                  label: "Confirmed"
                }}
              />
            </>
          )
        }
        {
          /** SCREEN DATE PICKERS */
          (ref === STAGE_SCREEN) && (
            <>
              {engagement.screen1 && (
                <CustomPicker
                  parent={this}
                  args={{
                    value: new Date(engagement.screen1),
                    field: "screen1",
                    streakField: "Screen1",
                    isFullday: engagement._screen1IsFullday,
                    label: 'Screen 1',
                    shortFormat: true
                  }}
                />
              )}
              {engagement.screen2 && (
                <CustomPicker
                  parent={this}
                  args={{
                    value: new Date(engagement.screen2),
                    field: "screen2",
                    streakField: "Screen2",
                    isFullday: engagement._screen2IsFullday,
                    label: 'Screen 2',
                    shortFormat: true
                  }}
                />
              )}
              {engagement.screen3 && (
                <CustomPicker
                  parent={this}
                  args={{
                    value: new Date(engagement.screen3),
                    field: "screen3",
                    streakField: "Screen3",
                    isFullday: engagement._screen3IsFullday,
                    label: 'Screen 3',
                    shortFormat: true
                  }}
                />
              )}
              <HomeworkPickers
                EngagementCardController={EngagementCardController}
                StageColumnController={this}
              />
            </>
          )
        }
        {
          /** ONSITE DATE PICKERS */
          (ref === STAGE_ONSITE) && (
            <>
              {engagement.onsite1 && (
                <CustomPicker
                  parent={this}
                  args={{
                    value: new Date(engagement.onsite1),
                    field: "onsite1",
                    streakField: "Onsite1",
                    isFullday: engagement._onsite1IsFullday,
                    label: 'Onsite 1',
                    shortFormat: true
                  }}
                />
              )}
              {engagement.onsite2 && (
                <CustomPicker
                  parent={this}
                  args={{
                    value: new Date(engagement.onsite2),
                    field: "onsite2",
                    streakField: "Onsite2",
                    isFullday: engagement._onsite2IsFullday,
                    label: 'Onsite 2',
                    shortFormat: true
                  }}
                />
              )}
            </>
          )
        }
        {
          /** OFFER DATE PICKERS */
          [
            STAGE_OFFER,
            STAGE_HIRE,
            STAGE_GUARANTEE
          ].includes(ref) && (
            <>
              <CustomPicker
                parent={this}
                args={{
                  value: !!engagement.onsite && new Date(engagement.onsite),
                  field: "onsite",
                  streakField: "Onsite",
                  label: "Extended"
                }}
              />
              <CustomPicker
                parent={this}
                args={{
                  value: !!engagement.offered && new Date(engagement.offered),
                  field: "offered",
                  streakField: "Offered",
                  label: "Accepted",
                  onChange: (ev, date) => {
                    if (engStage === STAGE_OFFER) {
                      nextStage(ev);
                    }
                  }
                }}
              />
              <CustomPicker
                parent={this}
                args={{
                  value:
                    !!engagement.startDate && new Date(engagement.startDate),
                  field: "startDate",
                  streakField: "StartDate",
                  label: "Start"
                }}
              />
              <CustomPicker
                parent={this}
                args={{
                  value:
                    !!engagement.guaranteeDate &&
                    new Date(engagement.guaranteeDate),
                  field: "guaranteeDate",
                  streakField: "GuaranteeDate",
                  label: "Guarantee"
                }}
              />
            </>
          )
        }

        {
          /** STATUS - DROPDOWN */
          _selected &&
          (Core.isAdminOrCoordinator() ? (
            <Box column>
              {!!_statusArr.length && (
                <StatusDropdown
                  statuses={_statusArr}
                  engagement={engagement}
                  onChange={onChangeStatus}
                />
              )}
              {(
                /** 
                 * @note 
                 * Following statements, evaluate if show the icon to open resume submission.
                 * "ref" is regarding to the column, which must be the one for {{STAGE_SUBMISSION}}.
                 * "engStage" is regarding to {{Engagement.stage}} must be on {{STAGE_SUBMISSION}} ALSO.
                 */
                (engagement.state === ENGAGEMENT__STATE_OPEN) &&
                (ref === STAGE_SUBMISSION) &&
                (engStage === STAGE_SUBMISSION) &&
                new RegExp(STATUS_W_10X10, 'i').test(engStatus) &&
                (!Core.isAdmin() || Core.isAdmin({ action: ACCOUNT_ACTION__RESUME_SUBMISSION })) && (
                  <NavLink
                    to={
                      "/candidate/resume-submission/" +
                      engagement.candidate.id +
                      "/" +
                      engagement.jobId
                    }
                    className="ui-m-min"
                    style={{ marginTop: 5 }}
                  >
                    <i title="Submit Resume" className="material-icons">
                      assignment_ind
                    </i>
                  </NavLink>
                )
              )}
              {isOnHold(engStatus) && (
                <HoldStatusActionItem
                  engagement={engagement}
                  onChange={updateField}
                />
              )}
            </Box>
          ) : (
            <span className="card-eng-status">{engStatus}</span>
          ))
        }
        {
          /** REJECTION REASON - DROPDOWN */
          _selected &&
          /** if status is END(e - ) but not SUCCESS */
          isEnded(engStatus) &&
          (engStatus !== STATUS_E_SUCCESS) &&
          (Core.isAdminOrCoordinator() ? (
            <RejectionDropdown
              setRef={(self) => (dropdowns[`${stage}Rejection`] = self)}
              rejectionReasons={_rejectionReasons}
              engagement={engagement}
              EngagementCardController={EngagementCardController}
              onChange={onChangeRejectionReason}
              disabled={Core.isRecruiter()}
              className='mb-05'
            />
          ) : (
            <span>{engagement.rejectionReason}</span>
          ))
        }

      </TableCell>
    );
  }
}

export default StageColumn;

export function HomeworkPickers({
  EngagementCardController,
  StageColumnController
}) {
  const engagement = EngagementCardController.state;
  const isValidHomeworkAssigned = isValidDate(engagement.homeworkAssigned);
  const isValidHomeworkCompleted = isValidDate(engagement.homeworkCompleted);
  const isAnyValidDate = (isValidHomeworkAssigned || isValidHomeworkCompleted);
  return (isAnyValidDate) && (
    <div className='mb-1'>
      <CustomPicker
        parent={StageColumnController}
        args={{
          value: (
            isValidDate(engagement.homeworkAssigned)
              ? new Date(engagement.homeworkAssigned)
              : null
          ),
          field: "homeworkAssigned",
          streakField: "homeworkAssigned",
          isFullday: true,
          label: EngagementUI.homeworkAssigned.label,
          shortFormat: true
        }}
      />
      <CustomPicker
        parent={StageColumnController}
        args={{
          value: (
            isValidDate(engagement.homeworkCompleted)
              ? new Date(engagement.homeworkCompleted)
              : null
          ),
          field: "homeworkCompleted",
          streakField: "homeworkCompleted",
          isFullday: true,
          label: EngagementUI.homeworkCompleted.label,
          shortFormat: true
        }}
      />
    </div>
  );
}
