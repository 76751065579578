import {
  join
} from '../Array.lib';
import {
  getPersonName
} from '../Object.lib';


/**
 * Returns a human-readable name for a candidate object.
 * If the candidate has an account attached, it will be included in
 * the name string.
 * 
 * @param {Object} candidate - a candidate record
 * @returns {string} - ex. "John Doe (R: Jane Doe)"
 */
export default function getCandidateEntityName(candidate) {
  return join([
    getPersonName(candidate),
    candidate.account ? ` (R: ${getPersonName(candidate.account)})` : ''
  ], ' ');
}
