import {
  Select
} from '@mui/material';
import {
  Input
} from 'antd';
import {
  useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import AccountLib, {
  getAccountEmployer
} from '../../lib/Account';
import {
  Arr
} from '../../lib/Array.lib';
import Core from '../../lib/Core';
import {
  REC_MSG_TYPE__ALL_ID,
  STATE_ACTIVE
} from '../../lib/Definition';
import {
  NOT,
  validateEmail
} from '../../lib/GenericTools.lib';
import Http from '../../lib/Http';
import {
  mapAccount
} from '../../lib/models/account';
import getEmailModel from '../../lib/models/email.model';
import {
  MODEL_NAME_EMPLOYER
} from '../../lib/models/employer';
import {
  MODEL_NAME_JOB
} from '../../lib/models/job';
import {
  ROLE__EMPLOYER_RECRUITER
} from '../../lib/services/Accounts/Session.lib';
import {
  Str
} from '../../lib/String.lib';
import {
  copyUrlEmployerPendings
} from '../Employers/EmployerPendings/Libraries/copyUrlEmployerPendings.fun';
import Button from '../Layout/Wrappers/Button';
import Col from '../Layout/Wrappers/Col';
import IconButton from '../Layout/Wrappers/IconButton';
import Menu from '../Layout/Wrappers/Menu';
import RadioGroup from '../Layout/Wrappers/RadioGroup';
import Row from '../Layout/Wrappers/Row';

/**
 *
 * @param {object} props_
 * @param {object} props_.data
 * @param {string} props.data.name
 * @param {string} props.data.email
 * @param {number[]} props.data.to
 * @param {number[]} props.data.cc
 * @param {number[]} props.data.bcc
 * @param {number} props_.index
 * @param {function} props_.onChange
 *
 * @returns
 */
function EmailEntry(props) {
  const {
    model = {},
    data = getEmailModel(),
    index = new Date().getTime(),
    messageTypeTags = [],
    onChange = ({ index, data }) => null,
    onRemove = ({ index }) => null,
    expanded
  } = props;
  let [state, setState] = useState({});
  let {
    accountId = Str(data.accountId),
    name = Str(data.name),
    email = Str(data.email),
    to = Arr(data.to),
    cc = Arr(data.cc),
    bcc = Arr(data.bcc)
  } = state;
  const _updateState = async (update, delay) => {
    update = Object(update) === update ? update : {};
    setState(prevState => ({ ...prevState, ...update }));
    return delay ? new Promise(resolve => setTimeout(() => resolve(state), delay)) : state;
  };
  const { t } = useTranslation();
  let messageTypesData = messageTypeTags.map(({ id, label }) =>
    ({ id, label, delivery: to.includes(id) ? 'to' : cc.includes(id) ? 'cc' : bcc.includes(id) ? 'bcc' : '' })
  );
  let {
    __accounts = {}
  } = model;
  const isEmployer = (model.___model___ === MODEL_NAME_EMPLOYER);
  const isJob = (model.___model___ === MODEL_NAME_JOB);
  const employerId = isEmployer ? model.id : isJob ? model.employerId : '';
  const companyName = isEmployer ? model.name : isJob ? model.employer.name : '';
  let accountsList = Array.isArray(__accounts.list) ? __accounts.list : [];
  let isAccountsListLength = !!accountsList.length;
  const _onChange = ({ index, key, id }) => async (event) => {
    let value = event.target.value;
    let update = { [key]: value };
    if (key === 'account') {
      let account = accountsList.find(({ id }) => id === event.target.value);
      update = {
        accountId: account.id,
        email: account.email,
        name: account._name
      }
    }
    else if (key === 'radio') {
      update = {
        to: to.filter(_id => _id !== id),
        cc: cc.filter(_id => _id !== id),
        bcc: bcc.filter(_id => _id !== id),
        [value]: REC_MSG_TYPE__ALL_ID === id ? [id] : [...data[value].filter(id => id !== REC_MSG_TYPE__ALL_ID), id]
      };
    }
    await _updateState(update);
    Object.assign(data, update);
    if (['account', 'radio'].includes(key)) {
      onChange({ index, data });
    }
  }
  const _onBlur = ({ index }) => (event) => {
    onChange({ index, data });
  }
  // console.debug('EmailEntry', data, state, model);
  return (
    expanded
      ? (
        <div
          className={
            validateEmail(email)
              ? "bg-white-light p-1 py-2 my-1 rounded"
              : "bg-yellow-light p-1 py-2 my-1 rounded"
          }
          style={{ position: 'relative' }}
        >
          {employerId && isAccountsListLength && (
            <Row className='mb-1' >
              <Col>
                <>
                  <div className='d-flex flex-align-left-center mt-2'>
                    <h3 className='m-0 mr-1'>Account</h3>
                    <b className="cred">*</b>
                  </div>
                  <Select
                    name="account"
                    value={accountId}
                    onChange={_onChange({ index, key: 'account' })}
                    fullWidth
                    error={!accountId}
                    variant='standard'
                  >
                    {accountsList.map((item) => (
                      <Menu.Item
                        key={item.id}
                        value={item.id}
                      >
                        {item._name}
                      </Menu.Item>
                    ))}
                  </Select>
                  {!accountId && (
                    <span className='f-sm c-red mt-05'>
                      An account must be created to receive reminder emails
                    </span>
                  )}
                </>
              </Col>
            </Row>
          )}
          <Row className="mb-1">
            <Col>
              <div className="d-flex">
                <h3 className='mr-1'>Email Address</h3>
                {!!email.length && NOT(validateEmail(email)) && (
                  <span className="c-red">{t('Invalid email')}</span>
                )}
              </div>
              <Input
                value={email}
                placeholder={t('Type here')}
                onChange={_onChange({ index, key: 'email' })}
                onBlur={_onBlur({ index })}
                disabled={accountId}
              />
            </Col>
            <Col>
              <h3>Name/Alias</h3>
              <Input
                value={name}
                placeholder={t('Type here')}
                onChange={_onChange({ index, key: 'name' })}
                onBlur={_onBlur({ index })}
                disabled={accountId}
              />
            </Col>
          </Row>
          <Row className="d-flex flex-wrap">
            {messageTypesData.map(({ id, label, delivery }) => (
              // !console.debug('deliver', delivery) &&
              <Col key={`message-type-${id}`} className="d-flex flex-wrap align-center" style={{ width: '50%' }}>
                <h3 className="m-0 mr-1 align-left" style={{ width: 96 }}>{label}</h3>
                <RadioGroup row
                  name={`message-type-${label}`}
                  options={['to', 'cc', 'bcc']}
                  optionProps={{ className: 'tt-unset min-w-80' }}
                  value={delivery}
                  onChange={_onChange({ index, key: 'radio', id })}
                />
              </Col>
            ))}
          </Row>
          <div
            className='d-flex flex-align-right-center'
          >
            {Core.isAdmin() && accountId && (
              <Button
                variant='outlined'
                color='gray'
                className='tt-unset mr-1'
                onClick={(event) => copyUrlEmployerPendings({
                  accountId
                })}
                endIcon={
                  <i className='material-icons c-inherit'>content_copy</i>
                }
              >
                Generate URL
              </Button>
            )}
            {Core.isAdmin() && employerId && !accountId && (
              <Button
                variant='outlined'
                className='tt-unset mr-1'
                onClick={async event => {
                  if (!validateEmail(email)) {
                    Core.showError(`Invalid email`);
                  }
                  else if (!String(name || '').trim().length) {
                    Core.showError(`Name is required`);
                  }
                  else {
                    let account = {
                      firstName: String(name).split(' ')[0],
                      lastName: String(name).split(' ')[1] || String(name).split(' ')[0],
                      email,
                      password: new Date().toISOString(),
                      confirm: new Date().toISOString(),
                      state: STATE_ACTIVE,
                      companyName
                    }
                    let role = await Http.get(Core.getApi(`Roles`), {
                      filter: JSON.stringify({ where: { name: ROLE__EMPLOYER_RECRUITER } }),
                    }).catch(Core.showError).then(roles => roles[0]);
                    if (!role) { return; }
                    account.role = role.name;
                    account = await AccountLib.post(account, role.id, {
                      accountRecruiterWhiteList: [],
                      accountEmployerBlackList: [],
                      accountJobBlackList: [],
                      accountEmployerSourceList: [],
                      accountJobSourceList: [],
                    }, async response => {
                      account = mapAccount(response);
                      console.debug({ account, role });
                      model.__accounts = model.__accounts || {};
                      model.__accounts.list = model.__accounts.list || [];
                      model.__accounts.list.push(account);
                      data.accountId = account.id;
                      await getAccountEmployer({ accountId: account.id, employerId });
                      _onChange({ index, key: 'account' })({ target: { value: account.id } });
                      return account;
                    }).catch(Core.showError);
                  }
                }}
              >
                Create Account
              </Button>
            )}
            <IconButton
              title="Remove contact"
              onClick={onRemove({ index, data })}
            >
              <i className="material-icons">delete</i>
            </IconButton>
          </div>
        </div >
      )
      : (
        <ul className='my-1'>
          <li>
            {name} {`<${email}>`}; {messageTypesData.filter(({ delivery }) => !!delivery.length).map(({ label, delivery }) => `${label}:${delivery}`).join(', ')}
          </li>
        </ul>
      )
  );
}

export { EmailEntry as default, EmailEntry };
