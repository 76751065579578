import MuiButton from '@mui/material/Button';
import {
  isString
} from 'lodash';
import {
  hasNoSize,
  hasNoTextTransform,
  hasNoWidth,
  joinClassName,
  mapWrapper,
  THEME__COLOR__DEFAULT,
  THEME__COLOR__ERROR,
  THEME__COLOR__INHERIT,
  THEME__COLOR__PRIMARY,
  THEME__COLOR__SECONDARY,
  THEME__COLOR__WARNING,
  THEME__VARIANT__CONTAINED,
  THEME__VARIANT__OUTLINED,
  THEME__VARIANT__TEXT
} from '../Libraries/Theme.lib';
import Icon from './Icon';
import Wrapper from './Wrapper';

/**
 * 
 * @see https://mui.com/material-ui/api/button/
 */
export default function Button({
  component = MuiButton,
  className = '',
  style = {},
  label = '',
  children = label,
  variant: propsVariant,
  color: propsColor,
  variant = THEME__VARIANT__CONTAINED,
  color = THEME__COLOR__SECONDARY,
  alignLeft = false,
  alignRight = false,
  size = 'medium',
  acl = true,
  debug = false,
  primary = false,
  secondary = false,
  standard = false,
  error = false,
  warning = false,
  inherit = false,
  icon = false,
  startIcon = false,
  endIcon = false,
  small = false,
  outlined = false,
  flat = false,
  uppercase = true,
  minW120 = false,
  minW100 = false,
  minW80 = false,
  mr1 = false,
  w100 = false,
  fullWidth = undefined,
  wrapperProps = {},
  ...props
}) {

  if (!acl) { return null; }

  startIcon = startIcon || icon;

  if (outlined) {
    variant = THEME__VARIANT__OUTLINED;
    color = THEME__COLOR__SECONDARY;
  }
  else if (flat) {
    variant = THEME__VARIANT__TEXT;
    color = THEME__COLOR__INHERIT;
  }

  if (primary) {
    color = THEME__COLOR__PRIMARY;
  }
  else if (secondary) {
    color = THEME__COLOR__SECONDARY;
  }
  else if (error) {
    color = THEME__COLOR__ERROR;
  }
  else if (warning) {
    color = THEME__COLOR__WARNING;
  }
  else if(inherit){
    color = THEME__COLOR__INHERIT;
  }
  else if (standard) {
    color = THEME__COLOR__DEFAULT;
  }

  if (small) { size = 'small'; }

  if (fullWidth) { w100 = true; }

  if (isString(startIcon)) {
    startIcon = (
      <Icon icon={startIcon} className='icon16' />
    );
  }
  if (isString(endIcon)) {
    endIcon = (
      <Icon icon={endIcon} className='icon16' />
    );
  }

  const _className = [
    'inline-flex nowrap',
    alignLeft ? 'align-left' : alignRight ? 'align-right' : 'align-center',
    mr1 && 'mr-1',
    (
      w100
    ) ? (
      'w-100'
    ) : (
      minW120
    ) ? (
      'min-w-120'
    ) : (
      minW100
    ) ? (
      'min-w-100'
    ) : (
      minW80
    ) ? (
      'min-w-80'
    ) : (
      hasNoWidth({ className, style }) &&
      'w-auto'
    ),
    hasNoTextTransform({ className, style }) && (
      'tt-none tt-unset truncate'
    ),
    hasNoSize({ className, style }) && 'f-md',
    className
  ];

  mapWrapper(
    {
      role: 'Button',
      props: wrapperProps,
      assign: {
        ...props,
        component,
        variant,
        color,
        size,
        className: joinClassName(_className),
        style,
        children,
        startIcon,
        endIcon
      },
      flags: { debug }
    }
  );

  return (
    <Wrapper {...wrapperProps} />
  );
}
