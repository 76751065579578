
/**
 * [2024-12-19]
 * @note
 * Following constants are used as KEYS, also LABELS.
 * @todo  Refactor
 * 1. Create a dictionary file for mapping labels.
 * 2. Use following constants only as KEYS.
 * @lookup  for `ACTION` in `prependLogEngagementFeedbackToRecruiter`
 */
export const ACTION_TYPE__NO_ACTION = '';
export const ACTION_TYPE__FEEDBACK_TO_10X10 = 'Update';
export const ACTION_TYPE__FEEDBACK_TO_RECRUITER = 'Update to recruiter';
export const ACTION_TYPE__ACCEPT_STRONG = 'Strong Yes';
export const ACTION_TYPE__ACCEPT_WEAK = 'Weak Yes';
export const ACTION_TYPE__ACCEPT = 'Yes';
export const ACTION_TYPE__REJECT_STRONG = 'Strong No';
export const ACTION_TYPE__REJECT_WEAK = 'Weak No';
export const ACTION_TYPE__REJECT = 'No';
export const ACTION_TYPE__OTHER = 'other';
export const ACTION_TYPE__VIEW_CANDIDATE = 'view_candidate';
export const ACTION_TYPE__UPDATE = 'Update';

export const ACTION_SOURCE__ADMIN = '10x10 admin';
export const ACTION_SOURCE__ATS = 'ATS';
export const ACTION_SOURCE__ENGAGEMENT = 'Manual';
export const ACTION_SOURCE__PENDING = '10x10 site';

export const EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK = 'calibration_feedback';
export const EMPLOYER__PROCESSING_MODEL_KEY__OFFER_IN_PROGRESS = 'offer_in_progress';
export const EMPLOYER__PROCESSING_MODEL_KEY__ONSITE_SCHEDULE = 'onsite_schedule';
export const EMPLOYER__PROCESSING_MODEL_KEY__ONSITE_FEEDBACK = 'onsite_feedback';
export const EMPLOYER__PROCESSING_MODEL_KEY__SCREEN_SCHEDULE = 'screen_schedule';
export const EMPLOYER__PROCESSING_MODEL_KEY__SCREEN_FEEDBACK = 'screen_feedback';
export const EMPLOYER__PROCESSING_MODEL_KEY__HOMEWORK_TO_SEND = 'homework_to_send';
export const EMPLOYER__PROCESSING_MODEL_KEY__HOMEWORK_TO_REVIEW = 'homework_to_review';
export const EMPLOYER__PROCESSING_MODEL_KEY__CV_FEEDBACK = 'cv_feedback';
export const EMPLOYER__PROCESSING_MODEL_KEY__SCREEN_STAGE = 'screen_stage';
export const EMPLOYER__PROCESSING_MODEL_KEY__ONSITE_STAGE = 'onsite_stage';