import {
  compile
} from 'handlebars';
import {
  debounce,
  isEqual
} from 'lodash';
import {
  useRef
} from 'react';
import {
  Arr,
  join,
  sanitizeArr
} from '../../../lib/Array.lib';
import {
  NOT
} from '../../../lib/Boolean.lib';
import Core from '../../../lib/Core';
import {
  STATE_ACTIVE
} from '../../../lib/Definition';
import {
  Fun
} from '../../../lib/Function.lib';
import useState from '../../../lib/hooks/useState.hook';
import {
  getPersonName,
  Obj
} from '../../../lib/Object.lib';
import {
  COLLECTION__JOBS,
  readLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import {
  sendSafeEmail
} from '../../../lib/services/Email/Email.lib';
import {
  joinKeyName,
  Str,
  trim
} from '../../../lib/String.lib';
import {
  matchLocation
} from '../../../lib/URL.lib';
import {
  joinClassName
} from '../../Layout/Libraries/Theme.lib';
import Box, {
  RefBox
} from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Chip from '../../Layout/Wrappers/Chip';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import Icon from '../../Layout/Wrappers/Icon';
import IconButton from '../../Layout/Wrappers/IconButton';
import {
  ErrorMessage
} from '../../Layout/Wrappers/Message';
import Once from '../../Layout/Wrappers/Once';
import {
  PLACEMENT__RIGHT
} from '../../Layout/Wrappers/StyledTooltip';
import Switch from '../../Layout/Wrappers/Switch';
import Table from '../../Layout/Wrappers/Table';
import TextField from '../../Layout/Wrappers/TextField';
import {
  CandidateEditController
} from '../Edit/CandidateEdit';
import {
  CandidateJobChoicesController
} from './CandidateJobChoices';

const KEY__JOB_APP_MGM = 'section__job_app_mgm';
const KEY__JOB_APP_MGM__REPRESENTATION__TAKEN = 'Taken';
const KEY__JOB_APP_MGM__REPRESENTATION__YOURS = 'Yours';
const KEY__JOB_APP_MGM__REPRESENTATION__AVAILABLE = 'Available';
const KEY__JOB_APP_MGM__REPRESENTATION__TBD = 'TBD';
const KEY__JOB_APP_MGM__DECISION__PERMITTED = 'Permitted';
const KEY__JOB_APP_MGM__DECISION__PITCHED = 'Pitched';
const KEY__JOB_APP_MGM__DECISION__DECLINED = 'Declined';
const KEY__JOB_APP_MGM__STATUS__PERMITTED = 'Permitted';
const KEY__JOB_APP_MGM__STATUS__PENDING = 'Pending';

const ROW__SELECTED__CLASSNAME = 'bg-yellow-lighter';
const COL__COMMON__CLASSNAME = 'p-05 px-1 border-bottom';
const COL__HEADER__CLASSNAME = joinClassName([
  COL__COMMON__CLASSNAME,
  'bg-white-medium c-black-medium'
]);
const CHIP__CLASSNAME = 'c-black-medium fw-500 p-0 px-05 mr-1 outlined';

const VALUE__JOB_APP_MGM__VISIBLE_ROWS = 7;

const QUERY__JOB_APP_MGM = {
  name: 'query__job_app_mgm',
  collection: COLLECTION__JOBS,
  fields: [
    'id',
    'employerId',
    'state',
    'jobTitle',
    'addressCity',
    'role',
    'roles',
    'mustHave',
    'niceToHave',
    'inOfficeRemoteFlags',
    'officeLocations',
    'candidateLocations',
    'visaTransfer',
  ],
  include: [
    {
      relation: 'employer',
      scope: {
        fields: ['id', 'name']
      }
    }
  ],
  order: 'jobTitle DESC',
}

/**
 * PermitJobsDecision is a React component that manages and displays a list
 * of job applications. It allows filtering and sorting of jobs based on employer
 * name, job title, and role. The component provides UI elements to view job 
 * details, manage job permissions, and make decisions (Permitted, Pitched, Declined)
 * on job applications. It uses internal state to track filters and display options.
 * 
 * Props:
 * @param {object} props - Additional props that can be passed to the component.
 * 
 * State:
 * @property {Array} jobs - List of job objects to be displayed.
 * @property {boolean} showActiveOnly - Filter to show only active jobs.
 * @property {boolean} showAllRoles - Filter to show all roles.
 * @property {string} employerFilter - Filter keyword for employer name.
 * @property {string} jobTitleFilter - Filter keyword for job title.
 * @property {number} visibleJobs - Number of jobs visible in the list.
 */
export default function PermitJobsDecision(props) {

  // 
  const mem = useRef({
    persistedModels: {}
  });
  const { persistedModels } = mem.current;

  // STATE
  const [state, updateState] = useState({
    jobs: [],
    showActiveOnly: true,
    showAllRoles: false,
    employerFilter: '',
    jobTitleFilter: '',
    visibleJobs: VALUE__JOB_APP_MGM__VISIBLE_ROWS,
  });

  const candidateRoles = Arr(
    CandidateEditController().state.roles
  ).map(i => +i);

  const data = Arr(
    state.jobs
  ).map(
    (job, index) => {

      // CURRENT CALCULATED REPRESENTATION (base on legacy color values)
      const KEY__CURRENT__REPRESENTATION = getRepresentationKey({ job });

      // CURRENT CALCULATED DECISION (based on candidate state)
      const KEY__CURRENT__DECISION = getDecisionKey({ job });

      // PERSISTED REPRESENTATION and DECISION
      const {
        KEY__PERSISTED__REPRESENTATION = KEY__CURRENT__REPRESENTATION,
        KEY__PERSISTED__DECISION = KEY__CURRENT__DECISION
      } = Obj(persistedModels[job.id]);

      const model = {
        ...job,
        ID__JOB: job.id,
        NAME__EMPLOYER: trim(job.employer?.name),
        NAME__JOB: trim(job.jobTitle),
        KEY__PERSISTED__REPRESENTATION,
        KEY__PERSISTED__DECISION,
        KEY__CURRENT__REPRESENTATION,
        KEY__CURRENT__DECISION,
      };

      // HACK TBD for TESTING PURPOSES
      if (
        (index === 0) &&
        matchLocation('hack-tbd')
      ) {
        model.KEY__CURRENT__REPRESENTATION = KEY__JOB_APP_MGM__REPRESENTATION__TBD;
        model.KEY__PERSISTED__REPRESENTATION = KEY__JOB_APP_MGM__REPRESENTATION__TBD;
      }

      if (
        model.KEY__CURRENT__REPRESENTATION === KEY__JOB_APP_MGM__REPRESENTATION__TBD
      ) {
        sendSafeEmail({
          from: Core.getNewCandidateReceiverNotiFromEmail(),
          to: Core.getNewCandidateReceiverNotiToEmail(),
          subject: `[10x10 ALERT] - PermitJobsDecision: Representation TBD`,
          html: compile(
            `{{NAME__CANDIDATE}} (R: {{NAME__RECRUITER}}) | {{NAME__EMPLOYER}} - {{NAME__JOB}}`
          )({
            NAME__CANDIDATE: getPersonName(CandidateEditController().state),
            NAME__RECRUITER: getPersonName(CandidateEditController().state?.account),
            ...model
          })
        });
      }

      return model;
    }
  ).filter(
    (model) => (
      model.NAME__EMPLOYER.toLowerCase().includes(
        state.employerFilter.toLowerCase()
      ) &&
      model.NAME__JOB.toLowerCase().includes(
        state.jobTitleFilter.toLowerCase()
      )
    )
  ).sort(
    (a, b) => getOrderKey(a).localeCompare(getOrderKey(b))
  );


  /**
   * Returns a function that takes an event and updates the state with the given
   * key by setting its value to the target value of the event.
   *
   * @param {string} key The key to update in the state.
   * @returns {function} A function that takes an event and updates the state
   *          with the given key.
   */
  const _onChange = (key) => (event) =>
    updateState({ [key]: event.target.value });


  /**
   * A generic toggle handler. Given a key, toggles the state with that key
   * and fetches the jobs again.
   *
   * @param {string} key The key to toggle in the state.
   * @returns {function} A function that takes an event and toggles the state
   *          with the given key and fetches the jobs again.
   */
  const _onToggle = (key) => async (event) => {
    const checked = !!event.target.checked;
    const mappedKey = {
      'showActiveOnly': 'showActive',
      'showAllRoles': 'showAll'
    }[key];
    CandidateJobChoicesController().setState({
      [mappedKey]: checked
    }, () => {
      CandidateJobChoicesController().loadJobs(
        async () => {

          // THIS IS THE UNIQUE CODE REQUIRED FOR THIS NEW COMPONENT TO WORK
          // All other stuff is a hack to work on dependencies
          _fetchJobs(await updateState({ [key]: checked }));

        }
      );
    });
  }


  /**
   * Increases the number of visible jobs by VISIBLE_ROWS, up to the total number of
   * jobs. Updates the component's state accordingly.
   */
  const _showMore = () => {
    updateState({
      visibleJobs: Math.min(
        state.visibleJobs + VALUE__JOB_APP_MGM__VISIBLE_ROWS,
        data.length
      )
    });
  };


  /**
   * Reduces the number of visible jobs by VISIBLE_ROWS, ensuring that the minimum 
   * number of visible jobs is VISIBLE_ROWS. Updates the component's state accordingly.
   */
  const _showLess = () => {
    updateState({
      visibleJobs: Math.max(
        state.visibleJobs - VALUE__JOB_APP_MGM__VISIBLE_ROWS,
        VALUE__JOB_APP_MGM__VISIBLE_ROWS
      )
    });
  };


  /**
   * @function
   * @param {object} model - The job object containing the job ID.
   * @param {string} decision - The decision to be made on the job (Permitted, Pitched, Declined).
   * Handles the decision made on a job application. Updates the CandidateEditController
   * state based on the decision and updates the UI.
   * @example
   * _handleDecision(job, 'Permitted');
   */
  const _handleDecision = (model, decision) => {

    // Used to highlight the clicked row in the UI
    mem.current.jobId = model.ID__JOB;

    // Set the persisted model
    persistedModels[model.ID__JOB] = persistedModels[model.ID__JOB] || model;

    const update = {};
    const _filter = (key) => {
      update[key] = CandidateEditController().state[key].filter(
        (id) => (id !== model.ID__JOB)
      );
    };

    // TOGGLE PERMITTED DECISION
    if (
      decision === KEY__JOB_APP_MGM__DECISION__PERMITTED
    ) {
      update.key = 'jobsPermitted';
      if (
        Arr(CandidateEditController().state.jobsPermitted).includes(
          model.ID__JOB
        )
      ) {
        _filter('jobsPermitted');
      }
      else {
        _filter('jobsPitched');
        _filter('jobsDeclined');
        update.jobsPermitted = sanitizeArr([
          ...CandidateEditController().state.jobsPermitted,
          model.ID__JOB
        ]);
        openAnswerJobsQuestions([model.ID__JOB]);
      }
    }

    // TOGGLE PITCHED DECISION
    else if (
      decision === KEY__JOB_APP_MGM__DECISION__PITCHED
    ) {
      update.key = 'jobsPitched';
      if (
        Arr(CandidateEditController().state.jobsPitched).includes(
          model.ID__JOB
        )
      ) {
        _filter('jobsPitched');
      }
      else {
        _filter('jobsPermitted');
        _filter('jobsDeclined');
        update.jobsPitched = sanitizeArr([
          ...CandidateEditController().state.jobsPitched,
          model.ID__JOB
        ]);
      }
    }

    // TOGGLE DECLINED DECISION
    else if (
      decision === KEY__JOB_APP_MGM__DECISION__DECLINED
    ) {
      update.key = 'jobsDeclined';
      if (
        Arr(CandidateEditController().state.jobsDeclined).includes(
          model.ID__JOB
        )
      ) {
        _filter('jobsDeclined');
      }
      else {
        _filter('jobsPermitted');
        _filter('jobsPitched');
        update.jobsDeclined = sanitizeArr([
          ...CandidateEditController().state.jobsDeclined,
          model.ID__JOB
        ]);
      }
    }

    // console.debug('_handleDecision...', update);

    // UPDATE CANDIDATE STATE
    CandidateEditController().setStateStore(
      update,
      async () => {
        CandidateEditController().checkPotentialDuplicatedCandidates(Fun);
        debounce(updateState)();
      }
    );

  };


  /**
   * Runs when the component is mounted.
   * If the current location matches a given pattern (edit-section),
   * scrolls the element with the id KEY__JOB_APP_MGM into view with a smooth animation.
   */
  const _onMount = async () => {
    if (matchLocation('edit-section')) {
      document.getElementById(KEY__JOB_APP_MGM).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }

  /**
   * Fetches jobs based on the current state of the component.
   * If the user is an admin, fetches all jobs.
   * If the user is not an admin, fetches only active jobs.
   * If currentState.showActiveOnly is true, fetches only active jobs, even for admin users.
   * If currentState.showAllRoles is false, fetches only jobs for the candidate's roles.
   * @param {Object} currentState - The current state of the component.
   * @param {Boolean} currentState.showActiveOnly - Whether to show only active jobs or not.
   * @param {Boolean} currentState.showAllRoles - Whether to show all roles or not.
   */
  const _fetchJobs = async ({
    showActiveOnly,
    showAllRoles
  } = state) => {
    const query = {
      ...QUERY__JOB_APP_MGM,
    };

    // ADMIN USER
    if (Core.isAdmin()) {
      if (showActiveOnly) {
        query.where = { state: STATE_ACTIVE };
      }
    }
    // NORMAL USER
    else {
      query.where = { state: STATE_ACTIVE };
      query.fields = query.fields.filter(
        (field) => !Str(field).match(/have/i)
      );
    }
    if (
      NOT(showAllRoles)
    ) {
      query.where = {
        ...query.where,
        roles: {
          inq: candidateRoles
        }
      };
    }

    updateState({ jobs: await readLoopbackRecord(query) });
  }

  // IF CANDIDATE ROLES CHANGED THEN FETCH JOBS
  if (
    NOT(
      isEqual(
        candidateRoles,
        mem.current.roles
      )
    )
  ) {
    mem.current.roles = candidateRoles;
    _fetchJobs();
  }

  console.debug(
    'PermitJobsDecision...',
    '\n', state,
    '\n', data,
    '\n', persistedModels
  );

  // RENDER
  return (
    <Fieldset
      wrapperProps={{
        className: 'm-0'
      }}
    >
      <RefBox id={KEY__JOB_APP_MGM} />
      <Once name='OnceMount'
        promise={_onMount}
      />
      <Box row className='mt-1'>
        <Box row className="mr-2">
          <Icon icon="search" className="icon16 mr-1 " />
          <TextField disabledBlurExport
            placeholder="Filter by employer"
            value={state.employerFilter}
            onChange={_onChange('employerFilter')}
          />
        </Box>
        <Box row>
          <Icon icon="search" className="icon16 mr-1 " />
          <TextField disabledBlurExport
            placeholder="Filter by job title"
            value={state.jobTitleFilter}
            onChange={_onChange('jobTitleFilter')}
          />
        </Box>
        <Switch
          id="active-jobs"
          acl={Core.isAdmin()}
          checked={!!state.showActiveOnly}
          onChange={_onToggle('showActiveOnly')}
          label="Active jobs only"
        />
        <Switch
          id="all-roles"
          checked={!!state.showAllRoles}
          onChange={_onToggle('showAllRoles')}
          label="Show all roles"
        />
        <Box className='c-gray fw-400 f-sm ml-1'>
          Including unmatched
        </Box>
      </Box>
      <Box row scroll flex1 className='mt-1'>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Cell className={COL__HEADER__CLASSNAME}>
                #
              </Table.Cell>
              <Table.Cell className={COL__HEADER__CLASSNAME}>
                Employer
              </Table.Cell>
              <Table.Cell className={COL__HEADER__CLASSNAME}>
                Job
              </Table.Cell>
              <Table.Cell className={COL__HEADER__CLASSNAME}>
                Representation
              </Table.Cell>
              <Table.Cell className={COL__HEADER__CLASSNAME}>
                <Box className='f-md'>
                  Permit: spoken with candidate and have consent<br />
                  Pitch: consent pending
                </Box>
              </Table.Cell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.slice(0, state.visibleJobs).map((model, index) => (
              <Table.Row
                key={model.ID__JOB}
                className={joinClassName([
                  (mem.current.jobId === model.ID__JOB) &&
                  ROW__SELECTED__CLASSNAME
                ])}
              >

                {/** INDEX */}
                <Table.Cell className={COL__COMMON__CLASSNAME}>
                  {index + 1}
                </Table.Cell>

                {/** EMPLOYER */}
                <Table.Cell className={COL__COMMON__CLASSNAME}>
                  {model.NAME__EMPLOYER}
                </Table.Cell>

                {/** JOB */}
                <Table.Cell className={COL__COMMON__CLASSNAME}>
                  {model.NAME__JOB}
                </Table.Cell>

                {/** REPRESENTATION */}
                <Table.Cell className={COL__COMMON__CLASSNAME}>
                  <Chip
                    className={joinClassName([
                      CHIP__CLASSNAME,
                      getRepresentationColor(model.KEY__PERSISTED__REPRESENTATION)
                    ])}
                  >
                    {model.KEY__PERSISTED__REPRESENTATION}
                  </Chip>
                </Table.Cell>

                {/** DECISION */}
                <Table.Cell className={COL__COMMON__CLASSNAME}>

                  {/** DECISION ACTIONS **/}
                  {
                    // PERSISTED REPRESENTATION is AVAILABLE or YOURS
                    [
                      KEY__JOB_APP_MGM__REPRESENTATION__AVAILABLE,
                      KEY__JOB_APP_MGM__REPRESENTATION__YOURS
                    ].includes(model.KEY__PERSISTED__REPRESENTATION)

                    // AND
                    // PERSISTED DECISION is not UNDEFINED OR PERMITTED
                    && NOT(
                      [
                        undefined,
                        KEY__JOB_APP_MGM__DECISION__PERMITTED
                      ].includes(model.KEY__PERSISTED__DECISION)
                    )

                    && (
                      <Box row noWrap>
                        <ActionDecision
                          model={model}
                          decisionKey={KEY__JOB_APP_MGM__DECISION__PERMITTED}
                          onClick={_handleDecision}
                        />
                        <ActionDecision
                          model={model}
                          decisionKey={KEY__JOB_APP_MGM__DECISION__PITCHED}
                          onClick={_handleDecision}
                        />
                        <ActionDecision
                          model={model}
                          decisionKey={KEY__JOB_APP_MGM__DECISION__DECLINED}
                          onClick={_handleDecision}
                        />
                      </Box>
                    )}

                  {/** DECISION STATUS **/}
                  {
                    (model.KEY__PERSISTED__REPRESENTATION === KEY__JOB_APP_MGM__REPRESENTATION__YOURS)
                    && (model.KEY__PERSISTED__DECISION === KEY__JOB_APP_MGM__DECISION__PERMITTED)
                    && (
                      <>
                        <Chip
                          className={joinClassName([
                            CHIP__CLASSNAME,
                            'bg-green-light'
                          ])}
                        >
                          {KEY__JOB_APP_MGM__STATUS__PERMITTED}
                        </Chip>
                        <IconButton
                          icon='edit'
                          onClick={(event) => {
                            openAnswerJobsQuestions([model.ID__JOB]);
                          }}
                          title='Answer job questions'
                          placement={PLACEMENT__RIGHT}
                        />
                      </>
                    )
                  }

                  {model.KEY__PERSISTED__REPRESENTATION === KEY__JOB_APP_MGM__REPRESENTATION__TBD && (
                    <Chip
                      className={joinClassName([
                        CHIP__CLASSNAME,
                        'bg-yellow-light'
                      ])}
                    >
                      {KEY__JOB_APP_MGM__STATUS__PENDING}
                    </Chip>
                  )}

                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Box>

      <ErrorMessage
        show={
          NOT(state.showAllRoles)
          && !candidateRoles.length
        }
        message="Select roles above to view matching job opportunities"
      />

      <Box row className='mt-2 space-between'>

        <Box row>
          {(data.length > VALUE__JOB_APP_MGM__VISIBLE_ROWS) &&
            state.visibleJobs < data.length && (
              <Button outlined minW120 className='mr-1'
                onClick={_showMore}
              >
                Show More
              </Button>
            )}

          {(state.visibleJobs > VALUE__JOB_APP_MGM__VISIBLE_ROWS) && (
            <Button outlined minW120
              variant="outline"
              onClick={_showLess}
            >
              Show Less
            </Button>
          )}
        </Box>

      </Box>

    </Fieldset>
  );
}


/**
 * Renders an action decision button.
 * @param {object} props The component props.
 * @param {object} props.model The model object.
 * @param {string} props.decisionKey The decision key to render.
 * @param {Function} [props.onClick] To call when the button is clicked.
 * @returns {ReactElement} The rendered component.
 */
export function ActionDecision({
  model,
  decisionKey,
  onClick = (model, decisionKey) => { }
}) {
  return (
    <Button small minW100 outlined
      onClick={(event) => onClick(
        model,
        decisionKey
      )}
      className='mr-1'
    >
      {
        join([
          decisionKey,
          (model.KEY__CURRENT__DECISION === decisionKey) && '✓'
        ], ' ')
      }
    </Button>
  );
}


/**
 * Returns a color class name based on the representation value.
 *
 * @param {string} value - The representation key indicating the job application status.
 * @returns {string} - A string representing the CSS class for the background color
 * corresponding to the given representation key. Defaults to 'bg-white-light' if
 * the representation is not recognized.
 */
export function getRepresentationColor(value = '') {
  switch (value) {
    case KEY__JOB_APP_MGM__REPRESENTATION__AVAILABLE:
      return 'bg-green-light';
    case KEY__JOB_APP_MGM__REPRESENTATION__TBD:
      return 'bg-yellow-light';
    case KEY__JOB_APP_MGM__REPRESENTATION__YOURS:
      return 'bg-blue-light';
    case KEY__JOB_APP_MGM__REPRESENTATION__TAKEN:
      return 'bg-red-light';
    default:
      return 'bg-white-light';
  }
}


/**
 * Determines the representation key for a given job based on ownership, duplicate,
 * and permission status. It uses the `thisJobIsConflictColorMethod` from the 
 * `CandidateJobChoices` to get the conflict color for the job and maps it to a 
 * representation key.
 *
 * @param {Object} job - The job object containing the job ID.
 * @returns {string} - The representation key, which can be 'Available', 'TBD', 
 * 'Yours', or 'Taken', based on the conflict color result.
 */
export function getRepresentationKey({ job }) {

  // GET CONFLICT COLOR from LEGACY CODE ( CandidateJobChoices )
  const color = getLegacyRepresentationColor({ jobId: job.id });

  // MAP CONFLICT COLOR to REPRESENTATION label
  const result = {
    [colors.purple.common]: KEY__JOB_APP_MGM__REPRESENTATION__AVAILABLE,
    [colors.gray.common]: KEY__JOB_APP_MGM__REPRESENTATION__TBD,
    [colors.cyan.darker]: KEY__JOB_APP_MGM__REPRESENTATION__YOURS,
    [colors.orange.darker]: KEY__JOB_APP_MGM__REPRESENTATION__TAKEN
  }[color];

  // console.debug('getRepresentationKey', results, result);
  return result;
}


/**
 * Determines the decision key for a given job based on its status in the 
 * CandidateEditController's state. The decision key indicates whether the 
 * job is permitted, pitched, or declined.
 *
 * @param {Object} job - The job object containing the job ID.
 * @returns {string|null} - The decision key, which can be 'Permitted', 
 * 'Pitched', or 'Declined'. Returns null if the job is not found in any 
 * of these states.
 */
export function getDecisionKey({ job }) {
  const isPermitted = Arr(
    CandidateEditController().state.jobsPermitted
  ).includes(job.id);
  const isPitched = Arr(
    CandidateEditController().state.jobsPitched
  ).includes(job.id);
  const isDeclined = Arr(
    CandidateEditController().state.jobsDeclined
  ).includes(job.id);
  if (isPermitted) {
    return KEY__JOB_APP_MGM__DECISION__PERMITTED;
  }
  if (isPitched) {
    return KEY__JOB_APP_MGM__DECISION__PITCHED;
  }
  if (isDeclined) {
    return KEY__JOB_APP_MGM__DECISION__DECLINED;
  }
  return null;
}


/**
 * Generates an order key string for a job model, which is used to order the
 * job list in the PermitJobsDecision component.
 *
 * The order key is a string consisting of four parts separated by '__'. The
 * first part is the index of the decision key in the decisionOrder array. The
 * second part is the index of the representation key in the representationOrder
 * array. The third part is the employer name, and the fourth part is the job
 * name.
 *
 * This order key is used to sort the job list by decision, representation, and
 * then by employer and job name.
 *
 * @param {Object} model - The job model containing the decision key and
 * representation key.
 * @returns {string} - The order key string.
 */
export function getOrderKey(model) {
  const adminRepresentationOrder = [
    KEY__JOB_APP_MGM__REPRESENTATION__TBD,
    KEY__JOB_APP_MGM__REPRESENTATION__YOURS,
    KEY__JOB_APP_MGM__REPRESENTATION__TAKEN,
    KEY__JOB_APP_MGM__REPRESENTATION__AVAILABLE,
    null,
  ];
  const recruiterRepresentationOrder = [
    KEY__JOB_APP_MGM__REPRESENTATION__AVAILABLE,
    KEY__JOB_APP_MGM__REPRESENTATION__TAKEN,
    KEY__JOB_APP_MGM__REPRESENTATION__TBD,
    KEY__JOB_APP_MGM__REPRESENTATION__YOURS,
    null,
  ];
  const isPermitted = Arr(
    CandidateEditController().state.jobsPermitted
  ).includes(model.id);
  return [
    Core.isAdmin() ? !isPermitted : isPermitted,
    Core.isAdmin()
      ? adminRepresentationOrder.indexOf(
        model.KEY__PERSISTED__REPRESENTATION
      )
      : recruiterRepresentationOrder.indexOf(
        model.KEY__PERSISTED__REPRESENTATION
      ),
    model.NAME__EMPLOYER,
    model.NAME__JOB
  ].join('__');
}


/**
 * Opens the AnswerJobsQuestions dialog for the specified job IDs.
 * 
 * @param {Array} jobIds - An array of job IDs for which to open the AnswerJobsQuestions dialog.
 */
export function openAnswerJobsQuestions(jobIds) {
  CandidateEditController().AnswerJobsQuestions.open({
    jobIds
  });
}


/**
 * Generates a key name for a legacy representation color value stored in the
 * key-value store. The key name is a string consisting of three parts separated
 * by '__'. The first part is 'LegacyRepresentationColor', the second part is the
 * candidate ID, and the third part is the job ID.
 *
 * @param {Object} param - An object containing the candidate ID and job ID.
 * @param {string} param.jobId - The job ID.
 * @returns {string} - The key name string.
 */
export function getLegacyRepresentationColorKey({
  jobId
}) {
  return joinKeyName([
    'LegacyRepresentationColor',
    CandidateEditController().state.id,
    jobId
  ]);
}


/**
 * Sets a legacy representation color for a given job ID in the key-value store.
 *
 * This function is used to store legacy representation color values when the
 * CandidateJobChoices component's chips are rendered. The color values are stored in the
 * key-value store with a key name generated by the
 * getLegacyRepresentationColorKey function. The key name is a string consisting
 * of three parts separated by '__'. The first part is 'LegacyRepresentationColor',
 * the second part is the candidate ID, and the third part is the job ID.
 *
 * @param {Object} param - An object containing the job ID and color value.
 * @param {string} param.jobId - The job ID.
 * @param {string} param.color - The color string value.
 * @returns {Promise} - A promise that resolves when the key-value store has been
 * updated.
 */
export function setLegacyRepresentationColor({
  jobId,
  color
}) {
  const key = getLegacyRepresentationColorKey({ jobId });
  return Core.setKeyValue(key, color);
}


/**
 * Retrieves a legacy representation color value for a given job ID from the
 * key-value store.
 *
 * The legacy representation color value is stored in the key-value store with a
 * key name generated by the getLegacyRepresentationColorKey function. The key name
 * is a string consisting of three parts separated by '__'. The first part is
 * 'LegacyRepresentationColor', the second part is the candidate ID, and the third
 * part is the job ID.
 *
 * @param {Object} param - An object containing the job ID.
 * @param {string} param.jobId - The job ID.
 * @returns {Promise} - A promise that resolves with the color string value
 * stored in the key-value store, or null if no value is found.
 */
export function getLegacyRepresentationColor({
  jobId
}) {
  const key = getLegacyRepresentationColorKey({ jobId });
  return Core.getKeyValue(key);
}
