import {
  debounce
} from 'lodash';
import {
  NOT,
  YES
} from '../../../lib/Boolean.lib';
import useState from '../../../lib/hooks/useState.hook';
import {
  ErrorMessage,
  LoadingMessage
} from './Message';

export default function Once({
  acl = true,
  promise = async (query) => ([]),
  onLoad: resolve = (results) => (results),
  name = 'Once',
  context = {},
  render: Render,
  ...props
}) {
  const [state, updateState] = useState();
  Object.assign(context, {
    [name]: {
      state,
      updateState
    }
  });
  debounce(async () => {
    if (YES(acl) && NOT(state.busy || state.error || state.ready)) {
      await updateState({ busy: true });
      try {
        const result = await promise();
        await updateState({ result });
        debounce(resolve)(result);
        await updateState({ ready: true });
      }
      catch (error) {
        console.error(error);
        await updateState({ error });
      }
      await updateState({ busy: false });
    }
    // console.debug(`Once ${name.replace(/once/i, '')}`, state);
  })();
  const status = state.busy ? 'busy' : state.error ? 'error' : state.ready ? 'ready' : 'initial';
  switch (status) {
    case 'busy':
      return <LoadingMessage {...props} message='Loading...' />;
    case 'error':
      return <ErrorMessage {...props} message={state.error.message} />;
    default:
      return Render ? <Render {...props} {...state} /> : null;
  }
}
