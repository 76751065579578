import {
  sanitizeArr
} from '../../lib/Array.lib';
import Core from '../../lib/Core';
import {
  joinKeyName
} from '../../lib/String.lib';
import Chip from '../Layout/Wrappers/Chip';

export default function ChipsFilterControl({
  name = 'ChipsFilerControl',
  list = [],
  onDelete = (item, index) => { },
}) {
  return (
    <>
      {
        sanitizeArr(list).map(
          (item, index) => (
            <Chip styled small
              key={
                joinKeyName([
                  name,
                  index,
                  Core.getKey()
                ])
              }
              onDelete={(event) => onDelete(item, index)}
            >
              {item.name}
            </Chip>
          )
        )
      }
    </>
  );
}
