import {
  compile
} from 'handlebars';
import {
  debounce
} from 'lodash';
import {
  Arr,
  join,
  sanitizeArr
} from '../../../../lib/Array.lib';
import {
  CSS_BLACK_RGB,
  CSS_RED_RGB
} from '../../../../lib/Constants';
import Core from '../../../../lib/Core';
import Definition from '../../../../lib/Definition';
import {
  NOT,
  YES,
  capitalize
} from '../../../../lib/GenericTools.lib';
import {
  joinHTML
} from '../../../../lib/HTML.lib';
import {
  Obj
} from '../../../../lib/Object.lib';
import {
  trim
} from '../../../../lib/String.lib';
import {
  TEMPLATE__MATCH_REJECTION_HAVES,
  TEMPLATE__MATCH_REJECTION_HAVES__SINGLE_LINE
} from '../../../../lib/templates/RejectionHaves.template';
import formatMoney from '../../../../lib/tools/formatMoney';
import {
  THEME__COLOR__ERROR,
  THEME__COLOR__INHERIT,
  THEME__COLOR__SUCCESS
} from '../../../Layout/Libraries/Theme.lib';
import Button from '../../../Layout/Wrappers/Button';
import HavesEditor from '../HavesEditor';
import {
  evaluateHaves
} from './Evaluations.lib';

export const HAVE__YES_MET__KEY = 'yes';
export const HAVE__NOT_MET__KEY = 'not';
export const HAVE__MAYBE__KEY = 'maybe';
export const HAVE__YES_MET__ICON = `✅`;
export const HAVE__NOT_MET__ICON = `❌`;
export const HAVE__MAYBE__ICON = `︖`;
export const MUST_HAVE_KEY = 'required';
export const NICE_TO_HAVE_KEY = 'desirable';

export const HAVE__OPTIONS = [
  {
    id: HAVE__YES_MET__KEY,
    info: 'Yes, met',
    icon: 'done',
    color: THEME__COLOR__SUCCESS,
  },
  {
    id: HAVE__NOT_MET__KEY,
    info: 'No, not met',
    icon: 'close',
    color: THEME__COLOR__ERROR,
  },
  {
    id: HAVE__MAYBE__KEY,
    info: 'Maybe, unclear',
    icon: 'question_mark',
    color: THEME__COLOR__INHERIT,
  }
]

export const HTML_GENERATORS = {
  and: generateSingleHaveHTMLString,
  or: generateSingleHaveHTMLString,
  not: generateSingleHaveHTMLString,
  chip: getChipHaveLabel,
  chipGroup: getChipHaveLabel,
  chipTag: getChipHaveLabel,
  yearsOfExperience: getYoEString,
  salary: getSalaryString,
  text: getTextHaveString
};

export async function editHaves(options) {
  const KEY__HAVES_EDITOR = 'haves_editor';
  return new Promise((resolve) => {
    Core.dialog.open({
      paperStyle: {
        width: 1024,
        maxWidth: 'calc(100vw - 2rem)'
      },
      title: 'Editing Candidate met Job haves',
      content: (
        <HavesEditor
          reference={(self) => Core.setKeyValue(KEY__HAVES_EDITOR, self)}
          {...options}
        />
      ),
      actions: [
        <Button primary minW120
          onClick={debounce((event) => resolve(
            Core.getKeyValue(KEY__HAVES_EDITOR).values()
          ), 111)}
        >
          Next
        </Button>
      ]
    });
  })
}

export const KEY__MATCH_HAVES__ACCEPTANCE = 'acceptance';
export const KEY__MATCH_HAVES__REJECTION = 'rejection';
export async function generateMatchHavesHTMLString({
  job = {},
  candidate = {},
  edit = null,
}) {
  console.debug('generateRejectionHavesHTMLString :job :candidate\n', job, candidate);
  if (NOT(job.id) || NOT(candidate.id)) { return ''; }

  // Generating met results in a JSON structure
  const { required, desirable } = (
    (
      YES(edit)
    ) ? (
      // Use ex. Rejection email, CAVE email (next feature)
      await editHaves({
        ...Obj(
          await evaluateHaves({
            job,
            candidate,
            // Flag: acceptance | rejection
            edit,

          })
        ),
      })
    ) : (
      // Use ex. CandidateJobChoices tooltips
      await evaluateHaves({
        job,
        candidate
      })
    )
  );

  // Compiling results as HTML text
  const _filterIgnored = (have) => (!have.ignore);
  const result = compile(TEMPLATE__MATCH_REJECTION_HAVES)({
    REQUIRED: join([
      ...required.not.filter(_filterIgnored).map((have) => generateSingleHaveHTMLString({ have })),
      ...required.yes.filter(_filterIgnored).map((have) => generateSingleHaveHTMLString({ have })),
      ...required.maybe.filter(_filterIgnored).map((have) => generateSingleHaveHTMLString({ have }))
    ], ''),
    DESIRABLE: join([
      ...desirable.not.filter(_filterIgnored).map((have) => generateSingleHaveHTMLString({ have })),
      ...desirable.yes.filter(_filterIgnored).map((have) => generateSingleHaveHTMLString({ have })),
      ...desirable.maybe.filter(_filterIgnored).map((have) => generateSingleHaveHTMLString({ have }))
    ], '')
  });

  return result;
}

export function generateSingleHaveHTMLString({ have, icon = true, colored }) {
  const isYoEType = (have.type === 'yearsOfExperience');
  const isSalaryType = (have.type === 'salary');
  const atLeast = parseInt(have.atLeast);
  const condition = (
    (
      isYoEType
    ) ? (
      (
        !!atLeast
      ) ? (
        `${atLeast}yrs+:`
      ) : (
        ''
      )
    ) : (
      isSalaryType
    ) ? (
      (
        !!atLeast
      ) ? (
        `$${formatMoney(atLeast, 0)} >`
      ) : (
        ''
      )
    ) : (
      (
        !!atLeast && (atLeast >= 2)
      ) ? (
        `${atLeast}+:`
      ) : (
        ''
      )
    )
  )
  const labels = join(
    (have.value ? [have] : sanitizeArr(have.args)).map(
      (have) => {
        if (HTML_GENERATORS[have.type]) {
          return HTML_GENERATORS[have.type]({ have });
        }
        const label = trim(have.value);
        return label ? `<b>${label}</b>` : label;
      }
    )
  );
  let specialInstruction = capitalize(have.specialInstruction);
  if (NOT(labels) && specialInstruction) {
    specialInstruction = `<b>${specialInstruction}</b>`;
  }
  const ICON = trim(icon && join([
    '&nbsp;&nbsp;&nbsp;&nbsp;',
    {
      [HAVE__YES_MET__KEY]: `${HAVE__YES_MET__ICON}&nbsp;&nbsp;`,
      [HAVE__NOT_MET__KEY]: `${HAVE__NOT_MET__ICON}&nbsp;&nbsp;`,
      [HAVE__MAYBE__KEY]: `&nbsp;${HAVE__MAYBE__ICON}&nbsp;&nbsp;`,
    }[have.met]
  ], ''));
  let result = compile(TEMPLATE__MATCH_REJECTION_HAVES__SINGLE_LINE)({
    ICON,
    COLOR: colored ? CSS_RED_RGB : CSS_BLACK_RGB,
    OPERATOR: (
      (have.type === 'and') ? `All: `
        : (have.type === 'not') ? `Not: `
          : ''
    ),
    CONDITION: condition ? `${condition} ` : '',
    LABELS: labels ? `${labels}${specialInstruction ? ' - ' : ' '}` : '',
    INSTRUCTION: specialInstruction ? `${specialInstruction} ` : '',
    COMMENT: joinHTML(have.comment),
  });
  return result;
}

export function getChipHaveLabel({ have }) {
  let defRaw = !!Definition.getRawDef(have.key) ? Definition.getRawDef(have.key) : [];
  let groupEntry = Obj(Arr(defRaw.groups).find(group => group.id === have.value));
  let label = have.type === 'chipGroup' ? groupEntry.name : Definition.getLabel(have.key, have.value);
  label = have.type === 'chipTag' ? have.value : label;
  label = trim(label);
  return (label && (have.match === true)) ? `<b>${label}</b>` : label;
}

export function getYoEString({ have }) {
  const value = parseInt(have.value);
  return value ? `<b>${value}yrs</b>` : '';
}

export function getSalaryString({ have }) {
  const value = parseInt(have.value);
  return value ? `<b>$${formatMoney(value, 0)}</b>` : '';
}

export function getTextHaveString({ have }) {
  return capitalize(trim(have.value));
}
