import {
  REJECTION_REASON__CANDIDATE__NOT_INTERESTED,
  STATUS_E_RECRUITER,
  STATUS_W_CANDIDATE_PERMISSION
} from '../../../dictionaries/Engagement.dic';
import Candidate from '../../../lib/Candidate';
import getCandidateEntityName from '../../../lib/Candidate/getCandidateEntityName.fun';
import Core from '../../../lib/Core';
import {
  ACCOUNT_ACTION__EDIT_CANDIDATE
} from '../../../lib/Definition';
import Engagement from '../../../lib/Engagement';
import useState from '../../../lib/hooks/useState.hook';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import TableCollection, {
  KEY__TABLE_COLLECTION__VIEW_TYPE__CANDIDATE
} from '../../Layout/Wrappers/TableCollection';

export function CandidateEngagementsDetails({
  candidate = {},
  isDrawer = false,
}) {
  return (
    <TableCollection
      where={{ candidateId: candidate.id }}
      title={
        <Box row w100 className='bg-white f-lg p-2'>
          <div className='fw-600 c-cyan-darker mr-1'>
            Engagements for candidate:
          </div>
          <span className='c-black'>
            {getCandidateEntityName(candidate)}
          </span>
        </Box>
      }
      isDrawer={isDrawer}
      viewType={KEY__TABLE_COLLECTION__VIEW_TYPE__CANDIDATE}
    />
  );
}

/** @deprecated This function was used on status col for candidate engagements details */
/** @todo review to cleanup, ask to BB  */
export function WaitingCandidatePermissionActions({ engagement, refetch = async () => null }) {
  let [state, setState] = useState({});
  async function _updateState(update, delay) {
    update = Object(update) === update ? update : {};
    state = { ...state, ...update };
    delay ? setTimeout(() => setState(state), delay) : setState(state);
    return await new Promise((resolve) => setTimeout(() => resolve(state), delay));
  }
  async function _closeEngagement() {
    await _updateState({ closing: true });
    let { candidate } = engagement;
    let { jobsDeclined = [] } = candidate;
    await Engagement.close({
      engagement,
      status: STATUS_E_RECRUITER,
      rejectionReason: REJECTION_REASON__CANDIDATE__NOT_INTERESTED
    }).catch(Core.showError);
    jobsDeclined.push(engagement.jobId);
    await Candidate.update(engagement.candidateId, {
      jobsDeclined
    }).catch(Core.showError);
    await refetch();
    await _updateState({ closing: false });
  }
  return (engagement.status === STATUS_W_CANDIDATE_PERMISSION) && (
    <div>
      {(!Core.isAdmin() || Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE })) && (
        <Button
          onClick={event => {
            Core.go({ to: `/candidate/edit-section/${engagement.candidateId}` })
          }}
          className='tt-unset mr-1'
          small
        >
          Edit permission
        </Button>
      )}
      <Button
        onClick={async event => {
          let { candidate } = engagement;
          let { jobsDeclined = [], jobsPermitted = [] } = candidate;
          if (jobsPermitted.includes(engagement.jobId)) {
            Core.showWarning('You already permitted the job, contact 10by10 to withdraw permission');
          }
          else if (!jobsDeclined.includes(engagement.jobId) && !state.closing) {
            await _closeEngagement();
          }
        }}
        className='tt-unset'
        disabled={!!state.closing}
        small
      >
        Decline
      </Button>
    </div>
  );
}
