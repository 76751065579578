import EmployerPendingsUI from '../../../dictionaries/EmployerPendingsUI.dic';
import {
  NOT
} from '../../../lib/Boolean.lib';
import Core from '../../../lib/Core';
import { Fun } from '../../../lib/Function.lib';
import useState from '../../../lib/hooks/useState.hook';
import openDialogAccountEmployerSelector, {
  KEY__ACTION__GENERATE_TODO_SUMMARY
} from '../../Accounts/Libraries/openDialogAccountEmployerSelector.fun';
import {
  mapWrapper
} from '../../Layout/Libraries/Theme.lib';
import Box from '../../Layout/Wrappers/Box';
import IconButton from '../../Layout/Wrappers/IconButton';
import IconStatus from '../../Layout/Wrappers/IconStatus';
import Menu from '../../Layout/Wrappers/Menu';
import Typography from '../../Layout/Wrappers/Typography';

export default function TriggerWaitingEmployerSummary({
  acl = Core.isAdmin(),
  employerId = '',
  children,
  type = 'icon',
  wrapperProps = {},
  onClick = Fun,
  ...props
}) {
  const [state, updateState] = useState();
  if (NOT(employerId && acl)) { return null; }
  const statusIcon = IconStatus({
    ...state,
    loadingTitle: EmployerPendingsUI.TriggerWaitingEmployerSummary.IconButton.loadingTooltip
  });
  const _onClick = async (event) => {
    await updateState({
      loading: true,
      error: false
    });
    try {
      await openDialogAccountEmployerSelector({
        employerId,
        onAcceptAction: KEY__ACTION__GENERATE_TODO_SUMMARY
      });
      onClick(event);
    }
    catch (error) {
      await updateState({ error });
    }
    await updateState({ loading: false });
  }
  mapWrapper({
    role: 'TriggerWaitingEmployerSummary',
    props: wrapperProps,
    assign: {
      ...props,
      onClick: _onClick,
      disabled: (state.loading),
      children: (
        (
          children
        ) ? (
          statusIcon || children
        ) : (
          type === 'icon'
        ) ? (
          (
            statusIcon
          ) ? (
            <IconButton icon={statusIcon} />
          ) : (
            <IconButton
              title={EmployerPendingsUI.TriggerWaitingEmployerSummary.IconButton.tooltip}
              icon='list'
            />
          )
        ) : (
          <Menu.Item divider>
            <Typography mr>
              {EmployerPendingsUI.TriggerWaitingEmployerSummary.MenuItem.title}
            </Typography>
            {statusIcon}
          </Menu.Item>
        )
      )
    }
  });
  console.debug(
    'TriggerWaitingEmployerSummary',
    {
      state,
      wrapperProps,
      statusIcon
    }
  );
  return (
    <Box {...wrapperProps} />
  )
}
