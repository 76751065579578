import {
  uniqueId
} from 'lodash';
import Ui3DotsMenu from '../../../dictionaries/Ui3DotsMenu.dic';
import {
  Arr
} from '../../../lib/Array.lib';
import {
  NOT
} from '../../../lib/Boolean.lib';
import Candidate, {
  deleteResumeFile
} from '../../../lib/Candidate';
import Core from '../../../lib/Core';
import {
  ACCOUNT_ACTION__EDIT_CANDIDATE,
  ACCOUNT_ACTION__LIST_CANDIDATES,
  ACCOUNT_ACTION__LIST_ENGAGEMENTS,
  ACCOUNT_ACTION__MATCH_CANDIDATE
} from '../../../lib/Definition';
import {
  Obj
} from '../../../lib/Object.lib';
import SovrenData from '../../../lib/SovrenData';
import {
  compileText,
  trim
} from '../../../lib/String.lib';
import downloadFile from '../../../lib/tools/downloadFile';
import {
  matchLocation,
  reloadLocation
} from '../../../lib/URL.lib';
import {
  CANDIDATE_UI
} from '../../Candidates/CandidateUI.dic';
import ActionComponentCandidateResumePdf from '../../Candidates/ResumePdf/ActionComponentCandidateResumePdf';
import {
  openWithdrawCandidate
} from '../../Engagements/WithdrawCandidate';
import Menu from '../../Layout/Wrappers/Menu';
import Typography from '../../Layout/Wrappers/Typography';
import ResumeMatch from '../../Shared/ResumeMatch';

let count = 0;

export const moreMenuOptionsCandidates = [
  {
    label: 'Edit Candidate',
    to: candidate => (`/candidate/edit/${candidate.id}`),
    target: candidate => (candidate.isDuplicate ? '_blank' : ''),
    acl: (candidate) => (
      Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE }) &&
      NOT(matchLocation(/candidate\/(create|edit)/i))
    ),
  },
  {
    to: candidate => (`/candidate/match/${candidate.id}`),
    label: 'Match',
    acl: (candidate) => (
      Core.isAdmin({ action: ACCOUNT_ACTION__MATCH_CANDIDATE }) &&
      NOT(matchLocation(/\/(create|edit|match)/i))
    ),
  },
  {
    acl: candidate => Core.isAdmin({ action: ACCOUNT_ACTION__LIST_ENGAGEMENTS }),
    label: candidate => `Engagements Details${candidate.engagements && candidate.engagements.length ? `(${candidate.engagements.length})` : ''}`,
    action: candidate => candidate.openEngagementsDetails(),
  },
  {
    label: 'View Dups',
    action: candidate => Core.openExact(`/candidates?viewDup=t&cId=${candidate.id}`),
    acl: candidate => Core.isAdmin({ action: ACCOUNT_ACTION__LIST_CANDIDATES }),
  },
  {
    label: 'LinkedIn',
    action: candidate => Core.openPopUp(candidate.linkedInURL),
    acl: candidate => (!!candidate.linkedInURL && Core.isAdmin()),
  },
  {
    label: 'Github',
    action: candidate => Core.openPopUp(candidate.gitHubURL),
    acl: candidate => (!!candidate.gitHubURL && Core.isAdmin()),
  },
  {
    render: (candidate) => {
      return (
        <>
          <ResumeMatch
            key={`more-options-menu-option-${candidate.id}-${count++}`}
            candidateResume={candidate.resumeTxtUrl}
          />
          <ActionComponentCandidateResumePdf
            candidate={candidate}
          />
        </>
      )
    },
    acl: candidate => (candidate.resumeTxtUrl),
  },
  {
    acl: (candidate) => !!candidate.resumes.length,
    render: (candidate, { key, ...props }) => {
      return (
        <div {...props} key={`engagements__more_menu__option__${key}__${uniqueId()}`}>
          {Arr(candidate.resumes).map((resume, index) => (
            <Menu.Item
              key={`card_candidate_resume__${resume.url}__${index}`}
              title={`Download CV ${index + 1}`}
              onClick={(event) => {
                downloadFile({
                  url: resume.url,
                  mimeType: 'application/pdf',
                  onError: Core.showError,
                });
              }}
              divider
            >
              {compileText(Ui3DotsMenu.Candidate.downloadResume, {
                PREFIX: matchLocation(/\/(create|edit)/i) ? '' : Ui3DotsMenu.Candidate.PREFIX,
                COUNTER: index + 1
              })}
            </Menu.Item>
          ))}
        </div>
      );
    },
  },
  {
    acl: candidate => (!!candidate.resumes?.length),
    label: 'View Resume',
    action: candidate => Core.openPopUp(Candidate.getMyPdfUrl(candidate))
  },
  {
    acl: candidate => !!candidate.sovrenDataId,
    label: CANDIDATE_UI.resumeScrubbedUrl.moreMenuLabel,
    action: candidate => openScrubbedResume({ candidate })
  },
  {
    acl: candidate => Core.isAdmin(),
    label: 'Copy Confirmation Job Description',
    action: candidate => Candidate.bulkCopy(candidate)
  },
  {
    acl: (candidate) => (
      Core.isAdmin() &&
      NOT(matchLocation(/candidate\/(create|edit)/i))
    ),
    label: 'Message',
    action: candidate => candidate.openMessage()
  },
  {
    acl: candidate => Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE }),
    label: 'WithDraw Candidate',
    action: candidate => openWithdrawCandidate({ candidateId: candidate.id }),
  },
  {
    acl: candidate => Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE }),
    label: (
      <Typography className='c-red'>
        {compileText(Ui3DotsMenu.Common.deleteEntity, {
          ENTITY: matchLocation(/\/(create|edit)/i) ? '' : Ui3DotsMenu.Candidate.ENTITY
        })}
      </Typography>
    ),
    action: candidate => deleteCandidate(candidate)
  }
];

function deleteCandidate(candidate) {
  Candidate.fetchEngagements({ candidateId: candidate.id }).then(
    engagements =>
      Core.showConfirm({
        title: <>Confirm</>,
        message: (
          `Delete "${candidate._name}"${!!engagements.length
            ? ` and ${engagements.length} engagement${engagements.length === 1 ? "" : "s"
            }?`
            : ""
          }`
        ),
        onAccept() {
          Candidate.delete(candidate.id,
            async response => {
              if (Array.isArray(candidate.resumes)) {
                await Promise.all(candidate.resumes.map(async (resume) => {
                  await deleteResumeFile({ resume });
                  return SovrenData.destroyData({ resume, candidate });
                }));
              }
              Core.showSuccess('Candidate was deleted successfully', () => reloadLocation());
            },
            error => {
              if (!!error) {
                Core.showError('Can not delete candidate. Please contact support.');
              }
            }
          );
        },
        onAcceptLabel: 'Delete Candidate'
      })
  )
}

export async function openScrubbedResume({ candidate }) {
  let { resumeScrubbedUrl } = candidate;
  if (!trim(resumeScrubbedUrl) && !!candidate.sovrenDataId) {
    const sovrenDataRecord = Obj(Arr(
      await SovrenData.get({ where: { id: candidate.sovrenDataId }, limit: 1, fields: ['scrubbedParsedDocument'] })
    )[0]);
    resumeScrubbedUrl = sovrenDataRecord.scrubbedParsedDocument;
  }
  if (!!trim(resumeScrubbedUrl)) { return Core.openPopUp(resumeScrubbedUrl, 1600); }
  Core.showError(`Scrubbed resume no found`);
}
