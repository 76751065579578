import CircularProgress from '@mui/material/CircularProgress';
import {
  isString
} from 'lodash';
import moment from "moment";
import {
  Component
} from "react";
import Account from "../../lib/Account";
import {
  Arr
} from '../../lib/Array.lib';
import {
  NOT,
  YES
} from '../../lib/Boolean.lib';
import Core from "../../lib/Core";
import {
  TIMEZONE__LA
} from '../../lib/Date.lib';
import {
  ACCOUNT_ACTION__EDIT_ACCOUNT,
  ACCOUNT_ACTION__EDIT_EMPLOYER,
  ACCOUNT_ACTION__LIST_JOBS,
  ID__TAG__ACCOUNT_INTERACTION__RECRUITER_KEY,
  STATE_ACTIVE
} from '../../lib/Definition';
import Employer from "../../lib/Employer";
import Engagement from "../../lib/Engagement";
import globalErrorHandler from '../../lib/Error/globalErrorHandler.fun';
import {
  debounce
} from '../../lib/GenericTools.lib';
import Job from '../../lib/Job';
import getJobEntityName from '../../lib/Job/getJobEntityName.fun';
import {
  getPersonName,
  Obj
} from '../../lib/Object.lib';
import Store from "../../lib/Store";
import {
  Str,
  trim
} from '../../lib/String.lib';
import onReady from "../../lib/tools/onReady";
import {
  getLocation
} from '../../lib/URL.lib';
import {
  joinClassName
} from '../Layout/Libraries/Theme.lib';
import Box, {
  RefBox
} from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import Checkbox from '../Layout/Wrappers/Checkbox';
import DatePicker from '../Layout/Wrappers/DatePicker';
import Dialog from '../Layout/Wrappers/Dialog';
import Divider from '../Layout/Wrappers/Divider';
import Icon from '../Layout/Wrappers/Icon';
import Menu from '../Layout/Wrappers/Menu';
import Navigate from '../Layout/Wrappers/Navigate';
import Paper from '../Layout/Wrappers/Paper';
import {
  PLACEMENT__TOP_START
} from '../Layout/Wrappers/StyledTooltip';
import Table from '../Layout/Wrappers/Table';
import TableCollection, {
  KEY__TABLE_COLLECTION__VIEW_TYPE__COMMON,
  KEY__TABLE_COLLECTION__VIEW_TYPE__EMPLOYER,
  KEY__TABLE_COLLECTION__VIEW_TYPE__JOB,
  KEY__TABLE_COLLECTION__VIEW_TYPE__RECRUITER
} from '../Layout/Wrappers/TableCollection';
import Typography from '../Layout/Wrappers/Typography';

export const VALUE__REPORTS__PERIOD_LENGTH = Core.isOnDev() ? '7' : '10';
export const VALUES__REPORTS__PERIOD_LENGTH = ['3', '7', '12', '16', '31'];

const styleStartTableCell = {
  minWidth: 240,
  width: 240,
  maxWidth: 240,
};

/**
 * Evaluates the visibility of engagements based on column attributes.
 * 
 * @param {Object} col - The column object containing engagement attributes.
 * @param {string} col.dateAttr - The date attribute of the engagement.
 * @param {number} col.value - The value associated with the column.
 * 
 * @returns {boolean} - Returns true if the date attribute is a non-empty string
 * and the column has a value, otherwise false.
 * 
 * @note The dateAttr type can be string or array, but the array case is not implemented.
 */
function evalEngagementsVisibility(col) {
  return (
    isString(col.dateAttr) &&
    trim(col.dateAttr) &&
    +col.value
  )
}

function getColClassName(col) {
  return evalEngagementsVisibility(col)
    ? 'cursor-pointer fw-600 c-purple'
    : 'opacity-50';
}

export default class Reports extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      accountId: "0",
      employerId: "0",
      jobId: "0",
      rows: [],
      open: false,
      selectedRecruiterFilter: 'Key recruiter',
      selectedRecruitersPipeline: 'New Candidates',
      selectedEmployersPipeline: 'New Screen',
      selectedJobsPipeline: 'New Screen',
      selectedMetric: "Count",
      recruiters: [],
      employers: [],
      jobs: [],
      viewBy: "Engagements",
      periodLength: VALUE__REPORTS__PERIOD_LENGTH,
      selectedEmployersFilter: 'Active Employer',
      selectedEmployerMetric: "Count",
      selectedJobMetric: "Count",
      duration: "Daily",
      ...getDurationDates()
    };
    Store.set("path", getLocation());
    onReady(this, "rowsContainer").then(em => {
      Account.getWhere({ role: { neq: "EmployerRecruiter" } }, recruiters => {
        Employer.getActives(async (employers) => {
          let jobs = [];
          try {
            jobs = await Job.getActives();
          } catch (error) {
            globalErrorHandler(error);
          }
          this.setState(
            {
              accountId: "0",
              recruiters,
              employerId: "0",
              employers,
              jobId: "0",
              jobs
            },
            then => {
              this.getData();
            }
          );
        });
      });
    });
  };

  handleCountClicked = ({
    rowData = {},
    col = {},
    recruiterId = this.state.accountId || '0',
    employerId = this.state.employerId || '0',
    jobId = this.state.jobId || '0'
  }) => async (event) => {

    if (NOT(evalEngagementsVisibility(col))) { return; }

    const dateAttr = trim(col.dateAttr);
    const header = trim(col.group);
    const stage = Arr(col.stage);
    const duration = this.state.duration;

    const where = {
      and: [
        { [dateAttr]: { neq: null } }
      ]
    };

    let date = moment().tz(TIMEZONE__LA);
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    let week = 0;
    let day = 1;
    let month = 0;
    let year = 2000;

    if (NOT(header.match(/total/i))) {
      switch (duration) {

        case "Daily":
          month = parseInt(header.split('/')[0]) - 1;
          day = parseInt(header.split('/')[1]);
          date = moment().tz(TIMEZONE__LA).month(month).date(day).startOf('day');
          startDate = date.toISOString();
          endDate = date.endOf('day').toISOString();
          break;

        case "Weekly":
          week = parseInt(header.split('/')[0].slice(1));
          year = 2000 + parseInt(header.split('/')[1]);
          date = moment().tz(TIMEZONE__LA).year(year).week(week).startOf('week');
          startDate = date.toISOString();
          endDate = date.endOf('week').toISOString();
          break;

        default: // Monthly
          month = header.split('/')[0];
          year = 2000 + parseInt(header.split('/')[1]);
          date = moment.tz(TIMEZONE__LA).year(year).month(month).startOf('month');
          startDate = date.toISOString();
          endDate = date.endOf('month').toISOString();
          break;

      }
    }

    if (startDate && endDate) {
      where.and.push({ [dateAttr]: { between: [startDate, endDate] } });
    }

    if (stage.length > 0) {
      where.and.push({ "or": stage });
    }

    const YES__RECRUITER = recruiterId !== "0";
    const YES__EMPLOYER = employerId !== "0";
    const YES__JOB = jobId !== "0";

    const viewType = (
      (
        YES__RECRUITER
      ) ? (
        KEY__TABLE_COLLECTION__VIEW_TYPE__RECRUITER
      ) : (
        YES__JOB
      ) ? (
        KEY__TABLE_COLLECTION__VIEW_TYPE__JOB
      ) : (
        YES__EMPLOYER
      ) ? (
        KEY__TABLE_COLLECTION__VIEW_TYPE__EMPLOYER
      ) : (
        KEY__TABLE_COLLECTION__VIEW_TYPE__COMMON
      )
    );

    const title = (
      (
        YES__RECRUITER
      ) ? (
        `Recruiter engagements: ${getPersonName(rowData)}`
      ) : (
        YES__JOB
      ) ? (
        `Job engagements: ${getJobEntityName(rowData)}`
      ) : (
        YES__EMPLOYER
      ) ? (
        `Employer engagements: ${rowData.name}`
      ) : (
        "Engagements"
      )
    );

    console.debug(
      'handleCountClicked...',
      '\n', JSON.stringify({
        recruiterId,
        employerId,
        jobId,
        viewType,
        duration,
        header,
        startDate,
        endDate,
      }, null, 2)
    );

    this.setState({ open: true }, debounce(
      () => {
        const update = {
          where,
          filter: (engagements) => {
            if (recruiterId && (recruiterId !== "0")) {
              engagements = engagements.filter((engagement) => engagement.candidate?.accountId === recruiterId);
            }
            if (employerId && (employerId !== "0")) {
              engagements = engagements.filter((engagement) => engagement.job?.employerId === employerId);
            }
            if (jobId && (jobId !== "0")) {
              engagements = engagements.filter((engagement) => engagement.jobId === jobId);
            }
            return engagements;
          },
          viewType,
          title,
          ready: false,
        };
        this.TableCollection.updateState(update);
      }
    ));

  };

  handleClose = () => {
    this.setState({ open: false });
  };

  getData = () => {
    this.setState({ busyOverview: true }, async () => {
      try {
        let params = {
          recruiterId: this.state.accountId,
          employerId: this.state.employerId,
          duration: this.state.duration,
          viewBy: this.state.viewBy,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          selectedMetric: this.state.selectedMetric || "Count",
        };
        this.setRows(
          Arr(
            await Engagement.newPipelineReport(params)
          ).map(
            (row) => ({
              ...row,
              cols: row.cols
            })
          )
        );
      }
      catch (error) {
        globalErrorHandler(error);
      }
      this.setState({ busyOverview: false });
    });
  };
  setRows = async data => {
    /*
    [
      {
        pipeline: "New Submission",
          cols: [
            { group: "1/14-2/14", value: 40 },
            { group: "2/14-3/14", value: 24 },
            { group: "3/14-4/14", value: 34 },
            { group: "3/14-5/14", value: 45 }
          ]
      }, ...
    ]*/
    const rows = [];
    if (data[0]) {
      let myPaddingStyle = {
        paddingLeft: 0,
        paddingRight: 0,
        textAlign: 'center'
      }
      /** TABLE HEADERS */
      rows.push(
        <Table.Row
          key={Core.getKey()}
        >
          <Table.Cell
            className='sticky-start truncate'
            style={styleStartTableCell}
          />
          {data[0].cols.map((col, index) => (
            <Table.Cell
              key={Core.getKey()}
              style={myPaddingStyle}
              className={joinClassName([
                'fw-600 min-w-80 p-1 bg-cyan-common c-white',
                index === data[0].cols.length - 1 ? 'sticky-end' : ''
              ])}
            >
              {col.group}
            </Table.Cell>
          ))}
        </Table.Row>
      );

      /** TABLE BODY */
      data.forEach((item, rowIndex) => {
        rows.push(
          <Table.Row key={`reports__data__row__${rowIndex}`}>
            <Table.Cell
              className="fw-600 p-1 align-left sticky-start truncate"
              style={styleStartTableCell}
            >
              {item.pipeline}
            </Table.Cell>
            {item.cols.map((col, colIndex) => (
              <Table.Cell
                key={`reports__data__row__${rowIndex}__col__${colIndex}`}
                className={joinClassName([
                  'min-w-80 p-1 align-center',
                  colIndex === data[0].cols.length - 1 ? 'sticky-end fw-600' : ''
                ])}
              >
                <span
                  onClick={this.handleCountClicked({
                    col
                  })}
                  className={getColClassName(col)}
                >
                  {col.value}
                </span>
              </Table.Cell>

            ), this)}
          </Table.Row>
        );
      });
      this.setState({
        rows,
        data,
      }, () => {
        /** @todo clean up */
        /** * /
        document.getElementById('reports__data__table')?.scrollIntoView({
          behavior: "smooth",
          block: "end"
        });
        /** */
      });
    }
  };
  render() {
    if (Core.isLoggedOut()) {
      return <Navigate to="/login" />;
    }
    const _updateState = (update) => new Promise((resolve) => {
      this.setState((prevState) => {
        update = Object(update) === update ? update : {};
        return { ...prevState, ...update };
      }, () => resolve(update));
    });
    const _onChange = async (update) => {
      Engagement.cleanCacheNewPipelineReport();
      await _updateState(update);
      this.getData();
      await _updateState({ ready: false });
    }
    debounce(async () => {
      const recruitersData = Arr(this.state.recruiters).filter(
        (recruiter) => {
          if (this.state.selectedRecruiterFilter === 'Key recruiter') {
            return Arr(recruiter.interactions).find(
              (interaction) => (
                interaction === ID__TAG__ACCOUNT_INTERACTION__RECRUITER_KEY
              )
            )
          }
          return YES(recruiter.starred);
        }
      );
      const employersData = Arr(this.state.employers).filter(
        (employer) => {
          if (this.state.selectedEmployersFilter === 'Starred Employer') {
            return YES(employer.starred);
          }
          // TODO ---Add any additional filters for other selectedEmployersFilter options here
          return Arr([employer.state]).find(state => state === STATE_ACTIVE);
        }
      );
      const jobsData = Arr(this.state.jobs).filter(
        ({ employerId, starred }) => {
          if (this.state.employerId && this.state.employerId !== '0') {
            return employerId === this.state.employerId;
          }
          return YES(starred);
        }
      );
      if (
        NOT(this.state.busy || this.state.error || this.state.ready) &&
        (recruitersData.length || employersData.length || jobsData.length)
      ) {
        await _updateState({ busy: true });
        try {
          await _updateState({ recruitersData, employersData, jobsData });
          await _updateState({
            busyRecruiters: true,
            busyEmployers: true,
            busyJobs: true
          });
          for (const [, recruiterData] of recruitersData.entries()) {
            let params = {
              recruiterId: recruiterData.id,
              employerId: '0',
              duration: this.state.duration,
              viewBy: this.state.viewBy,
              startDate: this.state.startDate,
              endDate: this.state.endDate,
              selectedReportPipeline: this.state.selectedRecruitersPipeline
            };
            Object.assign(
              recruiterData,
              { rows: Arr(await Engagement.newPipelineReport(params)) }
            );
            await _updateState({ recruitersData });
          }
          await _updateState({ busyRecruiters: false });
          for (const [, employerData] of employersData.entries()) {
            let params = {
              recruiterId: '0',
              employerId: employerData.id,
              duration: this.state.duration,
              viewBy: this.state.viewBy,
              startDate: this.state.startDate,
              endDate: this.state.endDate,
              selectedMetric: this.state.selectedEmployerMetric,
              selectedReportPipeline: this.state.selectedEmployersPipeline
            };
            Object.assign(
              employerData,
              { rows: Arr(await Engagement.newPipelineReport(params)) }
            );
            await _updateState({ employersData });
          }
          await _updateState({ busyEmployers: false });
          for (const [, jobData] of jobsData.entries()) {
            let params = {
              recruiterId: '0',
              employerId: jobData.employerId,
              jobId: jobData.id,
              duration: this.state.duration,
              viewBy: this.state.viewBy,
              startDate: this.state.startDate,
              endDate: this.state.endDate,
              selectedReportPipeline: this.state.selectedJobsPipeline,
              selectedMetric: this.state.selectedJobMetric
            };
            Object.assign(
              jobData,
              { rows: Arr(await Engagement.newPipelineReport(params)) }
            );
            await _updateState({ jobsData });
          }
          await _updateState({ busyJobs: false, ready: true });
        }
        catch (error) {
          Core.showError(error);
          await _updateState({ error });
        }
        await _updateState({ busy: false });
      }
    })();
    console.debug('Reports...', this.state);
    return (
      <Box column>

        {/** PIPELINE OVERVIEW */}
        <Box column className='p-2'>
          <Typography bold className='f-xxxl m-1'>
            Pipeline Overview
            {this.state.busyOverview && <CircularProgress size={16} thickness={4} className='ml-1' />}
          </Typography>
          <Paper>
            <Box column w100 scrollX>
              <Table className="collapsed-view">
                <Table.Body>
                  <Table.Row className="v-align-top">

                    <Table.Cell className='flex-1' style={{ minWidth: 240 }}>
                      <label>Recruiter</label>
                      <Menu dropdown avoidNullOption
                        name="recruiter"
                        value={this.state.accountId || '0'}
                        onChange={(accountId) => _onChange({
                          accountId
                        })}
                        paperStyle={{ maxHeight: 640 }}
                        options={
                          [
                            { id: "0", name: "All" },
                            ...Arr(this.state.recruiters).sort((a, b) => {
                              if (a.id === "0") {
                                return -1;
                              }
                              a = String(a.firstName || a.email).toLowerCase();
                              b = String(b.firstName || b.email).toLowerCase();
                              return a > b ? 1 : a < b ? -1 : 0;
                            })
                          ]
                        }
                      />
                    </Table.Cell>

                    <Table.Cell
                      className='flex-1'
                      style={{ minWidth: 240 }}
                    >
                      <label>Employer</label>
                      <Menu dropdown avoidNullOption
                        name="employer"
                        value={this.state.employerId}
                        onChange={(employerId) => _onChange({
                          employerId
                        })}
                        paperStyle={{ maxHeight: 640 }}
                        options={
                          [
                            { id: "0", name: "All" },
                            ...Arr(this.state.employers).sort((a, b) => {
                              if (a.id === "0") {
                                return -1;
                              }
                              a = String(a.name).toLowerCase();
                              b = String(b.name).toLowerCase();
                              return a > b ? 1 : a < b ? -1 : 0;
                            })
                          ]
                        }
                      />
                    </Table.Cell>

                    <Table.Cell
                      className='flex-1 nowrap'
                    >
                      <label>Job</label>
                      <Menu dropdown avoidNullOption
                        value={this.state.jobId}
                        onChange={(jobId) => _onChange({
                          jobId
                        })}
                        paperStyle={{ maxHeight: 640 }}
                        options={
                          [
                            { id: "0", name: "All" },
                            ...Arr(this.state.jobs).map(
                              job => ({
                                ...job,
                                id: job.id,
                                label: (
                                  job.employer
                                    ? `${job.employer.name} –  ${job.jobTitle}`
                                    : job.name
                                )
                              })
                            ).sort(
                              ({ label: a }, { label: b }) => a.localeCompare(b)
                            )
                          ]
                        }
                      />
                    </Table.Cell>

                    <Table.Cell className='flex-1'>
                      <label className='nowrap'>
                        View By
                        <Icon
                          icon='info'
                          className='icon16 c-gray ml-1'
                          title="A candidate can have multiple engagements (applied to multiple jobs). Active means the candidate's state is open (not rejected or hired)."
                          placement={PLACEMENT__TOP_START}
                        />
                      </label>
                      <Menu dropdown avoidNullOption
                        name="viewBy"
                        value={this.state.viewBy}
                        onChange={(viewBy) => _onChange({
                          viewBy
                        })}
                        paperStyle={{ maxHeight: 640 }}
                        options={["Engagements", "Candidates", "Active Engagements", "Active Candidates"]}
                      />
                    </Table.Cell>

                    <Table.Cell className='flex-1'>
                      <label>Duration</label>
                      <Menu dropdown avoidNullOption
                        name="duration"
                        value={this.state.duration}
                        onChange={(duration) => _onChange(
                          getDurationDates({
                            duration,
                            endDate: this.state.endDate,
                            periodLength: this.state.periodLength
                          })
                        )}
                        options={["Daily", "Weekly", "Monthly"]}
                      />
                    </Table.Cell>

                    <Table.Cell className='flex-1'>
                      <label className='nowrap'>
                        Period Length
                        <Icon
                          icon='info'
                          className='icon16 c-gray ml-1'
                          title='Number of periods to display in report (days/weeks/months based on Duration)'
                          placement={PLACEMENT__TOP_START}
                        />
                      </label>
                      <Menu dropdown avoidNullOption
                        name="periodLength"
                        value={String(this.state.periodLength)}
                        onChange={(periodLength) => _onChange(
                          getDurationDates({
                            duration: this.state.duration,
                            endDate: this.state.endDate,
                            periodLength
                          })
                        )}
                        options={VALUES__REPORTS__PERIOD_LENGTH}
                      />
                    </Table.Cell>

                    <Table.Cell className='flex-1'>
                      <label>
                        End Date
                      </label>
                      <DatePicker
                        name="endDate"
                        value={
                          this.state.endDate ? new Date(this.state.endDate) : null
                        }
                        onChange={(endDate) => _onChange(
                          getDurationDates({
                            duration: this.state.duration,
                            endDate: endDate.toISOString(),
                            periodLength: this.state.periodLength
                          })
                        )}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Box>
            <Divider />
            <Box row w100 scrollX className='flex-align-left-top'>
              <Table styled className="collapsed-view">
                <Table.Body
                  ref={self => (this.rowsContainer = self)}
                >
                  {this.state.rows}
                </Table.Body>
              </Table>
              <RefBox id='reports__data__table' />
            </Box>
          </Paper>
        </Box>

        {/** TOP RECRUITERS */}
        <Box column className='p-2'>
          <Box row className='space-between'>
            <Typography bold className='f-xxxl m-1'>
              Top Recruiters
              {this.state.busyRecruiters && <CircularProgress size={16} thickness={4} className='ml-1' />}
            </Typography>
            <Box row>
              <Menu dropdown avoidNullOption className="mr-1"
                style={{ maxWidth: 240 }}
                value={this.state.selectedRecruiterFilter}
                options={["Key recruiter", "Starred"]}
                onChange={(selectedRecruiterFilter) => _onChange({
                  selectedRecruiterFilter
                })}
              />
              <Menu dropdown avoidNullOption
                style={{ maxWidth: 240 }}
                value={this.state.selectedRecruitersPipeline}
                options={Arr(this.state.data).map((row) => (row.pipeline))}
                onChange={(selectedRecruitersPipeline) => _onChange({
                  selectedRecruitersPipeline
                })}
              />
            </Box>
          </Box>
          <Paper>
            <Box column w100 scrollX>
              <Table styled className="collapsed-view">
                <Table.Body
                  ref={self => (this.rowsContainer = self)}
                >
                  {(
                    () => {
                      const data = Arr(this.state.recruitersData).map(
                        (recruiterData, index) => (
                          {
                            URL__RECRUITER__EDIT: `/account/edit/${recruiterData.id}`,
                            RECRUITER__NAME: getPersonName(recruiterData),
                            RECRUITER__ID: recruiterData.id,
                            ...Obj(
                              Arr(
                                recruiterData.rows
                              ).find(
                                (row) => (row.pipeline === this.state.selectedRecruitersPipeline)
                              )
                            ),
                            rowData: recruiterData
                          }
                        )
                      );
                      const rows = [];
                      if (data[0]) {
                        let myPaddingStyle = {
                          paddingLeft: 0,
                          paddingRight: 0,
                          textAlign: 'center'
                        }
                        /** TABLE HEADERS */
                        rows.push(
                          <Table.Row
                            key={Core.getKey()}
                          >
                            <Table.Cell
                              className='sticky-start'
                              style={{ minWidth: 240, width: 240, maxWidth: 240 }}
                            />
                            {Arr(Arr(this.state.data)[0]?.cols).map((col, index) => (
                              <Table.Cell
                                key={Core.getKey()}
                                style={myPaddingStyle}
                                className={joinClassName([
                                  'fw-600 min-w-80 p-1 bg-cyan-common c-white',
                                  index === Arr(Arr(this.state.data)[0]?.cols).length - 1 ? 'sticky-end' : ''
                                ])}
                              >
                                {col.group}
                              </Table.Cell>
                            ))}
                          </Table.Row>
                        );

                        /** TABLE BODY */
                        data.forEach((item, rowIndex) => {
                          rows.push(
                            <Table.Row key={`recruiters__reports__data__row__${rowIndex}`}>
                              <Table.Cell
                                className="fw-600 p-1 align-left sticky-start truncate"
                                style={styleStartTableCell}
                              >
                                <Box row>
                                  <Checkbox
                                    checked={this.state.accountId === item.RECRUITER__ID}
                                    onChange={(event, checked) => _onChange({
                                      accountId: checked ? item.RECRUITER__ID : '0'
                                    })}
                                  />
                                  <Button flat primary minW120
                                    onClick={(event) => Core.openPopUp(item.URL__RECRUITER__EDIT, 1600)}
                                    disabled={NOT(Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_ACCOUNT }))}
                                    className='align-left'
                                  >
                                    {item.RECRUITER__NAME}
                                  </Button>
                                </Box>
                              </Table.Cell>
                              {Arr(Arr(this.state.data)[0]?.cols).map((col, colIndex) => {
                                col = Obj(Arr(item.cols).find(
                                  (colItem) => (colItem.group === col.group)
                                ));
                                return (
                                  <Table.Cell
                                    key={`recruiters__reports__data__row__${rowIndex}__col__${colIndex}`}
                                    className={joinClassName([
                                      'min-w-80 p-1 align-center',
                                      colIndex === Arr(Arr(this.state.data)[0]?.cols).length - 1 ? 'sticky-end fw-600' : ''
                                    ])}
                                  >
                                    <span
                                      onClick={this.handleCountClicked({
                                        col,
                                        recruiterId: item.RECRUITER__ID,
                                        employerId: '0',
                                        jobId: '0',
                                        rowData: item.rowData,
                                      })}
                                      className={getColClassName(col)}
                                    >
                                      {col.value || 0}
                                    </span>
                                  </Table.Cell>

                                );
                              }, this)}
                            </Table.Row>
                          );
                        });
                        return rows
                      }
                    }
                  )()}
                </Table.Body>
              </Table>
            </Box>
          </Paper>
        </Box>

        {/** TOP EMPLOYERS */}
        <Box column className='p-2'>
          <Box row className='space-between'>
            <Typography bold className='f-xxxl m-1'>
              Top Employers
              {this.state.busyEmployers && <CircularProgress size={16} thickness={4} className='ml-1' />}
            </Typography>
            <Box row style={{ justifyContent: 'flex-end' }}>
              <Menu dropdown avoidNullOption
                style={{ maxWidth: 240 }}
                value={this.state.selectedEmployersFilter}
                options={["Starred Employer", "Active Employer"]}
                onChange={(selectedEmployersFilter) => _onChange({
                  selectedEmployersFilter
                })}
              />
              <Menu dropdown avoidNullOption
                style={{ maxWidth: 240 }}
                value={this.state.selectedEmployerMetric}
                options={["Count", "Response Time"]}
                onChange={(selectedEmployerMetric) => _onChange({
                  selectedEmployerMetric
                })}
              />
              <Menu dropdown avoidNullOption
                style={{ maxWidth: 240 }}
                value={this.state.selectedEmployersPipeline}
                options={Arr(this.state.data).slice(1).map((row) => (row.pipeline))}
                onChange={(selectedEmployersPipeline) => _onChange({
                  selectedEmployersPipeline
                })}
              />
            </Box>
          </Box>
          <Paper>
            <Box column w100 scrollX>
              <Table styled className="collapsed-view">
                <Table.Body
                  ref={self => (this.rowsContainer = self)}
                >
                  {(
                    () => {
                      const data = Arr(this.state.employersData).map(
                        (employerData, index) => (
                          {
                            URL__EMPLOYER__EDIT: `/employer/edit/${employerData.id}`,
                            EMPLOYER__NAME: employerData.name,
                            EMPLOYER__ID: employerData.id,
                            ...Obj(
                              Arr(
                                employerData.rows
                              ).find(
                                (row) => (row.pipeline === this.state.selectedEmployersPipeline)
                              )
                            ),
                            rowData: employerData
                          }
                        )
                      );
                      const rows = [];
                      if (data[0]) {
                        let myPaddingStyle = {
                          paddingLeft: 0,
                          paddingRight: 0,
                          textAlign: 'center'
                        }
                        /** TABLE HEADERS */
                        rows.push(
                          <Table.Row
                            key={Core.getKey()}
                          >
                            <Table.Cell
                              className='sticky-start'
                              style={{ minWidth: 240, width: 240, maxWidth: 240 }}
                            />
                            {Arr(Arr(this.state.data)[0]?.cols).map((col, index) => (
                              <Table.Cell
                                key={Core.getKey()}
                                style={myPaddingStyle}
                                className={joinClassName([
                                  'fw-600 min-w-80 p-1 bg-cyan-common c-white',
                                  index === Arr(Arr(this.state.data)[0]?.cols).length - 1 ? 'sticky-end' : ''
                                ])}
                              >
                                {col.group}
                              </Table.Cell>
                            ))}
                          </Table.Row>
                        );

                        /** TABLE BODY */
                        data.forEach((item, rowIndex) => {
                          rows.push(
                            <Table.Row key={`employers__reports__data__row__${rowIndex}`}>
                              <Table.Cell
                                className="fw-600 p-1 align-left sticky-start truncate"
                                style={styleStartTableCell}
                              >
                                <Box row>
                                  <Checkbox
                                    checked={this.state.employerId === item.EMPLOYER__ID}
                                    onChange={(event, checked) => _onChange({
                                      employerId: checked ? item.EMPLOYER__ID : '0'
                                    })}
                                  />
                                  <Button flat primary minW120
                                    onClick={(event) => Core.openPopUp(item.URL__EMPLOYER__EDIT, 1600)}
                                    disabled={NOT(Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_EMPLOYER }))}
                                    className='align-left'
                                  >
                                    {item.EMPLOYER__NAME}
                                  </Button>
                                </Box>
                              </Table.Cell>
                              {Arr(Arr(this.state.data)[0]?.cols).map((col, colIndex) => {
                                col = Obj(Arr(item.cols).find(
                                  (colItem) => (colItem.group === col.group)
                                ));
                                return (
                                  <Table.Cell
                                    key={`employers__reports__data__row__${rowIndex}__col__${colIndex}`}
                                    className={joinClassName([
                                      'min-w-80 p-1 align-center',
                                      colIndex === Arr(Arr(this.state.data)[0]?.cols).length - 1 ? 'sticky-end fw-600' : ''
                                    ])}
                                  >
                                    <span
                                      onClick={this.handleCountClicked({
                                        col,
                                        recruiterId: '0',
                                        employerId: item.EMPLOYER__ID,
                                        jobId: '0',
                                        rowData: item.rowData,
                                      })}
                                      className={getColClassName(col)}
                                    >
                                      {col.value || 0}
                                    </span>
                                  </Table.Cell>

                                );
                              }, this)}
                            </Table.Row>
                          );
                        });
                        return rows
                      }
                    }
                  )()}
                </Table.Body>
              </Table>
            </Box>
          </Paper>
        </Box>

        {/** TOP JOBS */}
        <Box column className='p-2'>
          <Box row className='space-between'>
            <Typography bold className='f-xxxl m-1'>
              Top Jobs
              {this.state.busyJobs && <CircularProgress size={16} thickness={4} className='ml-1' />}
            </Typography>
            <Box row>
              <Menu dropdown avoidNullOption
                className="mr-1"
                style={{ maxWidth: 240 }}
                value={this.state.selectedJobMetric}
                options={["Count", "Response Time"]}
                onChange={(selectedJobMetric) => _onChange({
                  selectedJobMetric
                })}
              />
              <Menu dropdown avoidNullOption
                style={{ maxWidth: 240 }}
                value={this.state.selectedJobsPipeline}
                options={Arr(this.state.data).slice(1).map((row) => (row.pipeline))}
                onChange={(selectedJobsPipeline) => _onChange({
                  selectedJobsPipeline
                })}
              />
            </Box>
          </Box>
          <Paper>
            <Box column w100 scrollX>
              <Table styled className="collapsed-view">
                <Table.Body
                  ref={self => (this.rowsContainer = self)}
                >
                  {(
                    () => {
                      const data = Arr(this.state.jobsData).map(
                        (jobData, index) => (
                          {
                            ID__JOB: jobData.id,
                            ID__EMPLOYER: jobData.employerId,
                            NAME__JOB: jobData.jobTitle,
                            NAME__EMPLOYER: jobData.employer?.name,
                            URL__JOB__VIEW: `/job/view/${jobData.id}`,
                            URL__EMPLOYER__EDIT: `/employer/edit/${jobData.employerId}`,
                            ...Obj(
                              Arr(
                                jobData.rows
                              ).find(
                                (row) => (row.pipeline === this.state.selectedJobsPipeline)
                              )
                            ),
                            rowData: jobData
                          }
                        )
                      );
                      const rows = [];
                      if (data[0]) {
                        let myPaddingStyle = {
                          paddingLeft: 0,
                          paddingRight: 0,
                          textAlign: 'center'
                        }
                        /** TABLE HEADERS */
                        rows.push(
                          <Table.Row
                            key={Core.getKey()}
                          >
                            <Table.Cell
                              className='sticky-start'
                              style={{ minWidth: 240, width: 240, maxWidth: 240 }}
                            />
                            {Arr(Arr(this.state.data)[0]?.cols).map((col, index) => (
                              <Table.Cell
                                key={Core.getKey()}
                                style={myPaddingStyle}
                                className={joinClassName([
                                  'fw-600 min-w-80 p-1 bg-cyan-common c-white',
                                  index === Arr(Arr(this.state.data)[0]?.cols).length - 1 ? 'sticky-end' : ''
                                ])}
                              >
                                {col.group}
                              </Table.Cell>
                            ))}
                          </Table.Row>
                        );

                        /** TABLE BODY */
                        data.forEach((item, rowIndex) => {
                          rows.push(
                            <Table.Row key={`employers__reports__data__row__${rowIndex}`}

                            >
                              <Table.Cell
                                className="fw-600 p-1 align-left sticky-start truncate border-bottom"
                                style={styleStartTableCell}
                              >
                                <Box row>
                                  <Checkbox
                                    checked={this.state.jobId === item.ID__JOB}
                                    onChange={(event, checked) => _onChange({
                                      jobId: checked ? item.ID__JOB : '0'
                                    })}
                                  />
                                  <Box column>
                                    <Button flat primary minW120
                                      onClick={(event) => Core.openPopUp(item.URL__JOB__VIEW, 1600)}
                                      disabled={NOT(Core.isAdmin({ action: ACCOUNT_ACTION__LIST_JOBS }))}
                                      className='align-left py-0'
                                    >
                                      {item.NAME__JOB}
                                    </Button>
                                    <Button flat primary minW120 small
                                      onClick={(event) => Core.openPopUp(item.URL__EMPLOYER__EDIT, 1600)}
                                      disabled={NOT(Core.isAdmin({
                                        action: ACCOUNT_ACTION__EDIT_EMPLOYER
                                      }))}
                                      className='align-left fw-400 f-sm py-0'
                                    >
                                      {item.NAME__EMPLOYER}
                                    </Button>
                                  </Box>
                                </Box>
                              </Table.Cell>
                              {Arr(Arr(this.state.data)[0]?.cols).map((col, colIndex) => {
                                col = Obj(Arr(item.cols).find(
                                  (colItem) => (colItem.group === col.group)
                                ));
                                return (
                                  <Table.Cell
                                    key={`jobs__reports__data__row__${rowIndex}__col__${colIndex}`}
                                    className={joinClassName([
                                      'min-w-80 p-1 align-center border-bottom',
                                      colIndex === Arr(Arr(this.state.data)[0]?.cols).length - 1 ? 'sticky-end fw-600' : ''
                                    ])}
                                  >
                                    <span
                                      onClick={this.handleCountClicked({
                                        col,
                                        recruiterId: '0',
                                        employerId: item.ID__EMPLOYER,
                                        jobId: item.ID__JOB,
                                        rowData: item.rowData,
                                      })}
                                      className={getColClassName(col)}
                                    >
                                      {col.value || 0}
                                    </span>
                                  </Table.Cell>

                                );
                              }, this)}
                            </Table.Row>
                          );
                        });
                        return rows
                      }
                    }
                  )()}
                </Table.Body>
              </Table>
            </Box>
          </Paper>
        </Box>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          children={
            <TableCollection
              title='Engagements'
              actionBarBottomRight={
                <Button
                  onClick={this.handleClose}
                  className='min-w-120'
                  primary
                >
                  Close
                </Button>
              }
              context={this}
            />
          }
          paperStyle={{ width: 1600 }}
        />

      </Box>
    );
  }
}

export function getDurationDates({
  duration = 'Daily',
  endDate = moment().tz(TIMEZONE__LA).endOf('day').toISOString(),
  periodLength = VALUE__REPORTS__PERIOD_LENGTH
} = {}) {

  const today = moment().tz(TIMEZONE__LA).endOf('day');
  const date = moment.tz(endDate, TIMEZONE__LA).endOf('day');
  endDate = date.isAfter(today) ? today.toISOString() : date.toISOString();

  const update = {
    duration,
    endDate,
    startDate: null,
    // periodLength must be saved as string on the state
    // because it is used as a key for the Menu dropdown
    periodLength: Str(periodLength)
  };

  if (/Weekly/i.test(duration)) {
    update.startDate = moment(endDate)
      .tz(TIMEZONE__LA)
      .subtract(periodLength - 1, "weeks")
      .startOf("week")
      .toISOString();
  }
  else if (/Daily/i.test(duration)) {
    update.startDate = moment(endDate)
      .tz(TIMEZONE__LA)
      .subtract(periodLength - 1, "days")
      .startOf("day")
      .toISOString();
  }
  else {
    update.startDate = moment(endDate)
      .tz(TIMEZONE__LA)
      .subtract(periodLength - 1, "months")
      .startOf("month")
      .toISOString();
  }

  console.debug('getDurationDates', { duration, endDate, periodLength, update });

  return update;
}
