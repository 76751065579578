import { MDASH } from '../../lib/Constants';
import useState from '../../lib/hooks/useState.hook';
import { getPersonName } from '../../lib/Object.lib';
import Fieldset from '../Layout/Wrappers/Fieldset';
import Menu from '../Layout/Wrappers/Menu';

export default function FieldsetAccountEmployer({
  options = [],
  context = {}
}) {
  const [{ id = null }, updateState] = useState({ id: context.id });
  const _renderLabel = ({ id, account = {} }) => (
    <>
      {getPersonName(account)} {MDASH} {account.email}
    </>
  );
  return (
    <Fieldset
      title='Select an account'
    >
      <Menu dropdown avoidNullOption
        value={id}
        options={options}
        onChange={
          async (id, selected) => {
            await updateState({ id });
            Object.assign(context, selected);
          }
        }
        optionLabelMapper={_renderLabel}
        renderOption={({ option }) => _renderLabel(option)}
      />
    </Fieldset>
  )
}
