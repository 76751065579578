import {
  useRef
} from 'react';
import {
  Arr
} from '../../lib/Array.lib';
import {
  NOT
} from '../../lib/Boolean.lib';
import Engagement, {
  getEngagementUrl
} from '../../lib/Engagement';
import globalErrorHandler from '../../lib/Error/globalErrorHandler.fun';
import {
  Fun
} from '../../lib/Function.lib';
import useState from '../../lib/hooks/useState.hook';
import {
  COLLECTION__ENGAGEMENTS,
  readLoopbackRecord
} from '../../lib/services/BE/loopback.api';
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import Fieldset from '../Layout/Wrappers/Fieldset';
import Icon from '../Layout/Wrappers/Icon';
import Link from '../Layout/Wrappers/Link';
import {
  PLACEMENT__TOP_START
} from '../Layout/Wrappers/StyledTooltip';
import TextField from '../Layout/Wrappers/TextField';
import {
  AdminToolsController
} from './Tools';


/**
 * ToolSyncStreakWithListOfEngagement is a UI component for syncing a list of engagement ids to their respective Streak boxes.
 * 
 * @param {Object} props - Component props
 * @returns {ReactElement} a JSX element representing the component
 * 
 * The component is rendered by the AdminToolsController and is only accessible to admin users.
 * 
 * It displays a text field where the user can type a list of engagement ids, one per line.
 * Once the user types a new list of engagement ids, the component fetches the corresponding engagements from the BE and displays a synchronization button.
 * When the user clicks the synchronization button, the component triggers the update of each engagement in the BE, which in turn triggers the Streak box creation by the BE worker.
 * 
 * The component also displays a 'Clean results' button that resets the component state and clears the results.
 * 
 * The component is disabled until the user types a new list of engagement ids.
 * The component is also disabled while the component is busy synchronizing the engagements.
 * 
 * The component displays a help icon next to the synchronization button with a tooltip explaining that the user must type a new list of engagement ids first.
 * 
 * The component displays a success message when the synchronization is finished, and an error message if there is an error during the synchronization.
 */
export default function ToolSyncStreakWithListOfEngagement(props) {
  const [{
    engagements = [],
    textEngagementsList = '',
  }, updateState] = useState();
  const mem = useRef({});
  const {
    busy = false,
    ready = false,
    results = false,
  } = mem.current;


  /**
   * Resets the component state to its initial state, clearing the results.
   * 
   * This function is called when the user clicks the 'Clean results' button.
   * 
   * @param {Event} event - The event object passed to the function.
   * 
   * The function sets the `results` state to false, and resets the component state to its initial state.
   * The function also calls the `cleanResults` function of the `AdminToolsController` to clear the results.
   */
  const _cleanResults = async (event) => {
    mem.current.results = false;
    await updateState({
      textEngagementsList: '',
      engagements: [],
    });
    Fun(AdminToolsController().cleanResults)();
  }


  /**
   * Updates the results of the AdminToolsController with the given value.
   * @param {string} value - The new value of the results.
   * @returns {string} The new value of the results.
   */
  const _setResults = (value) => {
    return Fun(AdminToolsController().setResults)(value);
  }


  /**
   * Fetches the engagements given the list of engagement ids, and updates the component state with the results.
   * 
   * @param {Array<string>} engagementsIds - The list of engagement ids to fetch.
   * 
   * The function is only called when the user types a new list of engagement ids.
   * The function disables the component until the fetch is finished.
   * The function sets the `ready` state to true if the fetch is successful.
   * The function sets the `results` state to true if the fetch is successful.
   * The function displays a success message with the number of engagements fetched.
   * The function displays an error message if there is an error during the fetch.
   * 
   */
  const _fetchEngagements = async (engagementsIds) => {
    mem.current.busy = true;
    try {
      mem.current.ready = false;
      if (
        engagementsIds.length
      ) {
        await updateState({
          engagements: await readLoopbackRecord({
            collection: COLLECTION__ENGAGEMENTS,
            where: {
              id: {
                inq: engagementsIds
              }
            },
            fields: ['id']
          }).then((results = []) => {
            _setResults(
              `Engagements: ${results.length}`
            );
            return Arr(results);
          })
        });
        mem.current.results = true;
        mem.current.ready = true;
      }
      else {
      }
    }
    catch (error) {
      globalErrorHandler(error);
    }
    mem.current.busy = false;
    return updateState();
  }


  /**
   * onChange handler for the input field where the user types the engagement ids
   * @param {object} event - The onChange event
   * @returns {Promise<void>}
   */
  const _onChange = async (event) => {
    const textEngagementsList = event.target.value;
    const engagementsIds = textEngagementsList
      .replace(/"/g, '')
      .split('\n')
      .map((line) => line.split('/').pop().trim())
      .filter((engagementId) => engagementId.length);
    await updateState({ engagementsIds });
    await _fetchEngagements(engagementsIds);
  }


  /**
   * Set lastAction to the current date on all given engagements
   * This is used to sync the engagement with Streak
   * @param {Array<Engagement>} engagements - The engagements to sync
   * @returns {Promise<void>}
   */
  const _queueEngagementUpdates = async () => {
    mem.current.ready = false;
    for (const engagement of engagements) {
      try {
        await Engagement.update(engagement, { lastAction: Date.now() });
        _setResults(
          <>
            ✅ Engagement <Link url={getEngagementUrl({ engagement })}>${engagement.id}</Link> sync queued
          </>
        );
      }
      catch (error) {
        _setResults(
          <>
            ❌ Engagement <Link url={getEngagementUrl({ engagement })}>${engagement.id}</Link> update error: {error}
          </>
        );
      }
    }
    mem.current.results = true;
    _setResults(`${engagements.length} engagements processed`);
  }

  // RENDER
  return (
    <Fieldset
      title="Sync list of engagements to streak boxes"
      subtitle='Type a list of engagements, one per line'
    >
      <TextField multiLine disabledBlurExport
        maxRows={12}
        value={textEngagementsList}
        onChange={_onChange}
      />
      <Box alignRight className='mt-1'>
        <Button outlined mr1 minW120
          acl={!!results}
          onClick={_cleanResults}
        >
          Clean results
        </Button>
        <Button primary disabled={NOT(ready) || busy}
          className='w-50'
          onClick={_queueEngagementUpdates}
        >
          Synchronize
          <Icon mr
            acl={NOT(ready)}
            title='Type a new list of engagements first'
            placement={PLACEMENT__TOP_START}
            icon='help_outlined'
            style={{ position: 'absolute', right: 5 }}
          />
        </Button>
      </Box>
    </Fieldset>
  );
}
