import {
  Component
} from "react";
import Definition from "../../../lib/Definition";
import {
  getCategoryKeys
} from "../../../lib/tools/getSpecificCategoryKey";
import Chips from "../../Forms/ChipsNew";
import Card from '../../Layout/Wrappers/Card';
import Divider from '../../Layout/Wrappers/Divider';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import Typography from '../../Layout/Wrappers/Typography';
import {
  evaluatePositiveSignal
} from '../Haves/v2/PositiveSignals.lib';

class CandidateSkills extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      otherSkills: this.props.data,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        otherSkills: this.props.data,
      });
    }
  }

  skillsHandler = (skills, type) => {
    const { skillsHandler } = this.props;
    let otherSkills = this.state.otherSkills;
    let changed = otherSkills.find((el) => el.type === type);
    changed.chips = skills;
    let changedIndex = otherSkills.findIndex((el) => el.type === type);
    otherSkills[changedIndex] = changed;

    this.setState({
      otherSkills,
    });
    !!skillsHandler && skillsHandler(skills, type);
  };

  renderList = (data) => {
    const { jobArgs, source, candidate, githubStatsData } = this.props;
    if (!Array.isArray(data)) {
      data = [];
    }
    let typeArray = [];
    let decisionArray = [];
    return data.map((obj, index) => {
      typeArray =
        obj.type !== "technologyDomain"
          ? getCategoryKeys({ key: obj.type }, jobArgs)
          : null;
      decisionArray = !!typeArray
        ? obj.chips.map((chip) => {
          const isMatched = evaluatePositiveSignal({
            jobSignalTagId: parseInt(chip),
            candidateSignalTagIds: typeArray,
            priority: !!'renderCandidatePipe'
          });
          return !!isMatched
            ? isMatched
            : source && source === "candidateMatch"
              ? null
              : isMatched;
        })
        : null;

      return (
        <Fieldset
          key={index}
          title={
            <>
              {obj.label}
            </>
          }
          subtitle={
            <>
              {
                (obj.type === "positiveSignals") &&
                !!(candidate.posSignalsInResume || []).length && (
                  <>
                    <small className='tt-none tt-unset'>
                      - See additional suggestions found in resume
                    </small>
                    <ul>
                      {candidate.posSignalsInResume.map((obj, signalIndex) => {
                        return (
                          <li key={`candidates__match_new__candidate_pipe__candidate_skills__render_list__${signalIndex}`}>
                            <small className='tt-none tt-unset'>
                              {Definition.getLabels("positiveSignals", obj.tags)} => {" "}
                              <Typography sub className='c-black-medium'>
                                {obj.ref}
                              </Typography>
                            </small>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                )
              }
            </>
          }
        >

          <div>
            <Chips
              name={obj.type}
              values={obj.chips}
              decisionArray={decisionArray}
              onChange={(chips) => {
                this.skillsHandler(chips, obj.type);
              }}
            />
          </div>

          {obj.type === "positiveSignals" && !!githubStatsData && githubStatsData}

        </Fieldset>
      );
    });
  };

  render() {
    const { otherSkills } = this.state;

    return (
      <Card
        role='PublicNotesCard'
        className='mb-05'
      >
        <Typography strong large alignCenter blackDark
          className='m-1'
        >
          OTHERS CANDIDATE SKILLS
        </Typography>
        <Divider />

        {this.renderList(otherSkills)}

      </Card>
    );
  }
}

export default CandidateSkills;
