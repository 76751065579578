import {
  uniqueId
} from 'lodash';
import {
  useEffect
} from 'react';
import EmployerPendingsUI from '../../../dictionaries/EmployerPendingsUI.dic';
import {
  ENGAGEMENT__ACTIVE_REJECTION_REASONS,
  ENGAGEMENT__ACTIVE_STAGES,
  ENGAGEMENT__ACTIVE_STATUSES,
  ENGAGEMENT__STAGE_STATUSES,
  ENGAGEMENT__STATES,
  STAGE_GUARANTEE,
  STAGE_HIRE,
  STAGE_OFFER,
  STAGE_ONSITE,
  STAGE_REVIEW,
  STAGE_SCREEN,
  STAGE_SUBMISSION
} from '../../../dictionaries/Engagement.dic';
import {
  Arr,
  join
} from '../../../lib/Array.lib';
import {
  NOT
} from '../../../lib/Boolean.lib';
import Candidate from '../../../lib/Candidate';
import Core from '../../../lib/Core';
import Definition, {
  ACCOUNT_ACTION__EMPLOYER_PENDINGS,
  ACCOUNT_ACTION__MATCH_CANDIDATE,
  ACCOUNT_ACTION__RESUME_SUBMISSION,
  ACCOUNT_ACTION__VIEW_ENGAGEMENT,
  MLM__HARDCODED_ID
} from '../../../lib/Definition';
import controlFlowCAVE from '../../../lib/Email/CAVE/controlFlowCAVE.fun';
import Engagement from '../../../lib/Engagement';
import globalErrorHandler from '../../../lib/Error/globalErrorHandler.fun';
import {
  Fun
} from '../../../lib/Function.lib';
import Job from '../../../lib/Job';
import {
  mapEngagement
} from '../../../lib/models/engagement';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  openMessageEmailPreview
} from '../../../lib/services/Email/Email.lib';
import {
  openEmployerTodoReminderEmailPreview,
  openJobTodoReminderEmailPreview
} from '../../../lib/services/Email/EmailEmployer.lib';
import {
  openRecruiterReminderDialog
} from '../../../lib/services/Email/EmailRecruiter.lib';
import Streak from '../../../lib/Streak';
import copyHtml from '../../../lib/tools/copyHtml';
import downloadFile from '../../../lib/tools/downloadFile';
import {
  matchLocation,
  reloadLocation
} from '../../../lib/URL.lib';
import openDialogAccountEmployerSelector from '../../Accounts/Libraries/openDialogAccountEmployerSelector.fun';
import ActionComponentCandidateResumePdf from '../../Candidates/ResumePdf/ActionComponentCandidateResumePdf';
import {
  showAlert
} from '../../Dialogs/AlertDialog';
import {
  showConfirm
} from '../../Dialogs/AppConfirmationDialog';
import TriggerWaitingEmployerSummary from '../../Employers/EmployerPendings/TriggerWaitingEmployerSummary';
import {
  DatesForm,
  EmailThreads,
  StreakComments,
  StreakNotes
} from '../../Engagements/Card/EngagementCard';
import openEngagementJobDialog from '../../Engagements/Card/openEngagementJobDialog';
import {
  openCloseJob
} from '../../Engagements/CloseJob';
import {
  openWithdrawCandidate
} from '../../Engagements/WithdrawCandidate';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Col from '../../Layout/Wrappers/Col';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import Menu from '../../Layout/Wrappers/Menu';
import Row from '../../Layout/Wrappers/Row';
import Loader from '../../Shared/Loader';
import ResumeMatch from '../../Shared/ResumeMatch';
import {
  useUpdateState
} from '../useUpdateState.hook';
import Typography from '../../Layout/Wrappers/Typography';
import { compileText } from '../../../lib/String.lib';
import Ui3DotsMenu from '../../../dictionaries/Ui3DotsMenu.dic';

const REG_EXP__URL__EMPLOYER_PENDINGS = /employer\/pendings/i;

export const moreMenuOptionsEngagements = [
  {
    label: 'View engagement',
    to: (engagement) => (`/engagement/view/${engagement.id}`),
    acl: (engagement) => (
      Core.isAdmin({ action: ACCOUNT_ACTION__VIEW_ENGAGEMENT }) &&
      NOT(matchLocation(REG_EXP__URL__EMPLOYER_PENDINGS))
    ),
  },
  {
    label: 'Match candidate',
    to: (engagement) => (`/candidate/match/${engagement.candidateId}`),
    target: (engagement) => '_blank',
    acl: (engagement) => Core.isAdmin({ action: ACCOUNT_ACTION__MATCH_CANDIDATE }),
  },
  {
    label: 'Employer portal',
    to: (engagement) => (
      `/employer/pendings/?employerId=${engagement.job.employerId}&engagementId=${engagement.id}`
    ),
    target: (engagement) => '_blank',
    acl: (engagement) => (
      Core.isAdmin({ action: ACCOUNT_ACTION__EMPLOYER_PENDINGS }) &&
      NOT(matchLocation(REG_EXP__URL__EMPLOYER_PENDINGS))
    ),
  },
  {
    acl: (engagement) => Core.isAdmin(),
    label: EmployerPendingsUI.urlGeneratorDialog.title,
    action: (engagement) => openDialogAccountEmployerSelector({ employerId: engagement.job?.employerId }),
  },
  {
    acl: (engagement) => Core.isAdmin(),
    render: (engagement, { key, ...props }) => (
      <TriggerWaitingEmployerSummary {...props}
        employerId={engagement.job.employerId}
        type='MenuItem'
      />
    )
  },
  {
    acl: (engagement) => Core.isAdminOrCoordinator(),
    label: 'Update status',
    action: openUpdateStatus,
  },
  {
    label: 'Update dates',
    action: openDatesDialog,
  },
  {
    acl: () => Core.isAdmin(),
    label: 'Reassign to another job',
    action: (engagement) => openEngagementJobDialog({ engagement }),
  },
  {
    acl: engagement => Core.isAdmin(),
    label: `Match score`,
    action: engagement => syncMatchDetails(engagement).catch(Core.showError)
  },
  {
    label: 'Represent email (CAVE)',
    action: (engagement) => controlFlowCAVE({ engagementId: engagement.id })
  },
  {
    label: 'Reminder: Employer',
    action: (engagement) =>
      openEmployerTodoReminderEmailPreview({
        employer: engagement.job.employer,
        engagements: [engagement],
      }),
  },
  {
    label: 'Reminder: Job',
    action: (engagement) =>
      openJobTodoReminderEmailPreview({
        job: engagement.job,
        employer: engagement.job.employer,
        engagements: [engagement],
      }),
  },
  {
    label: 'Reminder: Recruiter',
    action: (engagement) =>
      openRecruiterReminderDialog({
        recruiterId: engagement.candidate.accountId,
        engagements: [engagement],
      }),
  },
  {
    label: 'Submission page',
    to: (engagement) =>
      (`/candidate/resume-submission/${engagement.candidateId}/${engagement.jobId}`),
    target: (engagement) => '_blank',
    acl: (engagement) => Core.isAdmin({ action: ACCOUNT_ACTION__RESUME_SUBMISSION }),
  },
  {
    label: 'Send email',
    action: (engagement) => openMessageEmailPreview({
      ...engagement,
      recruiterId: engagement.candidate.accountId,
      employerId: engagement.job.employerId,
      engagementId: engagement.id
    }),
  },
  {
    acl: (engagement) => Core.isAdminOrCoordinator(),
    label: 'Streak: Notes, comments and threads',
    action: (engagement) => openStreakNoteCommentsAndThreads(engagement),
  },
  {
    acl: engagement => Core.isAdmin(),
    label: `Streak: Fix streak box`,
    action: engagement => fixEngagementStreakBox(engagement)
  },
  {
    acl: (engagement) => Core.isAdminOrCoordinator(),
    label: 'Job: Copy',
    action: copyEngagementJob,
  },
  {
    label: 'Job: View',
    action: engagement => engagement.job.openDetails(),
  },
  {
    acl: candidate => Core.isAdmin(),
    label: 'Job: Close',
    action: ({ jobId }) => openCloseJob({ jobId }),
  },
  {
    acl: (engagement) => !!engagement.candidate.linkedInURL,
    label: 'Candidate: LinkedIn',
    action: (engagement) => Core.openPopUp(engagement.candidate.linkedInURL),
  },
  {
    acl: (engagement) => !!engagement.candidate.gitHubURL,
    label: 'Candidate: GitHub',
    action: (engagement) => Core.openPopUp(engagement.candidate.gitHubURL),
  },
  {
    acl: (engagement) => !!engagement.candidate.resumes.length,
    label: 'Candidate: View resume',
    action: (engagement) =>
      Core.openPopUp(Candidate.getMyPdfUrl(engagement.candidate)),
  },
  {
    acl: (engagement) => !!engagement.candidate.resumeTxtUrl,
    render: (engagement, { key, ...props }) => {
      const { candidate, job } = engagement;
      return (
        <div {...props} key={`engagements__more_menu__option__${key}__${uniqueId()}`}>
          <ResumeMatch
            candidateResume={candidate.resumeTxtUrl}
            jobSkills={job._technicalSkills}
            employerSkills={job.employer._technicalSkills}
          />
          <ActionComponentCandidateResumePdf
            candidate={candidate}
            jobId={job.id}
            employerId={job.employerId}
          />
        </div>
      );
    },
  },
  {
    acl: (engagement) => !!engagement.candidate.resumes.length,
    render: (engagement, { key, ...props }) => {
      const { candidate } = engagement;
      return (
        <div {...props} key={`engagements__more_menu__option__${key}__${uniqueId()}`}>
          {candidate.resumes.map((resume, index) => (
            <Menu.Item
              key={`engagement_${engagement.id}__card_candidate_resume__${resume.url}__${index}`}
              title={`Download CV ${index + 1}`}
              onClick={(event) => {
                downloadFile({
                  url: resume.url,
                  mimeType: 'application/pdf',
                  onError: Core.showError,
                });
              }}
              divider
            >
              {`Candidate: download resume (download CV ${index + 1})`}
            </Menu.Item>
          ))}
        </div>
      );
    },
  },
  {
    acl: candidate => Core.isAdmin(),
    label: 'Candidate: WithDraw',
    action: ({ candidateId }) => openWithdrawCandidate({ candidateId }),
  },
  {
    acl: (engagement) => Core.isAdmin(),
    label: (
      <Typography className='c-red'>
        {compileText(Ui3DotsMenu.Common.deleteEntity, {
          ENTITY: matchLocation(/\/(create|edit)/i) ? '' : Ui3DotsMenu.Engagement.ENTITY
        })}
      </Typography>
    ),
    action: (engagement) => deleteEngagement(engagement),
  },
];

function updateEngagement({ engagement, update }) {
  // updateUrlState from current live instance hook
  const updateUrlState = Core.getKeyValue('updateUrlState') || console.debug;

  Object.keys(update).forEach((key) => (engagement[key] = update[key]));
  mapEngagement(engagement);
  Engagement.update(
    engagement,
    update,
    (response) => {
      updateUrlState({ _updatedAt: response._updatedAt });
      Fun(engagement.___engagementCardUpdater___)(update);
    },
    (failure) => Core.showError(`failed to update engagement with id = ${engagement.id}`)
  );
}

export async function copyEngagementJob(engagement) {
  Core.showMessage(<Loader className="f-md">Getting Job Info...</Loader>);
  let job = await Job.get(engagement.job.id);
  copyHtml(Job.getPreview(job))
    .then((em) => {
      Core.log('Copy email command was successful');
      Core.showSuccess('Copied!');
    })
    .catch((ex) => {
      Core.log('Oops, unable to copy');
      Core.showError('Fail copy!');
    });
}

function openDatesDialog(engagement) {
  const _key = `Home__moreMenuOptionsEngagements__openDatesDialog__${engagement.id}`;
  showConfirm({
    title: <>Update Dates</>,
    content: (
      <DatesForm
        ref={(self) => (self && Core.setKeyValue(_key, self))}
        engagement={engagement}
      />
    ),
    onAcceptLabel: 'Done',
    onAccept: () => {
      const { getDates = () => Obj() } = Obj(Core.getKeyValue(_key));
      const update = { ...getDates() };
      if (Object.keys(update).length) {
        updateEngagement({
          engagement,
          update
        });
      }
    },
    paperStyle: {
      width: 640
    }
  });
}

function openUpdateStatus(engagement) {
  Core.dialog.open({
    title: <>Update Status</>,
    content: (
      <UpdateEngagementStatusOption
        setConfirmation={(action) => (openUpdateStatus.onConfirm = action)}
        engagement={engagement}
      />
    ),
    paperStyle: { width: 480 },
    actions: [
      <Button outlined minW120
        onClick={(ev) => {
          Core.dialog.close();
        }}
      >
        Cancel
      </Button>,
      <Button primary minW120
        onClick={(ev) => {
          Core.dialog.close();
          openUpdateStatus.onConfirm();
        }}
      >
        Submit
      </Button>,
    ],
  });
}

function UpdateEngagementStatusOption({
  engagement,
  setConfirmation = () => { },
}) {
  const { state = {}, updateState } = useUpdateState();

  useEffect(() => {
    if (!state.engagement && engagement.___model___ === 'Engagement') {
      let model = { ...engagement, engagement };
      updateState({
        model,
        stages: [...ENGAGEMENT__ACTIVE_STAGES],
        statuses: [...(
          ENGAGEMENT__STAGE_STATUSES[model.stage] ||
          ENGAGEMENT__ACTIVE_STATUSES
        )],
        states: [...ENGAGEMENT__STATES],
        rejectionReasons: [...ENGAGEMENT__ACTIVE_REJECTION_REASONS],
        matchStrengths: Definition.get('engagementMatchStrength'),
        stage: model.stage,
        status: model.status,
        state: model.state,
        engagement: model.engagement,
        rejectionReason: model.rejectionReason,
        matchStrength: model.matchStrength,
        cbUpdateField: model.cbUpdateField,
        rejectionReasonAdditionalInfo: !!model.engagement
          ? model.engagement.rejectionReasonAdditionalInfo ||
          model.engagement.rejectionReason
          : '',
      });
    }
  }, [state, engagement, updateState]);

  setConfirmation(() => {
    const update = {
      stage: state.stage,
      status: state.status,
      state: state.state,
      rejectionReason: state.rejectionReason,
      matchStrength: state.matchStrength,
      rejectionReasonAdditionalInfo: state.rejectionReasonAdditionalInfo,
    };
    const newDate = new Date();
    const newISO = newDate.toISOString();
    [
      [
        new RegExp(
          join([
            STAGE_SUBMISSION,
            STAGE_REVIEW,
            STAGE_SCREEN,
            STAGE_ONSITE,
            STAGE_OFFER,
            STAGE_HIRE,
            STAGE_GUARANTEE
          ], '|')
        ),
        "confirmed"
      ],
      [
        new RegExp(
          join([
            STAGE_REVIEW,
            STAGE_SCREEN,
            STAGE_ONSITE,
            STAGE_OFFER,
            STAGE_HIRE,
            STAGE_GUARANTEE
          ], '|')
        ),
        "submitted"
      ],
      [
        new RegExp(
          join([
            STAGE_SCREEN,
            STAGE_ONSITE,
            STAGE_OFFER,
            STAGE_HIRE,
            STAGE_GUARANTEE
          ], '|')
        ),
        "reviewed"
      ],
      [
        new RegExp(
          join([
            STAGE_ONSITE,
            STAGE_OFFER,
            STAGE_HIRE,
            STAGE_GUARANTEE
          ], '|')
        ),
        "screened"
      ],
      [
        new RegExp(
          join([
            STAGE_OFFER,
            STAGE_HIRE,
            STAGE_GUARANTEE
          ], '|')
        ),
        "onsite"
      ],
      [
        new RegExp(
          join([
            STAGE_HIRE,
            STAGE_GUARANTEE
          ], '|')
        ),
        "offered"
      ]
    ].forEach((item) => {
      if (item[0].test(update.stage) && !engagement[item[1]]) {
        update[item[1]] = newISO;
        Core.log(item[1]);
      }
    });
    let fields = update;

    if (!!state.engagement) {
      if (state.matchStrength !== state.engagement.matchStrength) {
        fields = {
          ...fields,
          matchedByWho: Core.getUser(),
          lastMatchedDate: new Date().toISOString(),
        };
      }
    } else {
      //it means its a new entry
      fields = {
        ...fields,
        matchedByWho: Core.getUser(),
        lastMatchedDate: new Date().toISOString(),
      };
    }

    Core.log('Modal Update Status from Submit', 'fields', fields);
    if (!!state.cbUpdateField) {
      state.cbUpdateField(state.engagement, fields);
    } else {
      updateEngagement({
        engagement,
        update: fields
      });
    }
  });
  if (!state.___model___ === 'Engagement') {
    return <div>No engagement set</div>;
  }
  const setStage = (stage) => {
    updateState({
      stage,
      statuses: [...(
        ENGAGEMENT__STAGE_STATUSES[stage] ||
        ENGAGEMENT__ACTIVE_STATUSES
      )]
    });
  };
  const setMatchStrength = (matchStrength) => {
    updateState({ matchStrength });
  };
  const setStatus = (status) => {
    updateState({ status });
  };
  const setSt = (state) => {
    updateState({ state });
  };
  return (
    <Box column>

      <Fieldset
        title='Stage'
      >
        <Menu dropdown
          name="stage"
          value={state.stage}
          onChange={setStage}
          options={state.stages}
        />
      </Fieldset>

      <Fieldset
        title='Status'
      >
        <Menu dropdown
          name="status"
          value={state.status}
          onChange={setStatus}
          options={state.statuses}
        />
      </Fieldset>

      <Fieldset
        title='State'
      >
        <Menu dropdown
          name="state"
          value={state.state}
          onChange={setSt}
          options={state.states}
        />
      </Fieldset>

      <Fieldset
        title='Strength'
      >
        <Menu dropdown
          name="matchStrength"
          value={state.matchStrength}
          onChange={setMatchStrength}
          options={state.matchStrengths}
        />
      </Fieldset>

    </Box>
  );
}

function openEmailThreads(engagement) {
  Streak.getThreads(engagement.boxKey, (threads) => {
    if (!!threads.length) {
      Core.dialog.open({
        title: <>Email Threads</>,
        message: (
          <EmailThreads
            ref={(self) => (openEmailThreads.EmailThreads = self)}
            threads={threads}
            engagement={engagement}
          />
        ),
        paperStyle: { minWidth: '60vw' },
        actions: [
          <Button
            className="button-white-cyan"
            onClick={(ev) => Core.dialog.close()}
          >
            Done
          </Button>,
        ],
      });
    } else {
      Core.showWarning("This Streak Box, doesn't have email threads attached yet");
    }
  });
}

export function openStreakNoteCommentsAndThreads(engagement) {
  Core.hideMessage();
  Core.showMessage(<Loader className="f-md">Fetching threads, wait a moment...</Loader>);
  Streak.getThreads(engagement.boxKey, (threads) => {
    Core.showMessage(<Loader className="f-md">Fetching notes, wait a moment...</Loader>);
    Streak.getBox(engagement.boxKey, (box) => {
      Core.hideMessage();
      Core.showMessage(<Loader className="f-md">Fetching previous comments, wait a moment...</Loader>);
      Streak.getComments(engagement.boxKey, (comments) => {
        Core.hideMessage();
        Core.dialog.open({
          title: <>Streak</>,
          content: (
            <Box row w100 className='flex-align-left-top'>
              <div className="p-1 mr-1 outlined rounded scroll-y" style={{ width: '33%' }}>
                <Col className="p-0">
                  <label>Edit Note</label>
                </Col>
                <StreakNotes boxKey={engagement.boxKey} notes={box.notes} />
              </div>
              <div className="p-1 mr-1 outlined rounded scroll-y" style={{ width: '33%' }}>
                <Col className="p-0">
                  <label>Add comment</label>
                </Col>
                <StreakComments
                  ref={(self) => (openStreakNoteAndCommentsDialog.StreakComments = self)}
                  boxKey={engagement.boxKey}
                  comments={comments}
                />
              </div>
              <div className='p-1 outlined rounded scroll-y' style={{ width: '33%' }}>
                <Col className="p-0">
                  <label>Threads</label>
                </Col>
                {!!threads.length && (
                  <EmailThreads
                    ref={(self) => (openEmailThreads.EmailThreads = self)}
                    threads={threads}
                    engagement={engagement}
                  />
                )}
              </div>
            </Box>
          ),
          paperStyle: {
            width: 'calc(100vw - 2rem)',
            maxWidth: 'unset',
            // height: 'calc(100vh - 11rem)'
          },
          actions: [
            <Button outlined minW120
              onClick={(ev) => Core.dialog.close()}
            >
              Cancel
            </Button>,
            <Button primary minW120
              onClick={(ev) => {
                openStreakNoteAndCommentsDialog.StreakComments.saveToCloud();
                Core.dialog.close();
              }}
            >
              Done
            </Button>
          ],
        });
      });
    });
  });
}

export function openStreakNoteAndCommentsDialog(engagement) {
  Core.showMessage('Fetching notes, wait a moment...');
  Streak.getBox(engagement.boxKey, (box) => {
    Core.hideMessage();
    Core.showMessage('Fetching previous comments, wait a moment...');
    Streak.getComments(engagement.boxKey, (comments) => {
      Core.hideMessage();
      Core.dialog.open({
        title: <>Streak</>,
        message: (
          <Row>
            <Col className="px-1">
              <label>Edit Note</label>
              <StreakNotes boxKey={engagement.boxKey} notes={box.notes} />
            </Col>
            <Col className="px-1">
              <label>Add comment</label>
              <StreakComments
                ref={(self) => (openStreakNoteAndCommentsDialog.StreakComments = self)}
                boxKey={engagement.boxKey}
                comments={comments}
              />
            </Col>
          </Row>
        ),
        actions: [
          <Button
            key="action-button-cancel"
            variant="outlined"
            color="secondary"
            onClick={(ev) => Core.dialog.close()}
          >
            Cancel
          </Button>,
          <Button
            key="action-button-done"
            variant="contained"
            color="primary"
            onClick={(ev) => {
              openStreakNoteAndCommentsDialog.StreakComments.saveToCloud();
              Core.dialog.close();
            }}
          >
            Done
          </Button>
        ],
      });
    });
  });
}

export async function fixEngagementStreakBox(engagement) {
  try {
    /** 
     * @note 
     * We are requesting the engagement update in order to trigger the sync of the Streak box by the BE worker.
     */
    const update = { lastAction: new Date().toISOString() };
    await Engagement.update(engagement, update);
    Fun(engagement.___engagementCardUpdater___)(update);
    Core.showSuccess(
      <pre>
        Streak box creation scheduled<br />
        {join(
          [
            engagement.candidate._name,
            engagement.job.jobTitle,
            engagement.stage,
            engagement.status
          ], ' - '
        )}
      </pre>
    );
  }
  catch (error) {
    globalErrorHandler(error);
  }
}

function deleteEngagement(engagement) {
  Core.dialog.open({
    title: 'Confirm',
    message: `Delete "${engagement._name}" engagement?`,
    paperStyle: { width: 640 },
    actions: [
      <Button
        key={`action-button-confirm`}
        color='error'
        variant='outlined'
        onClick={event => {
          Core.dialog.close();
          Engagement.delete(
            engagement.id,
            (response) => {
              showAlert({
                message: 'Engagement was deleted successfully',
                onClose: () => reloadLocation()
              });
            },
            (error) => {
              if (!!error) {
                showAlert({
                  message: 'Can not delete engagement. Please contact support.',
                  severity: 'warning'
                });
              }
            }
          )
        }}
      >
        Confirm
      </Button>,
      <Button
        key={`action-button-cancel`}
        color='primary'
        variant='contained'
        onClick={Core.dialog.close}
      >
        Cancel
      </Button >,
    ],
  });
}

export async function syncMatchDetails(engagement) {
  return new Promise((resolve, reject) => {
    Job.getMlMatchingScoreBulkCandoMatch(
      {
        params: {
          candidateId: engagement.candidateId,
          jobIds: [engagement.jobId],
          currentMlModel: MLM__HARDCODED_ID
        },
        apiMethod: "get_jobs_scores"
      },
      // ON SUCCESS
      async (response) => {
        response = Obj(Arr(response)[0]);
        let matchDetails = Obj(response.match);
        let probScore = matchDetails.score;
        let update = { probScore, matchDetails };
        Object.assign(engagement, update);
        await Engagement.update(engagement, update).then(response => {
          if (engagement.___engagementCardUpdater___ instanceof Function) {
            engagement.___engagementCardUpdater___(update);
          }
          else {
            Core.showWarning('This action requires reload the page', Core.reload);
          }
          resolve(response);
        }).catch(reject);
      },
      // ON ERROR
      reject
    );
  })
}
