import moment from "moment-business-days";
import {
  CANDIDATE_NOTES__DICTIONARY_KEY
} from '../components/Candidates/Forms/CandidateNotes';
import {
  removeContactInfoFromSubmissionNote
} from '../components/Employers/EmployerPendings/Libraries/EmployerPendings.lib';
import {
  getMatchFlags
} from "../components/Match/Libraries/Match.lib";
import {
  ENGAGEMENT__STATE_CLOSED,
  ENGAGEMENT__STATE_OPEN,
  STAGE_CONFIRMATION,
  STAGE_END,
  STAGE_GUARANTEE,
  STAGE_HIRE,
  STAGE_OFFER,
  STAGE_ONSITE,
  STAGE_PENDING,
  STAGE_REVIEW,
  STAGE_SCREEN,
  STAGE_SUBMISSION,
  STATUS_W_10X10,
  STATUS_W_CANDIDATE,
  STATUS_W_CANDIDATE_SUBMIT_HOMEWORK,
  STATUS_W_EMPLOYER,
  STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK,
  STATUS_W_GUARANTEE,
  STATUS_W_ONSITE,
  STATUS_W_SCREEN,
  STATUS_W_START_DATE
} from "../dictionaries/Engagement.dic";
import Candidate from "./Candidate";
import Core from "./Core";
import {
  getPacificTimeIsoString,
  isValidDate
} from './Date.lib';
import Definition, {
  ATS_TYPE__NONE_ID
} from "./Definition";
import {
  Fun
} from './Function.lib';
import Http from "./Http";
import Job from "./Job";
import {
  model as engModel,
  mapAtsContextNone,
  mapEngagement,
  mapEngagements
} from "./models/engagement";
import getHoldModel from './models/hold.model';
import {
  flattenObject,
  Obj,
  safeStringify,
  stringify
} from './Object.lib';
import {
  mapContactsToStrings
} from './services/Email/Email.lib';
import Streak from './Streak';
import {
  joinKeyName,
  trim
} from './String.lib';
import cleanHtml from "./tools/cleanHtml";
import formatURL from './tools/formatURL';
import getStateModel from "./tools/getStateModel";

const cache = {};
const commonQuery = {
  include: [
    {
      relation: "engagementStarreds",
      scope: {
        where: {
          accountId: Core.getUserId()
        }
      }
    },
    { candidate: "account" },
    { job: "employer" },
    "actionOwner"
  ]
};

/** * /

db.Engagement.find({filter:include:[{relation:"engagementStarreds",scope:{where:{accountId:"5a73ad4f7c136c3b3d173f41"}}},{candidate:"account"},{job:"employer"},"actionOwner"]}})

/** */

const menus = [];
const more = [];
const listTabs = ["Name", "Recent", "Starred"];
const matchStrengths = [
  { key: 'A', value: 'A - Strong Match' },
  { key: 'B', value: 'B - Match' },
  { key: 'C', value: 'C - Stretch Match' },
  { key: 'E', value: 'E - Mismatch' },
  { key: 'F', value: 'F - Strong Mismatch' },
  { key: 'U', value: 'U - Unknown' },
];

const cacheStrengthsPrefix = "jobMatchStrength-";

const listTab = "Name";
const stageOrder = [
  STAGE_PENDING,
  STAGE_CONFIRMATION,
  STAGE_SUBMISSION,
  STAGE_REVIEW,
  STAGE_SCREEN,
  STAGE_ONSITE,
  STAGE_OFFER,
  STAGE_GUARANTEE,
  STAGE_HIRE,
  STAGE_END
];

const Engagement = {
  name: 'Engagement',
  menus,
  more,
  listTabs,
  listTab,
  stageOrder,
  matchStrengths,
  cacheStrengthsPrefix,
  columns: [
    {
      headers: [
        {
          label: "Info",
          key: "_col1Cmp",
          sortKey: "_name",
          filter: true
        },
        { label: "Flags", key: "_flags" }
      ],
      selected: 0,
      style: { minWidth: 256 }
    },
    {
      headers: [
        { label: "Stage", key: "_stage" },
        { label: "Employees", key: "_employeeCount", sortKey: "employeeCount" }
      ],
      selected: 0
    },
    {
      headers: [
        { label: "City", key: "addressCity" },
        { label: "State", key: "_state" }
      ],
      selected: 0
    },
    {
      headers: [
        { label: "Active Jobs", key: "_active", sortKey: "_activeNum" },
        { label: "Jobs Count", key: "_jobsLength", sortKey: "_jobsLengthNum" }
      ],
      selected: 0
    },
    {
      headers: [
        {
          label: "Starred",
          key: "_rowOptionsCmp",
          sortKey: "_starred",
          reverseSort: true,
          hint: "Options"
        }
      ],
      selected: 0,
      style: { width: 124, textAlign: "right" }
    }
  ],
  cleanCache: em => {
    Object.keys(cache).forEach(key => {
      delete cache[key];
    })
  },

  /**
   * Creates an Streak Box,
   * then updates boxKey of engagement on DB
   */
  createBox: ({ engagement, onSuccess = () => null, onFailure }) => {
    const today = new Date();
    const {
      candidate,
      recruiter,
      job,
      _role: role,
      stage,
      status,
      state
    } = engagement;
    const {
      employer
    } = job;
    const boxOptions = {
      name: `${candidate._name} - ${Object(employer).name}`,
      recruiter: `${Object(recruiter)._name}`,
      stage: stage || STAGE_PENDING,
      status: status || STATUS_W_10X10,
      state: state || ENGAGEMENT__STATE_OPEN,
      role,
      introduced: candidate.introduced,
      matched: today.getTime(),
      confirmed: (stage === STAGE_SUBMISSION) ? today.toISOString() : null,
      platformRating: candidate.platformRating,
      jobTitle: job.jobTitle,
      engagementURL: Core.getPath(`engagement/view/${engagement.id}`)
    };
    console.debug('µ:createBox', boxOptions, engagement);
    Streak.createBox(
      boxOptions,
      box => {
        Core.log("Created Box", box);
        const { boxKey } = box;
        Engagement.updateBoxKey({
          engagement,
          boxKey,
          onSuccess,
          onFailure
        });
      },
      onFailure
    );
  },

  /**
   * Updates boxKey of an engagement on DB
   */
  updateBoxKey({ engagement, boxKey, onSuccess = () => null, onFailure }) {
    const update = { boxKey, status: engagement.status };
    Engagement.overdueCalculation(engagement, update);
    Http.patch(
      Core.getApi("Engagements/" + engagement.id),
      update,
      engagement => {
        engagement = mapEngagement(engagement);
        Core.log("Updated boxKey on Engagement", engagement);
        onSuccess(engagement);
      },
      onFailure
    );
  },

  getActives: (success, skipCache) => {
    success = success instanceof Function ? success : function () {
    };
    if (!skipCache && cache.actives) {
      setTimeout(st => success(cache.actives));
    } else {
      return Http.get(
        Core.getApi("Engagements"),
        {
          filter: JSON.stringify({ ...commonQuery, where: { state: 1 } })
        },
        function onSuccess(response) {
          cache.actives = mapEngagements(response);
          success(cache.actives);
        }
      );
    }
  },

  negativeStrengths: () => {
    return Definition.get("engagementMatchStrength").filter(obj => (/Mismatch/i.test(obj.label))).map(o => o.id);
  },

  unSpecifiedStrength: () => {
    return Definition.get("engagementMatchStrength").filter(obj => (/Unspecified|Unknown/i.test(obj.label))).find(o => o.id);
  },

  matchStrengthLabel: (number) => {
    let el = Definition.get("engagementMatchStrength").find(o => o.id === number);
    let ret = "";
    if (el) {
      ret = el.label;
    }

    return ret;
  },

  getAll: (cb, skipCache) => {
    cb = cb instanceof Function ? cb : function () {
    };
    if (!skipCache && cache.all) {
      setTimeout(st => cb(cache.all));
    } else {
      return Http.get(
        Core.getApi("Engagements"),
        {
          filter: JSON.stringify({ ...commonQuery })
        },
        function onSuccess(response) {
          cache.all = mapEngagements(response);
          cb(cache.all);
          //Core.log("Engagement.getAll", {response});
        }
      );
    }
  },

  getCacheKeyJobMatch: (candidateId, jobId) => {
    return `${cacheStrengthsPrefix}${candidateId}-${jobId}`
  },

  getWhere: async (
    where,
    callback = () => null,
    filters = {},
    options = {}
  ) => {
    try {
      const include = !!filters.include ? {} : commonQuery
      const query = {
        ...include,
        ...filters,
        where: { ...where },
      }
      const _key = `engagement__get_where__${safeStringify(query)}`;
      // console.debug('Engagement.getWhere\n', options.source, _key, new Error().stack);
      cache[_key] = cache[_key] || mapEngagements(
        await Http.get(Core.getApi("Engagements"), {
          filter: JSON.stringify(query)
        })
      );
      callback(cache[_key]);
      return cache[_key];
    }
    catch (error) {
      Core.showError(error);
    }
    callback([]);
    return [];
  },

  /** @todo consolidate getWhere, to handle a promise every where it is called */
  getWhereV2: async (
    where = {},
    filters = {}
  ) => {
    const include = !!filters.include ? {} : commonQuery
    const query = {
      ...include,
      ...filters,
      where: { ...where },
    }
    const cacheKey = `engagement__get_where__${stringify(flattenObject(query))}`;
    cache[cacheKey] = cache[cacheKey] || Http.get(
      Core.getApi("Engagements"),
      {
        filter: JSON.stringify(query)
      }
    ).then(mapEngagements);
    return cache[cacheKey];
  },

  getCandidates: success => {
    Http.get(
      Core.getApi("Engagements"),
      {
        filter: JSON.stringify({
          fields: ["candidateId", "boxKey"],
          include: ["candidate"]
        })
      },
      function onSuccess(engagements) {
        success(mapEngagements(engagements));
      }
    );
  },
  getBoxIds: success => {
    Http.get(
      Core.getApi("Engagements"),
      {
        filter: JSON.stringify({
          fields: { id: true, boxKey: true }
        })
      },
      success
    );
  },
  /**
   *
   * @param {*} params
   * @param {*} params.status open (default) | closed | all
   * @param {*} params.onSuccess
   * @param {*} params.onFailure
   * @returns
   */
  getBoxIdsV2({
    status = 'open',
    onSuccess = () => { },
    onFailure = () => { },
  }) {
    const query = {
      'all': ({
        fields: { id: true, boxKey: true }
      }),
      'open': ({
        where: { state: "Open" },
        fields: { id: true, boxKey: true }
      }),
      'closed': ({
        where: { state: "Closed" },
        fields: { id: true, boxKey: true }
      })
    };
    return Http.get(
      Core.getApi("Engagements"),
      {
        filter: JSON.stringify(query[status])
      },
      onSuccess,
      onFailure,
    );
  },
  get: async (engagementId, cb = () => { }) => {
    if (cache[engagementId]) {
      setTimeout(st => cb(cache[engagementId]));
      return cache[engagementId];
    } else {
      return Http.get(
        Core.getApi("Engagements/" + engagementId),
        {
          filter: JSON.stringify({ ...commonQuery })
        },
        function onSuccess(response) {
          cache[engagementId] = mapEngagement(response);
          cb(cache[engagementId]);
        }
      ).then(mapEngagement);
    }
  },
  overdueCalculation: (engagement, update) => {
    const locale = moment.locale();
    moment.updateLocale(moment.locale(), {
      holidays: Definition.get("holidays").map(item => item.label),
      holidayFormat: "YYYY-MM-DD",
      workingWeekdays: [1, 2, 3, 4, 5]
    });
    const getOverdue = (date, hours) => {
      const days = Math.ceil((hours || 48) / 24);
      let result = moment(date)
        .startOf("day")
        .businessAdd(days)
        ._d.toISOString();
      Core.log({
        locale,
        date: moment(date).toISOString(),
        hours,
        days,
        result
      });
      return result;
    };
    if (
      /**
       * If stage is Review
       * and there is updating submitted
       * overdue is submitted + employer.expectedResumeResponseTime
       */
      (engagement.stage === STAGE_REVIEW) && update.submitted
    ) {
      update.overdueDate = getOverdue(
        update.submitted,
        engagement.employer.expectedResumeResponseTime
      );
    } else if (
      /**
       * If stage is Screen
       * and there is updating an eventDate
       * overdue is eventDate + employer.expectedScreenResponseTime
       */
      (engagement.stage === STAGE_SCREEN) &&
      (update.screen3 || update.screen2 || update.screen1)
    ) {
      update.overdueDate = getOverdue(
        update.screen3 || update.screen2 || update.screen1,
        engagement.employer.expectedScreenResponseTime
      );
    } else if (
      /**
       * If stage is Onsite
       * and there is updating an eventDate
       * overdue is eventDate + employer.expectedOnsiteResponseTime
       */
      (engagement.stage === STAGE_ONSITE) && (update.onsite2 || update.onsite1)
    ) {
      update.overdueDate = getOverdue(
        update.onsite2 || update.onsite1,
        engagement.employer.expectedOnsiteResponseTime
      );
    }
    if (
      /**
       * If there is updating the status to "{{STATUS_W_EMPLOYER}}"
       * overdue is now + employer.expectedSchedulingResponseTime
       */
      update.status &&
      new RegExp(STATUS_W_EMPLOYER, 'i').test(update.status)
    ) {
      update.overdueDate = getOverdue(
        moment(),
        engagement.employer.expectedSchedulingResponseTime
      );
    } else if (
      /**
       * If there is updating the status to "{{STATUS_W_CANDIDATE}}""
       * overdue is now + 2 days
       */
      update.status &&
      new RegExp(STATUS_W_CANDIDATE).test(update.status)
    ) {
      update.overdueDate = getOverdue(moment(), 48);
    } else if (
      /**
       * If there is updating the status to "{{STATUS_W_10X10}}"
       * overdue is now
       */
      update.status &&
      new RegExp(STATUS_W_10X10, 'i').test(update.status)
    ) {
      update.overdueDate = moment().toISOString();
    }
    /**
     * If there is updating the holdDate
     * overdue is holdDate
     */
    if (!!update.holdDate) {
      update.overdueDate = update.holdDate;
    } else if (update.status && /h - /i.test(update.status)) {
      /**
       * If and there is updating the status to "H - *"
       * overdue is now + employer.expectedSchedulingResponseTime
       */
      update.holdDate =
        engagement.holdDate ||
        getOverdue(
          moment(),
          engagement.employer.expectedSchedulingResponseTime
        );
      update.overdueDate = update.holdDate;
    }
    return update;
  },
  match: async (
    {
      candidate,
      job,
      stage,
      status,
      statusNote,
      matchStrength,
      jobAnswers = [],
      rejectionReason,
      rejectionReasonAdditionalInfo,
      state
    },
    onSuccess = Fun,
    onFailure = Fun
  ) => {
    const today = new Date();
    Engagement.cleanCache();
    Job.cleanCache();
    Candidate.cleanCache();
    const engFlags = getMatchFlags({
      status,
      candidate,
      job,
    });

    // [story_441] Set "Candidate Summary" as default value for Engagement.submissionNote.
    let submissionNote = removeContactInfoFromSubmissionNote(
      Obj(Core.getKeyValue(CANDIDATE_NOTES__DICTIONARY_KEY))[candidate.id]
    );

    return Http.post(
      Core.getApi("Engagements"),
      getStateModel(
        {
          candidateId: candidate.id,
          jobId: job.id,
          stage: stage || STAGE_PENDING,
          status: status || STATUS_W_10X10,
          statusNote,
          state: state || "Open",
          jobAnswers,
          matched: today.toISOString(),
          statusUpdatedDate: today.toISOString(),
          lastAction: today.toISOString(),
          matchStrength,
          matchedByWho: { email: Object(Core.getUser().email) },
          lastMatchedDate: new Date().toISOString(),
          introduced: candidate.introduced,
          confirmed: (stage === STAGE_SUBMISSION) ? today.toISOString() : null,
          platformRating: candidate.platformRating,
          jobTitle: job.jobTitle,
          rejectionReason,
          rejectionReasonAdditionalInfo,
          submissionNote,
          ...engFlags,
        },
        engModel
      ),
      engagement => {
        engagement = mapEngagement(engagement);
        // if boxKey and candidate.emailsList create the initial streak note
        if (engagement.boxKey) {
          let candidateRecruiters = mapContactsToStrings(candidate.emailsList).join(', ').trim();
          if (candidateRecruiters) {
            Streak.getBox(engagement.boxKey, (box) => {
              const sign = ` - ${Core.getUserName()} - ${moment().tz(moment.tz.guess()).format("lll z")}`;
              Streak.updateNotes({
                boxKey: box.boxKey,
                notes: `${box.notes ? box.notes : ''}Candidate Recruiters: ${candidateRecruiters}\n\n${sign}`
              });
            });
          }
        }
        onSuccess(engagement);
      },
      onFailure
    );
  },

  updateMany: (engagements, success, failure) => {
    Http.patch(
      Core.getApi("Engagements/updateMany"),
      engagements,
      success,
      failure
    )
  },

  update: async (engagement, update, success, failure) => {
    const engagementId = engagement.id;
    Engagement.cleanCache();
    Job.cleanCache();
    Candidate.cleanCache();
    Engagement.overdueCalculation(engagement, update);
    update.lastAction = update.lastAction || new Date().toISOString();
    Core.log("Engagement", "update", engagementId, update);

    /** for retro-compatibility see engagement.holdDate(old) and engagement.hold.endDate(new) */
    if (update.holdDate && (update.holdDate !== update.hold?.endDate)) {
      update.hold = getHoldModel({
        prefill: true,
        startDate: update.hold?.startDate || engagement.hold?.startDate,
        endDate: update.hold?.endDate || engagement.hold?.endDate,
        note: update.hold?.note || engagement.hold?.note
      });
    }

    if (update.status !== undefined) {
      update.statusUpdatedDate = moment().toISOString();
      if (update.status === STATUS_W_CANDIDATE_SUBMIT_HOMEWORK) {
        update.homeworkAssigned = moment().toISOString();
        update.homeworkCompleted = '';
      }
      else if (
        !!engagement.homeworkAssigned &&
        (update.status === STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK)
      ) {
        update.homeworkCompleted = moment().toISOString();
      }
    }

    if (update.state === ENGAGEMENT__STATE_OPEN) {
      update.rejectionReason = '';
      update.rejectionReasonAdditionalInfo = '';
    }

    return Http.patch(
      Core.getApi("Engagements/" + engagementId),
      {
        ...getStateModel(update, engModel),
        /** 
         * @todo 
         * [2022-10-03][story_6573][POC]
         * [2023-08-25][story_9528][leave disabled event context object for now]
         * /
        // For BE to create event on update
        __eventContext: {
          globalText: `This to test text in global context ${(new Date()).toISOString()}`,
          userName: Core.getUserName(),
          state: update.state || engagement.state,
          stage: {
            text: `This is just to test stage prop update ${(new Date()).toISOString()}`,
            prev: engagement.stage,
            new: update.stage
          },
          status: {
            text: `This is just to test status prop update ${(new Date()).toISOString()}`,
            prev: engagement.status,
            new: update.status
          }
        }
        /** */
      },

      response => {
        response = mapEngagement(response);

        if (update.stage === STAGE_SCREEN) {
          const candidate = engagement.candidate;
          const job = engagement.job;
          const line = cleanHtml(
            "<p>" +
            [
              [
                moment(update.lastAction).format("MM/DD/YY"),
                `<a href="/#/candidate/edit/${candidate.id
                }" target="_blank">${candidate._name}</a>`,
                Definition.getLabel(
                  "platformRating",
                  candidate.platformRating
                )
              ]
                .filter(e => !!e && !!String(e).trim().length)
                .join(", "),
              Definition.getLabels(
                "positiveSignals",
                candidate.positiveSignals
              ).join(", "),
              Definition.getLabels(
                "positiveSignals",
                candidate.negativeSignals
              ).join(", "),
              candidate.tagLine
            ]
              .filter(e => !!e && !!String(e).trim().length)
              .join("; ") +
            ".</p>"
          );
          const examplesOfAccepted =
            typeof job.examplesOfAccepted === "string"
              ? line + job.examplesOfAccepted
              : line;
          Job.update(
            job.id,
            {
              examplesOfAccepted
            },
            jobResponse => {
              /**
               * Delaying response to avoid contention warnings
               * from Streak
               */
              setTimeout(() => {
                success && success(response);
              }, 15);
            }
          );
        } else {
          /**
           * Delaying response to avoid contention warnings
           * from Streak
           */
          setTimeout(() => {
            success && success(response);
          }, 15);
        }
      },
      failure
    ).then(response => Engagement.get(response.id));
  },
  delete: (engagementId, success) => {
    Engagement.cleanCache();
    Engagement.getWhere({ id: engagementId }, eng => {
      eng = { ...eng[0] };
      Http.delete(Core.getApi("Engagements/" + engagementId), response => {
        Streak.deleteBox({ boxKey: eng.boxKey });
        success && success(response);
      });
    });
  },
  updateStarred: (engagementId, starredId, starred, success) => {
    Engagement.cleanCache();
    if (starredId) {
      return Http.patch(
        Core.getApi("EngagementStarred/" + starredId),
        { starred },
        success
      );
    } else {
      return Http.post(
        Core.getApi("EngagementStarred"),
        {
          engagementId,
          starred,
          accountId: Core.getUserId()
        },
        success
      );
    }
  },
  sendResumeSubmissionEmail: (params, success, failure) => {
    /**
      var params = {
        from: from,
        to: to.join(","),
        cc: cc.join(","),
        bcc: bcc,
        subject: subject,
        html: html
      };
     */
    Http.post(
      Core.getApi("Engagements/sendResumeSubmissionEmail"),
      params,
      response => {
        Core.showSuccess("Resume submission sent.");
        success && success(response);
      },
      error => {
        Core.showFailure(
          "Email may not be sent. Please check the Sent folder and retry."
        );
        failure && failure(error);
      }
    );
  },
  newPipelineReport: async (params, success, failure) => {
    const key = joinKeyName(['new_pipeline_report', ...Object.entries(params).flat()]);
    cache[key] = cache[key] || Http.post(
      Core.getApi("Engagements/newPipelineReport"),
      params
    );
    return cache[key];
  },
  cleanCacheNewPipelineReport: () => {
    Object.keys(cache).forEach((key) => {
      if (key.startsWith('new_pipeline_report')) {
        delete cache[key];
      }
    })
  },
  getStructure: (queryParam = '', cb) => {
    Http.get(
      Core.getApi(`Engagements/getStructure${queryParam}`),
      function onSuccess(response) {
        cb(response);
      }
    );
  },
  getStructureAccount: (queryParam = '', cb) => {
    Http.get(
      Core.getApi(`Engagements/getStructureAccount${queryParam}`),
      function onSuccess(response) {
        cb(response);
      }
    );
  },
  async close({
    engagement,
    status,
    rejectionReason,
    rejectionReasonAdditionalInfo,
    confirmed
  }) {
    let update = {
      state: ENGAGEMENT__STATE_CLOSED,
      status,
      rejectionReason,
      rejectionReasonAdditionalInfo: rejectionReasonAdditionalInfo || rejectionReason,
      closed: getPacificTimeIsoString()
      // Do not need to specify lastAction or statusUpdateDate, since the update is care of them
    };
    if (isValidDate(confirmed)) { update.confirmed = confirmed; }
    return Engagement.update(engagement, update);
  }
};

export {
  Engagement as default,
  Engagement as EngagementLib
};

export function saveEmpAtsUrl(engagement) {
  return async empAtsUrl => {
    engagement = mapAtsContextNone(engagement);
    empAtsUrl = formatURL(empAtsUrl);
    Object.assign(
      engagement.atsContext[ATS_TYPE__NONE_ID],
      { empAtsUrl }
    );
    await Engagement.update(engagement, {
      atsContext: engagement.atsContext
    }).catch(Core.showError);
  }
}

export function getEngagementUrl({ engagement, engagementId }) {
  return Core.getPath(`engagement/view/${engagementId || trim(Obj(engagement).id)}`);
}

export function getToBeDefinedMatchStrengthLabel({ status, value }) {
  return (status === STATUS_W_10X10) ? 'TBD' : value;
}

export function isWaiting(status) {
  return /w -/i.test(status);
}

export function isWaiting10x10(status) {
  return new RegExp(STATUS_W_10X10, 'i').test(status);
}

export function isWaitingCandidate(status) {
  return new RegExp(STATUS_W_CANDIDATE, 'i').test(status);
}

export function isWaitingEmployer(status) {
  return new RegExp(STATUS_W_EMPLOYER, 'i').test(status);
}

export function isWaitingDate(status) {
  return [
    STATUS_W_SCREEN,
    STATUS_W_ONSITE,
    STATUS_W_GUARANTEE,
    STATUS_W_START_DATE
  ].includes(status);
}

export function isWaitingInterview(status) {
  return [
    STATUS_W_SCREEN,
    STATUS_W_ONSITE
  ].includes(status);
}

export function isOnHold(status) {
  return /h -/i.test(status);
}

export function isEnded(status) {
  return /e -/i.test(status);
}

export function isEndedOrOnHold(status) {
  return /(e|h) -/i.test(status);
}
