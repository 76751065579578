
import { isBoolean } from 'lodash';
import {
  isString
} from '../../../lib/String.lib';
import useState from '../../../lib/hooks/useState.hook';
import Menu from '../../Layout/Wrappers/Menu';
import NavLink from '../../Layout/Wrappers/NavLink';

export default function MoreOptionsMenu({ row = {}, context: { config }, overrideOptions }) {
  const [{ open = false }, _updateState] = useState();
  const { moreMenuOptions: options = [] } = config;
  const _options = overrideOptions || options;
  const { original: model = {} } = row;
  const _onClick = async (event) => await _updateState({ open: true })
  const _onClose = (method = () => null) => async (event) => {
    await _updateState({ open: false });
    method(model);
  };
  return (
    <>
      <Menu icon='more_vert'
        open={open}
        onClick={_onClick}
        onClose={_onClose()}
        paperStyle={{
          maxHeight: '65vh',
          minWidth: 360
        }}
      >
        {!!_options.length && _options.map(({
          acl = model => true,
          title,
          label,
          render,
          to,
          target = engagement => '',
          action = model => { }
        }, index) => {
          const key = `more-options-menu-option-${label}-${index}`;
          label = isString(label) ? (label) : label instanceof Function ? label(model) : label;
          return (
            (
              acl instanceof Function
                ? acl(model)
                : isBoolean(acl)
                  ? acl
                  : true
            ) && (
              render
                ?
                render(model, { key, onClick: _onClose(action) })
                :
                (to instanceof Function)
                  ? (
                    <NavLink menu noSelected
                      key={key}
                      title={title}
                      to={to(model)}
                      target={target(model)}
                      onClick={_onClose(action)}
                    >
                      {label}
                    </NavLink>
                  )
                  : (
                    <Menu.Item
                      key={key}
                      title={title}
                      onClick={_onClose(action)}
                      divider
                    >
                      {label}
                    </Menu.Item>
                  )
            )
          );
        })}
      </Menu>
    </>
  );
}
