import EmployerPendingsUI from '../../../../dictionaries/EmployerPendingsUI.dic';
import globalErrorHandler from '../../../../lib/Error/globalErrorHandler.fun';
import {
  copyString
} from '../../../../lib/GenericTools.lib';
import {
  getEmployerPendingsURL
} from '../../../../lib/services/Accounts/Session.lib';
import {
  trim
} from '../../../../lib/String.lib';

export async function copyUrlEmployerPendings({
  accountId
}) {
  try {
    const URL = await getEmployerPendingsURL({ accountId });
    if (trim(URL)) { copyString(URL); }
    else {
      throw new Error(
        EmployerPendingsUI.urlGeneratorDialog.unexpectedError
      );
    }
  }
  catch (error) {
    globalErrorHandler(error);
  }
}
