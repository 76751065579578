import moment from 'moment';
import {
  ENGAGEMENT__STATE_CLOSED,
  STAGE_CONFIRMATION,
  STAGE_REVIEW
} from '../../../dictionaries/Engagement.dic';
import EngagementUI from '../../../dictionaries/EngagementUI.dic';
import {
  US_DATE_FORMAT
} from '../../../lib/Constants';
import Core from '../../../lib/Core';
import {
  formatDateTime12hPT,
  getAgoText,
  getPacificTimeUSDateFormat,
  isValidDate
} from '../../../lib/Date.lib';
import {
  ACCOUNT_ACTION__EDIT_CANDIDATE,
  ACCOUNT_ACTION__EDIT_JOB,
  ACCOUNT_ACTION__VIEW_ENGAGEMENT
} from '../../../lib/Definition';
import {
  saveEmpAtsUrl
} from '../../../lib/Engagement';
import {
  NOT,
  getGravatarURL
} from '../../../lib/GenericTools.lib';
import {
  Str,
  compileText,
  trim
} from '../../../lib/String.lib';
import {
  mapAccount
} from '../../../lib/models/account';
import {
  getEmpAtsUrl,
  mapEngagement
} from '../../../lib/models/engagement';
import {
  COLLECTION__ACCOUNTS,
  getLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import { FieldsetLogFeedbackTo10x10 } from '../../Engagements/Fieldsets/FieldsetLogFeedbackTo10x10';
import { FieldsetLogFeedbackToRecruiter } from '../../Engagements/Fieldsets/FieldsetLogFeedbackToRecruiter';
import MoreOptionsMenu from '../../Home/3DotsMenu/MoreOptionsMenu';
import { moreMenuOptionsEngagements } from '../../Home/3DotsMenu/moreMenuOptionsEngagements';
import {
  REACT_TABLE__COLUMN_MD,
  REACT_TABLE__COLUMN_XXS
} from '../../Home/useEnhancedReactTable.hook';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import {
  EditableLink
} from '../../Layout/Wrappers/EditableLink';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import {
  WarningChipMessage
} from '../../Layout/Wrappers/Message';
import NavLink from '../../Layout/Wrappers/NavLink';
import NavigationTabs from '../../Layout/Wrappers/NavTabs';
import Paper from '../../Layout/Wrappers/Paper';
import StyledTooltip, {
  PLACEMENT__BOTTOM_END
} from '../../Layout/Wrappers/StyledTooltip';
import {
  getMatchInfoString
} from '../../Match/Libraries/Match.lib';
import {
  EmployerPendings_updateState
} from './EmployerPendings';
import {
  FeedbackInput
} from './FeedbackInput';
import {
  EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK,
} from './Libraries/EmployerPendings.dic';
import {
  removeContactInfoFromSubmissionNote,
  sendViewCandidateEmail
} from './Libraries/EmployerPendings.lib';
import {
  MessageEmployerToCandidate
} from './MessageEmployerToCandidate';
import NavTabFeedbackTo10x10 from './NavTabFeedbackTo10x10';
import {
  PendingCard
} from './PendingCard';

export function EmployerPendingsMiddlePanel(props) {
  let {
    employer,
    selected,
    textAreaRef,
    feedback,
    feedbacks,
    state
  } = props;
  if (!selected || !selected.id) { return null; }
  let { candidate } = selected;

  async function _fetchRecruiter({ recruiterId }) {
    selected.fetchingRecruiter = true;
    selected.fetchingRecruiterDone = false;
    selected.recruiter = await getLoopbackRecord({
      collection: COLLECTION__ACCOUNTS,
      where: { id: recruiterId },
      fields: ['id', 'firstName', 'lastName'],
      limit: 1,
      mapper: mapAccount
    });
    selected.fetchingRecruiter = false;
    selected.fetchingRecruiterDone = true;
    await EmployerPendings_updateState({ selected });
  }
  let recruiterId = selected.recruiterId;
  let recruiter = selected.recruiter || {};
  if (
    Core.isAdmin() &&
    !!recruiterId &&
    !recruiter.id &&
    !selected.fetchingRecruiter
  ) {
    _fetchRecruiter({ recruiterId });
  }

  (async () => {
    // IF: user is EmployerRecruiter OR fetching recruiter done
    if (Core.isEmployer() || !!selected.fetchingRecruiterDone) {

      // CASE: first render
      if (!Core.getKeyValue('employer_pendings_middle_panel_first_render')) {
        Core.setKeyValue('employer_pendings_middle_panel_first_render', true);
        sendViewCandidateEmail({ ...props, firstRender: true });
      }

      // CASE: user viewing a candidate
      if (
        // IF: is on Review stage
        selected.stage && (selected.stage === STAGE_REVIEW) &&
        // AND: no previous render OR it was less than 10 minutes
        (
          !selected.renderedMiddlePanel ||
          moment().diff(selected.renderedMiddlePanel, 'minutes') >= 10
        )
      ) {
        selected.renderedMiddlePanel = new Date().toISOString();
        await EmployerPendings_updateState({ selected });
        if (Core.isEmployer()) {
          sendViewCandidateEmail(props);
        }
        /** @todo THIS IS A POC | 2022-10-03 | µ */
        /** * /
        await Http.post(Core.getApi('Events'), {
          source: 'api',
          eventName: 'EMPLOYER_PENDINGS__VIEW_CANDIDATE',
          context: {
            userName: Core.getUserName(),
            candidateName: selected.candidateName,
            employerName: selected.employerName,
            jobTitle: selected.jobTitle,
            state: selected.state,
            stage: selected.stage,
            status: selected.status,
            submitted: selected.submitted,
            candidateId: selected.candidateId,
            employerId: selected.employerId,
            jobId: selected.jobId,
          }
        }).catch(Core.showError);
        /** */
      }
    }
  })();
  console.debug('EmployerPendingsMiddlePanel:selected', selected);
  return (
    <div className={`flex-1 p-05 ${state.hideMiddle ? 'd-none' : ''}`.trim()} style={{ height: 'calc(100vh - 100px)', minWidth: 640 }}>
      <Paper className='p-1 h-100 flex-col'>
        <PendingCard
          model={{
            image: !selected.ratingFlag ? getGravatarURL(candidate.email) : undefined,
            abbr: (
              selected.ratingFlag === '*'
                ? '⭐'
                : selected.ratingFlag === '**'
                  ? '🔥'
                  : candidate._abbr
            ),
            title: (
              <div
                aria-label='emp_pen__mid_pan__title_section'
                className='d-flex flex-align-left-bottom'
                style={{ maxWidth: 740 }}
              >
                <div
                  aria-label='emp_pen__mid_pan__candidate_name'
                  className='mr-1 truncate'
                  style={{ maxWidth: 250 }}
                >
                  {Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE }) ? (
                    <NavLink to={`/candidate/edit/${candidate.id}`} target='_blank'>
                      {candidate._name}
                    </NavLink>
                  ) : (
                    candidate._name
                  )}
                </div>
                <div
                  aria-label='emp_pen__mid_pan__process_label'
                  className='c-red f-sm fw-500 mr-auto'
                >
                  {(selected.engagementKey === EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK)
                    ? (
                      <WarningChipMessage>
                        Calibration only - Do not contact
                      </WarningChipMessage>
                    ) : (
                      Str(selected.processLabel).replace(/ \(.*/, '')
                    )}
                </div>
                {Core.isAdmin() && (
                  <EditableLink
                    aria-label='emp_pen__mid_pan__editable_link'
                    className='f-sm flex-align-right-bottom w-40 c-black-medium'
                    style={{ maxWidth: 330 }}
                    value={getEmpAtsUrl(selected)}
                    onChange={async value => {
                      await saveEmpAtsUrl(selected)(value);
                      await EmployerPendings_updateState({ __renderedAt: Date.now() });
                    }}
                  >
                    ATS URL
                  </EditableLink>
                )}
              </div>
            ),
            customSubtitle: (
              <div aria-label='emp_pen__mid_pan__custom_subtitle_section'>
                <div className='d-flex flex-align-left-bottom flex-wrap'>
                  <div className='mr-1'>
                    {Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_JOB }) ? (
                      <NavLink to={`/job/edit/${selected.jobId}`} target='_blank'>
                        {selected.jobTitle}
                      </NavLink>
                    ) : (
                      selected.jobTitle
                    )}
                  </div>
                  <div className='f-sm c-black-medium min-w-120'>
                    {selected.submitted ? `Submitted on ${moment(selected.submitted).format(US_DATE_FORMAT)}` : ''}
                    {Core.isAdmin() && getMatchInfoString({ engagement: selected, prefix: ' - ' })}
                  </div>
                </div>
                {Core.isAdmin({ action: ACCOUNT_ACTION__VIEW_ENGAGEMENT }) && (
                  <Box row className='f-sm c-black-medium flex-wrap'>
                    {recruiter._name}&nbsp;|&nbsp;
                    {selected.stage}&nbsp;-&nbsp;
                    {selected.status}
                    <Button flat
                      endIcon={<i className='material-icons c-inherit'>open_in_new</i>}
                      onClick={(event) => Core.openPopUp(`/#/engagement/view/${selected.id}`, 1200)}
                      className='tt-unset c-black-medium c-purple-hover px-2 ml-auto'
                      small
                    >
                      View engagement
                    </Button>
                    <MoreOptionsMenu
                      row={{ original: mapEngagement({ ...selected }) }}
                      context={{
                        config: { moreMenuOptions: moreMenuOptionsEngagements }
                      }}
                    />
                  </Box>
                )}
              </div>
            )
          }}
          large
        />
        <div className='scroll'>
          <FeedbackInput
            textAreaRef={textAreaRef}
            selected={selected}
            recruiter={recruiter}
            employer={employer}
            feedback={feedback}
            feedbacks={feedbacks}
            state={state}
          />
          <div className='mt-3' />
          <NavigationTabs
            data={[
              {
                title: 'Notes',
                content: (
                  <CandidateProfile selected={selected} />
                )
              },
              {
                title: 'Email',
                content: (
                  <MessageEmployerToCandidate context={props} />
                )
              },
              isValidDate(selected.submitted) && NOT([STAGE_CONFIRMATION].includes(selected.stage)) && {
                title: 'Activity',
                content: (
                  <div>
                    {(
                      [
                        { label: 'Submitted', value: selected.submitted },
                        { label: 'Reviewed', value: selected.reviewed },
                        { label: 'Screen 1', value: selected.screen1 },
                        { label: 'Screen 2', value: selected.screen2 },
                        { label: 'Screen 3', value: selected.screen3 },
                        {
                          label: EngagementUI.homeworkAssigned.label,
                          fullLabel: EngagementUI.homeworkAssigned.fullLabel,
                          value: selected.homeworkAssigned,
                        },
                        {
                          label: EngagementUI.homeworkCompleted.label,
                          fullLabel: EngagementUI.homeworkAssigned.fullLabel,
                          value: selected.homeworkCompleted
                        },
                        { label: 'Onsite 1', value: selected.onsite1 },
                        { label: 'Onsite 2', value: selected.onsite2 },
                        {
                          label: 'Archived',
                          value: (
                            (selected.state === ENGAGEMENT__STATE_CLOSED) &&
                            (selected.closed || selected.statusUpdatedDate)
                          )
                        }
                      ].sort(
                        (a, b) => new Date(b.value) - new Date(a.value)
                      ).map(props => (
                        <ActivityRow
                          key={`employer_pendings_middle_panel__navigation_tabs__${props.label}`}
                          {...props}
                        />
                      ))
                    )}
                  </div>
                )
              },
              {
                title: 'Job feedback',
                content: (
                  <NavTabFeedbackTo10x10
                    jobId={selected.jobId}
                  />
                )
              },
              Core.isAdmin() && {
                title: 'To Recruiter FB',
                content: (
                  <FieldsetLogFeedbackToRecruiter
                    engagement={selected}
                  />
                )
              }
            ].filter(v => !!v)}
            reset={selected.id}
          />
        </div>
      </Paper>
    </div>
  );
}

function CandidateProfile({ selected }) {
  const submissionNote = removeContactInfoFromSubmissionNote(selected.submissionNote);
  console.debug('CandidateProfile', submissionNote);
  return (
    <>
      <FieldsetLogFeedbackTo10x10
        engagement={selected}
        readOnly={!Core.isAdmin()}
      />
      {!!trim(selected.submissionNote) && (
        <Fieldset
          title='Profile'
          className='p-0'
          fullWidth
        >
          <div
            className='c-black-medium rounded f-md p-2 py-1 custom-html'
            dangerouslySetInnerHTML={{ __html: submissionNote }}
          />
        </Fieldset>
      )}
    </>
  );
}

function ActivityRow({ label = '', value = '', fullLabel = '' }) {
  return isValidDate(value) && (
    <StyledTooltip
      title={(
        compileText('{{LABEL}}: {{DATE}}', {
          LABEL: fullLabel || label,
          DATE: formatDateTime12hPT(value)
        })
      )}
      placement={PLACEMENT__BOTTOM_END}
      className='d-flex flex-align-left-bottom hover-bg rounded-sm p-1'
    >
      <div className='f-sm fw-500 mr-1' style={{ width: REACT_TABLE__COLUMN_XXS }}>
        {label}:
      </div>
      <div className='f-sm mr-1' style={{ width: REACT_TABLE__COLUMN_MD }}>
        {getPacificTimeUSDateFormat(value)}&nbsp;
        ({getAgoText(value)})
      </div>
    </StyledTooltip>
  );
}
