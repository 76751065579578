import moment from 'moment';
import AppUI from '../../../dictionaries/AppUI.dic';
import {
  NOT
} from '../../../lib/Boolean.lib';
import {
  US_DATE_FORMAT
} from '../../../lib/Constants';
import Core from '../../../lib/Core';
import Definition, {
  STATE_ACTIVE
} from '../../../lib/Definition';
import Engagement from '../../../lib/Engagement';
import {
  Fun
} from '../../../lib/Function.lib';
import useStateObserved from '../../../lib/hooks/useStateObserved.hook';
import {
  getJob
} from '../../../lib/Job';
import {
  showConfirm
} from '../../Dialogs/AppConfirmationDialog';
import {
  ACTION_TYPE__UPDATE
} from '../../Employers/EmployerPendings/Libraries/EmployerPendings.dic';
import prependLogEngagementFeedbackTo10x10 from '../../Employers/EmployerPendings/Libraries/prependLogEngagementFeedbackTo10x10.fun';
import {
  joinClassName
} from '../../Layout/Libraries/Theme.lib';
import Box from '../../Layout/Wrappers/Box';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import Menu from '../../Layout/Wrappers/Menu';
import {
  ErrorMessage,
  LoadingMessage
} from '../../Layout/Wrappers/Message';
import {
  PLACEMENT__RIGHT
} from '../../Layout/Wrappers/StyledTooltip';
import Typography from '../../Layout/Wrappers/Typography';
import {
  Dash
} from '../../Shared/Dash';
import {
  EngagementsController
} from '../Engagements';

export default function openEngagementJobDialog({ engagement }) {
  const prevJobId = String(engagement.jobId);
  const prevJobTitle = String(engagement.job.jobTitle);
  showConfirm({
    title: (
      <>
        Engagement:
        <Typography sub bold blackDark
          className='ml-1'
        >
          Reassign to another job
        </Typography>
      </>
    ),
    content: (
      <EngagementJobFieldset
        engagement={engagement}
        prevJobId={prevJobId}
        prevJobTitle={prevJobTitle}
      />
    ),
    paperStyle: { minWidth: 640 },
    onAcceptLabel: 'Submit',
    async onAccept() {
      try {
        if (prevJobId !== engagement.jobId) {
          Object.assign(engagement,
            await Engagement.update(
              engagement,
              { jobId: engagement.jobId }
            )
          );
          await prependLogEngagementFeedbackTo10x10({
            engagementId: engagement.id,
            context: {
              ACTION: ACTION_TYPE__UPDATE,
              USER: Core.getUserName(),
              NOTE: `Job reassigned from "${prevJobTitle}" to "${engagement.job.jobTitle}"`
            }
          });
          Fun(EngagementsController().reloadData)();
        }
      }
      catch (error) {
        if (Core.isProduction()) {
          Core.showError(AppUI.unexpectedError.message, error);
        }
        else {
          Core.showError(error);
        }
      }
    },
  })
}

export function EngagementJobFieldset({
  engagement = {},
  prevJobId,
  prevJobTitle
}) {
  const [{
    error = false,
    busy = false,
    ready = false,
    options = [],
    jobId = null,
  }, updateState] = useStateObserved({
    observed: {
      jobId: engagement.jobId
    }
  });
  setTimeout(async () => {
    if (NOT(error || busy || ready)) {
      try {
        await updateState({ busy: true });
        await updateState({
          options: await getJob({
            where: {
              employerId: engagement.job.employerId
            },
            fields: [
              'id',
              'jobTitle',
              'state',
              'createdAt'
            ],
            order: 'state ASC'
          }),
          busy: false,
          ready: true,
        });
      }
      catch (err) {
        await updateState({
          busy: false,
          error,
        });
      }
    }
  });
  return (
    <>
      <LoadingMessage show={!!busy}>
        Fetching jobs...
      </LoadingMessage>
      <ErrorMessage show={!!error}>
        {error}
      </ErrorMessage>
      <Fieldset
        acl={!!ready}
        title='Current job'
        subtitle={
          <Typography sub className='pt-05'>
            {prevJobTitle} – ({prevJobId})
          </Typography>
        }
      />
      <Fieldset
        title='New assigned job'
      >
        <Menu dropdown avoidNullOption
          value={jobId}
          options={options}
          optionLabel='jobTitle'
          renderOption={
            ({ option: { id, jobTitle, state, createdAt } }) => (
              <Box row w100
                title={id}
                placement={PLACEMENT__RIGHT}
                className={joinClassName([
                  (prevJobId === id) && 'bg-cyan-lighter',
                  (jobId === id) && 'bg-yellow-light'
                ])}
                onClick={async (event) => {
                  const update = { jobId: id }
                  Object.assign(engagement, update);
                  await updateState(update);
                }}
              >
                {jobTitle}
                <Dash />
                <Typography sub
                  cyanDark={(state === STATE_ACTIVE)}
                  blackDark={(state !== STATE_ACTIVE)}
                >
                  {Definition.getLabel('state', state)}
                </Typography>
                {createdAt ? (
                  <Typography sub blackDark>
                    <Dash />
                    {moment(createdAt).format(US_DATE_FORMAT)}
                  </Typography>
                ) : ''}
              </Box>
            )
          }
        />
      </Fieldset>
    </>
  )
}
