import {
  getHTMLLinkString
} from '../../../../lib/HTML.lib';
import {
  getEmployerPendingsURL
} from '../../../../lib/services/Accounts/Session.lib';

export default async function getHtmlLinkEmployerPendings({
  accountId
}) {
  if (!accountId) { return ''; }
  return getHTMLLinkString({
    url: await getEmployerPendingsURL({ accountId }),
    name: 'Employer Pendings'
  });
}
