import {
  Collapse
} from '@mui/material';
import {
  MDASH
} from '../../../lib/Constants';
import useState from '../../../lib/hooks/useState.hook';
import {
  mapWrapper
} from '../Libraries/Theme.lib';
import Card from './Card';
import Divider from './Divider';
import Icon from './Icon';
import List from './List';

export default function ExpandableCard({
  children = MDASH,
  cardTitle = MDASH,
  cardTitleOnOpen = undefined,
  cardContent = children,
  wrapperProps = {},
  unmountOnExit = true,
  ...props
}) {
  const [state, updateState] = useState();
  const _toggleCollapse = (key) => async (event) => {
    await updateState({ [key]: !state[key] });
  };
  return (
    <Card
      {...mapWrapper({
        role: 'ExpandableCard',
        props: wrapperProps,
        assign: props
      })
      }
    >
      <List className='m-0 p-0'>
        <List.Item button onClick={_toggleCollapse('open')}>
          <Icon icon='toc' className='mr-1' />
          <List.Item.Text>
            {cardTitleOnOpen && state.open ? cardTitleOnOpen : cardTitle}
          </List.Item.Text>
          <Icon
            icon={!!state.open ? 'expand_less' : 'expand_more'}
            className='ml-1'
          />
        </List.Item>
        <Collapse
          in={!!state.open}
          timeout="auto"
          unmountOnExit={unmountOnExit}
        >
          <Divider />
          {cardContent}
        </Collapse>
      </List>
    </Card>
  )
}
