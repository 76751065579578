import {
  Input
} from 'antd';
import {
  uniqueId
} from 'lodash';
import {
  join
} from '../../../lib/Array.lib';
import useState from '../../../lib/hooks/useState.hook';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  convertHtmlToPlainText
} from '../../../lib/String.lib';
import Box from '../../Layout/Wrappers/Box';
import Col from '../../Layout/Wrappers/Col';
import Divider from '../../Layout/Wrappers/Divider';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import {
  Html
} from '../../Layout/Wrappers/Html';
import Icon from '../../Layout/Wrappers/Icon';
import StyledRadio from '../../Layout/Wrappers/StyledRadio';
import {
  PLACEMENT__BOTTOM_START
} from '../../Layout/Wrappers/StyledTooltip';
import Switch from '../../Layout/Wrappers/Switch';
import Typography from '../../Layout/Wrappers/Typography';
import {
  generateSingleHaveHTMLString,
  HAVE__OPTIONS
} from './v2/Generators.lib';

const HAVES_EDIT_KEY = 'match__haves__haves_edit';

export default function HavesEditor(props) {
  const { required, desirable, reference = () => null } = props;
  reference({ values: () => ({ required, desirable }) });
  const _mapHave = (have) => (
    <SingleHave
      have={have}
      key={uniqueId(HAVES_EDIT_KEY)}
    />
  );
  return (
    <Col className='p-0' fullWidth>

      <Fieldset column
        title='Required'
        wrapperProps={{ className: 'p-0', fullWidth: true }}
      >
        {required.not.map(_mapHave)}
        {required.yes.map(_mapHave)}
        {required.maybe.map(_mapHave)}
      </Fieldset>

      <Fieldset column
        title='Desirable'
        wrapperProps={{ className: 'p-0', fullWidth: true }}
      >
        {desirable.not.map(_mapHave)}
        {desirable.yes.map(_mapHave)}
        {desirable.maybe.map(_mapHave)}
      </Fieldset>

    </Col>
  )
}

export function HaveIcon({
  info,
  option,
  ...props
}) {
  return (
    <Icon
      {...props}
      icon={option.icon}
      color={option.color}
    />
  );
}

export function SingleHave({ have }) {
  const [{ showCommentInput }, _updateState] = useState({});
  const _toggleComment = async (event) => _updateState({
    showCommentInput: !showCommentInput
  });
  const htmlValue = generateSingleHaveHTMLString({
    have,
    icon: false
  });
  const renderHtmlElement = ({ className, style }) => (
    <Html
      value={htmlValue}
      className={join(['flex-column', className], ' ')}
      style={style}
    />
  );

  const textLength = convertHtmlToPlainText(htmlValue).length;

  const originalOption = Obj(
    HAVE__OPTIONS.find(
      (option) => (option.id === have.originalMet)
    )
  );

  const selectedOption = Obj(
    HAVE__OPTIONS.find(
      (option) => (option.id === have.met)
    )
  );

  return (
    <>
      <Box row noWrap
        className='px-1 mt-1'
      >

        <Switch
          checked={!have.ignore}
          onChange={async () => {
            const update = { ignore: !have.ignore };
            Object.assign(have, update);
            await _updateState();
          }}
        />

        <StyledRadio
          options={HAVE__OPTIONS}
          value={have.met}
          onChange={async (value) => {
            have.originalMet = have.originalMet || have.met;
            have.ignore = false;
            have.met = value;
            console.debug('CHANGE', value, have);
            await _updateState();
          }}
          renderOption={(option) => (
            <HaveIcon medium option={option} />
          )}
          className='mr-2'
        />

        <HaveIcon
          title={(
            (
              originalOption.id && (selectedOption.id !== originalOption.id)
            ) ? (
              <Box>
                {selectedOption.info}<br />
                <Typography sub italic className='c-yellow'>
                  Original: {originalOption.info}
                </Typography>
              </Box>
            ) : (
              selectedOption.info
            )
          )}
          placement={PLACEMENT__BOTTOM_START}
          option={selectedOption}
          className='mr-2'
        />

        <Box row flex1 noWrap scrollX
          onClick={_toggleComment}
        >
          {
            (
              showCommentInput
            ) ? (
              <Input
                autoFocus
                className='mb-1'
                placeholder='Type a comment'
                value={have.comment}
                onChange={async (event) => {
                  have.comment = event.target.value;
                  await _updateState();
                }}
                onPressEnter={_toggleComment}
                onBlur={_toggleComment}
              />
            ) : (
              <Box row w100 wrap
                title={(textLength >= 133) && (
                  <div className='p-1 pb-2' style={{ width: sizes.m7 }}>
                    {renderHtmlElement({ className: 'c-white-all' })}
                  </div>
                )}
                className='pointer'
              >
                {renderHtmlElement({ className: 'nowrap' })}
              </Box>
            )
          }
        </Box>
      </Box>
      <Divider />
    </>
  );
}
