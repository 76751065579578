import {
  Input
} from 'antd';
import EngagementUI from '../../../dictionaries/EngagementUI.dic';
import Core from '../../../lib/Core';
import Engagement from '../../../lib/Engagement';
import {
  NOT
} from '../../../lib/GenericTools.lib';
import useState from '../../../lib/hooks/useState.hook';
import {
  trim
} from '../../../lib/String.lib';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import {
  Html
} from '../../Layout/Wrappers/Html';


export function FieldsetLogFeedbackToRecruiter({
  engagement = {},
  title = EngagementUI.toRecruiterFBText.title,
  onChange = async (update) => null,
  autoSave = true,
  disabled = false,
  readOnly = false,
  ...props
}) {
  const [_state, _updateState] = useState();
  const _value = (
    _state.toRecruiterFBText !== undefined
      ? _state.toRecruiterFBText
      : engagement.toRecruiterFBText
  );
  const _trimValue = trim(_value);
  const _onDone = async (event) => {
    try {
      if (_trimValue === engagement.toRecruiterFBText) { return; }
      const _update = { toRecruiterFBText: _trimValue };
      Object.assign(engagement, _update);
      await onChange(_update);
      if (NOT(autoSave)) { return; }
      await Engagement.update(engagement, _update);
    }
    catch (error) {
      Core.showError(error);
    }
  }
  console.debug('FieldsetLogFeedbackToRecruiter', engagement);
  return (NOT(readOnly) || (readOnly && _trimValue)) && (
    <Fieldset
      wrapperProps={{ className: 'p-0' }}
      {...props}
      title={title}
    >
      {readOnly
        ? (
          <div
            className='scroll rounded border-1 p-1'
            style={{ maxHeight: 128 }}
          >
            <Html value={engagement.toRecruiterFBText} />
          </div>
        ) : (
          <Input.TextArea
            placeholder={EngagementUI.toRecruiterFBText.placeholder}
            value={_value}
            onChange={(event) => _updateState({
              toRecruiterFBText: event.target.value
            })}
            onBlur={_onDone}
            onPressEnter={_onDone}
            autoSize={{ minRows: 1, maxRows: 12 }}
            disabled={disabled}
          />
        )}

    </Fieldset>
  );
}
