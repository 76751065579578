import {
  Str,
  trim
} from '../../../lib/String.lib';
import Icon from './Icon';

export default function IconStatus({
  loading = false,
  loadingTitle = 'Loading...',
  error = false,
  ...props
}) {
  return (
    (loading || error) &&
    (
      <Icon outlined
        icon={loading ? 'hourglass_empty' : 'error'}
        title={
          trim(Str(error).split(':').pop()) ||
          (loading && loadingTitle)
        }
        className='icon16'
      />
    )
  );
}
