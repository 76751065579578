import {
  join
} from '../lib/Array.lib';

// STATES
export const ENGAGEMENT__STATE_CLOSED = 'Closed';
export const ENGAGEMENT__STATE_OPEN = 'Open';

// STAGES
export const STAGE_PENDING = "Pending";
export const STAGE_CONFIRMATION = "Confirmation";
export const STAGE_SUBMISSION = "Submission";
export const STAGE_REVIEW = "Review";
export const STAGE_SCREEN = "Screen";
export const STAGE_ONSITE = "Onsite";
export const STAGE_OFFER = "Offer";
export const STAGE_HIRE = "Hire";
export const STAGE_GUARANTEE = "Guarantee";
export const STAGE_PAID = "Paid";
export const STAGE_END = "End";

// WAITING 10x10 STATUS
export const STATUS_W_10X10 = "W - 10x10";
export const STATUS_W_10X10_ASK_PERMISSION = "W - 10x10 Ask Permission";
export const STATUS_W_10X10_NOTIFY_REJECTION = "W - 10x10 Notify Rejection";
export const STATUS_W_10X10_REVIEW = "W - 10x10 Review";
export const STATUS_W_10X10_SCHEDULE = "W - 10x10 Schedule";
export const STATUS_W_10X10_SUBMISSION = "W - 10x10 Submission";
export const STATUS_W_10X10_CALIBRATION = "W - 10x10 Calibration";

// WAITING CANDIDATE STATUS
export const STATUS_W_CANDIDATE = "W - Candidate";
export const STATUS_W_CANDIDATE_PERMISSION = "W - Candidate Permission";
export const STATUS_W_CANDIDATE_SCHEDULE = "W - Candidate Schedule";
export const STATUS_W_CANDIDATE_SUBMIT_FORM = "W - Candidate Submit Form";
export const STATUS_W_CANDIDATE_SUBMIT_HOMEWORK = "W - Candidate Submit HW";

// WAITING EMPLOYER STATUS
export const STATUS_W_EMPLOYER = "W - Employer";
export const STATUS_W_EMPLOYER_FEEDBACK = "W - Employer Feedback";
export const STATUS_W_EMPLOYER_FORM_FEEDBACK = "W - Employer Form Feedback";
export const STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK = "W - Employer HW Feedback";
export const STATUS_W_EMPLOYER_SEND_FORM = "W - Employer Send Form";
export const STATUS_W_EMPLOYER_SEND_HOMEWORK = "W - Employer Send HW";
export const STATUS_W_EMPLOYER_SCHEDULE = "W - Employer Schedule";

// WAITING EVENT STATUS
export const STATUS_W_GUARANTEE = "W - Guarantee";
export const STATUS_W_ONSITE = "W - Onsite";
export const STATUS_W_SCREEN = "W - Screen";
export const STATUS_W_START_DATE = "W - Start Date";

// HOLD STATUS
export const STATUS_H_10X10 = "H - 10x10";
export const STATUS_H_CANDIDATE = "H - Candidate";
export const STATUS_H_EMPLOYER = "H - Employer";

// EXIT STATUS
export const STATUS_E_10X10 = "E - 10x10";
export const STATUS_E_CANDIDATE = "E - Candidate";
export const STATUS_E_EMPLOYER = "E - Employer";
export const STATUS_E_RECRUITER = "E - Recruiter";
export const STATUS_E_SUCCESS = "E - Success";


// 10X10 REJECTION REASON
export const REJECTION_REASON__10X10__BAD_MATCH = '10x10 - Bad Match';
export const REJECTION_REASON__10X10__NO_MATCH = '10x10 - No Match';

// CANDIDATE REJECTION REASON
export const REJECTION_REASON__CANDIDATE__CHOOSE_OTHER_OFFER = 'Candidate - Choose Other Offer';
export const REJECTION_REASON__CANDIDATE__MIA = 'Candidate - MIA';
export const REJECTION_REASON__CANDIDATE__NOT_INTERESTED = 'Candidate - Not Interested';
export const REJECTION_REASON__CANDIDATE__PLACED_BY_10X10 = 'Candidate - Placed by 10x10';
export const REJECTION_REASON__CANDIDATE__PLACED_BY_RECRUITER = 'Candidate - Placed by Recruiter';
export const REJECTION_REASON__CANDIDATE__MAYBE_LATER = 'Candidate - Maybe Later';

// EMPLOYER REJECTION REASON
export const REJECTION_REASON__EMPLOYER__NOT_INTERESTED = 'Employer - Not Interested';
export const REJECTION_REASON__EMPLOYER__PROJECT_COMPLEXITY = 'Employer - Project Complexity';
export const REJECTION_REASON__EMPLOYER__TECHNICAL = 'Employer - Tech Stack';
export const REJECTION_REASON__EMPLOYER__COMPANY_PEDIGREE = 'Employer - Company Pedigree';
export const REJECTION_REASON__EMPLOYER__COMPANY_SIZE = 'Employer - Company Size';
export const REJECTION_REASON__EMPLOYER__EDUCATION_CREDENTIALS = 'Employer - Education Credentials';
export const REJECTION_REASON__EMPLOYER__LEVEL_SENIORITY = 'Employer - Level Seniority';
export const REJECTION_REASON__EMPLOYER__JOB_HOPPER = 'Employer - Job Hopper Consulting';
export const REJECTION_REASON__EMPLOYER__HOMEWORK = 'Employer - Homework';
export const REJECTION_REASON__EMPLOYER__MIA = 'Employer - MIA';
export const REJECTION_REASON__EMPLOYER__MAYBE_LATER = 'Employer - Maybe Later';

// RECRUITER REJECTION REASON
export const REJECTION_REASON__RECRUITER__BAD_MATCH = 'Recruiter - Bad Match';
export const REJECTION_REASON__RECRUITER__CONFLICT_IN_10x10_SYSTEM = 'Recruiter - Conflict in 10x10 system';
export const REJECTION_REASON__RECRUITER__MIA = 'Recruiter - MIA';

export const ENGAGEMENT__STATES = [
  ENGAGEMENT__STATE_OPEN,
  ENGAGEMENT__STATE_CLOSED
]

export const ENGAGEMENT__ACTIVE_STAGES = [
  STAGE_CONFIRMATION,
  STAGE_SUBMISSION,
  STAGE_REVIEW,
  STAGE_SCREEN,
  STAGE_ONSITE,
  STAGE_OFFER,
  STAGE_GUARANTEE,
  STAGE_HIRE
];

export const ENGAGEMENT__ACTIVE_STATUSES = [
  // W - 10X10
  STATUS_W_10X10,
  STATUS_W_10X10_ASK_PERMISSION,
  STATUS_W_10X10_NOTIFY_REJECTION,
  STATUS_W_10X10_REVIEW,
  STATUS_W_10X10_SCHEDULE,
  STATUS_W_10X10_SUBMISSION,
  STATUS_W_10X10_CALIBRATION,
  // W - CANDIDATE
  STATUS_W_CANDIDATE,
  STATUS_W_CANDIDATE_PERMISSION,
  STATUS_W_CANDIDATE_SCHEDULE,
  STATUS_W_CANDIDATE_SUBMIT_FORM,
  STATUS_W_CANDIDATE_SUBMIT_HOMEWORK,
  // W - EMPLOYER
  STATUS_W_EMPLOYER,
  STATUS_W_EMPLOYER_FEEDBACK,
  STATUS_W_EMPLOYER_FORM_FEEDBACK,
  STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK,
  STATUS_W_EMPLOYER_SEND_FORM,
  STATUS_W_EMPLOYER_SEND_HOMEWORK,
  STATUS_W_EMPLOYER_SCHEDULE,
  // W - EVENT
  STATUS_W_GUARANTEE,
  STATUS_W_ONSITE,
  STATUS_W_SCREEN,
  STATUS_W_START_DATE,
  // ON HOLD
  STATUS_H_10X10,
  STATUS_H_CANDIDATE,
  STATUS_H_EMPLOYER,
  // ENDED
  STATUS_E_10X10,
  STATUS_E_CANDIDATE,
  STATUS_E_EMPLOYER,
  STATUS_E_RECRUITER
];

export const ENGAGEMENT__STAGE_STATUSES = {
  [STAGE_CONFIRMATION]: [
    // DEFAULT
    STATUS_W_10X10,
    // WAITING
    STATUS_W_10X10_ASK_PERMISSION,
    STATUS_W_10X10_CALIBRATION,
    STATUS_W_10X10_NOTIFY_REJECTION,
    STATUS_W_10X10_REVIEW,
    STATUS_W_CANDIDATE,
    STATUS_W_CANDIDATE_PERMISSION,
    STATUS_W_EMPLOYER,
    // ON HOLD
    STATUS_H_10X10,
    STATUS_H_CANDIDATE,
    STATUS_H_EMPLOYER,
    // ENDED
    STATUS_E_10X10,
    STATUS_E_CANDIDATE,
    STATUS_E_EMPLOYER,
    STATUS_E_RECRUITER
  ],
  [STAGE_SUBMISSION]: [
    // DEFAULT
    STATUS_W_10X10_REVIEW,
    // WAITING
    STATUS_W_10X10,
    STATUS_W_10X10_NOTIFY_REJECTION,
    STATUS_W_10X10_SUBMISSION,
    STATUS_W_CANDIDATE,
    STATUS_W_EMPLOYER,
    // ON HOLD
    STATUS_H_10X10,
    STATUS_H_CANDIDATE,
    STATUS_H_EMPLOYER,
    // ENDED
    STATUS_E_10X10,
    STATUS_E_CANDIDATE,
    STATUS_E_EMPLOYER,
    STATUS_E_RECRUITER
  ],
  [STAGE_REVIEW]: [
    // DEFAULT
    STATUS_W_EMPLOYER_FEEDBACK,
    // WAITING
    STATUS_W_10X10,
    STATUS_W_10X10_NOTIFY_REJECTION,
    STATUS_W_CANDIDATE,
    STATUS_W_CANDIDATE_SUBMIT_FORM,
    STATUS_W_CANDIDATE_SUBMIT_HOMEWORK,
    STATUS_W_EMPLOYER,
    STATUS_W_EMPLOYER_FORM_FEEDBACK,
    STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK,
    STATUS_W_EMPLOYER_SEND_FORM,
    STATUS_W_EMPLOYER_SEND_HOMEWORK,
    // ON HOLD
    STATUS_H_10X10,
    STATUS_H_CANDIDATE,
    STATUS_H_EMPLOYER,
    // ENDED
    STATUS_E_10X10,
    STATUS_E_CANDIDATE,
    STATUS_E_EMPLOYER,
    STATUS_E_RECRUITER
  ],
  [STAGE_SCREEN]: [
    // DEFAULT
    STATUS_W_EMPLOYER_SCHEDULE,
    // WAITING
    STATUS_W_10X10,
    STATUS_W_10X10_NOTIFY_REJECTION,
    STATUS_W_10X10_SCHEDULE,
    STATUS_W_CANDIDATE,
    STATUS_W_CANDIDATE_SCHEDULE,
    STATUS_W_CANDIDATE_SUBMIT_FORM,
    STATUS_W_CANDIDATE_SUBMIT_HOMEWORK,
    STATUS_W_EMPLOYER,
    STATUS_W_EMPLOYER_FEEDBACK,
    STATUS_W_EMPLOYER_FORM_FEEDBACK,
    STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK,
    STATUS_W_EMPLOYER_SEND_FORM,
    STATUS_W_EMPLOYER_SEND_HOMEWORK,
    STATUS_W_SCREEN,
    // ON HOLD
    STATUS_H_10X10,
    STATUS_H_CANDIDATE,
    STATUS_H_EMPLOYER,
    // ENDED
    STATUS_E_10X10,
    STATUS_E_CANDIDATE,
    STATUS_E_EMPLOYER,
    STATUS_E_RECRUITER
  ],
  [STAGE_ONSITE]: [
    // DEFAULT
    STATUS_W_EMPLOYER_SCHEDULE,
    // WAITING
    STATUS_W_10X10,
    STATUS_W_10X10_NOTIFY_REJECTION,
    STATUS_W_10X10_SCHEDULE,
    STATUS_W_CANDIDATE,
    STATUS_W_CANDIDATE_SCHEDULE,
    STATUS_W_CANDIDATE_SUBMIT_HOMEWORK,
    STATUS_W_EMPLOYER,
    STATUS_W_EMPLOYER_FEEDBACK,
    STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK,
    STATUS_W_EMPLOYER_SEND_HOMEWORK,
    STATUS_W_ONSITE,
    // ON HOLD
    STATUS_H_10X10,
    STATUS_H_CANDIDATE,
    STATUS_H_EMPLOYER,
    // ENDED
    STATUS_E_10X10,
    STATUS_E_CANDIDATE,
    STATUS_E_EMPLOYER,
    STATUS_E_RECRUITER
  ],
  [STAGE_OFFER]: [
    // DEFAULT
    STATUS_W_EMPLOYER,
    // WAITING
    STATUS_W_10X10,
    STATUS_W_10X10_NOTIFY_REJECTION,
    STATUS_W_CANDIDATE,
    // ON HOLD
    STATUS_H_10X10,
    STATUS_H_CANDIDATE,
    STATUS_H_EMPLOYER,
    // ENDED
    STATUS_E_10X10,
    STATUS_E_CANDIDATE,
    STATUS_E_EMPLOYER,
    STATUS_E_RECRUITER
  ],
  [STAGE_HIRE]: [
    // DEFAULT
    STATUS_W_EMPLOYER,
    // WAITING
    STATUS_W_10X10,
    STATUS_W_CANDIDATE,
    STATUS_W_START_DATE,
    // ON HOLD
    STATUS_H_10X10,
    STATUS_H_CANDIDATE,
    STATUS_H_EMPLOYER,
    // ENDED
    STATUS_E_10X10,
    STATUS_E_CANDIDATE,
    STATUS_E_EMPLOYER,
    STATUS_E_RECRUITER
  ],
  [STAGE_GUARANTEE]: [
    // DEFAULT
    STATUS_W_EMPLOYER,
    // WAITING
    STATUS_W_10X10,
    STATUS_W_CANDIDATE,
    STATUS_W_GUARANTEE,
    // ON HOLD
    STATUS_H_10X10,
    STATUS_H_CANDIDATE,
    STATUS_H_EMPLOYER,
    // ENDED
    STATUS_E_10X10,
    STATUS_E_CANDIDATE,
    STATUS_E_EMPLOYER,
    STATUS_E_RECRUITER
  ]
};

export const ENGAGEMENT__MATCH_OPTIONS = [
  join([STAGE_SUBMISSION, STATUS_W_10X10_SUBMISSION]),
  join([STAGE_CONFIRMATION, STATUS_W_10X10_REVIEW]),
  join([STAGE_CONFIRMATION, STATUS_W_10X10_ASK_PERMISSION]),
  join([STAGE_CONFIRMATION, STATUS_W_10X10_CALIBRATION]),
  join([STAGE_CONFIRMATION, STATUS_W_10X10])
];

export const ENGAGEMENT__INFO_STATUSES = [
  STATUS_W_CANDIDATE,
  STATUS_W_CANDIDATE_SUBMIT_FORM,
  STATUS_W_CANDIDATE_SUBMIT_HOMEWORK,
  STATUS_W_EMPLOYER,
  STATUS_W_EMPLOYER_FEEDBACK,
  STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK
];

export const ENGAGEMENT__ACTIVE_REJECTION_REASONS = [
  // 10X10
  REJECTION_REASON__10X10__BAD_MATCH,
  REJECTION_REASON__10X10__NO_MATCH,
  // CANDIDATE
  REJECTION_REASON__CANDIDATE__CHOOSE_OTHER_OFFER,
  REJECTION_REASON__CANDIDATE__MIA,
  REJECTION_REASON__CANDIDATE__NOT_INTERESTED,
  REJECTION_REASON__CANDIDATE__PLACED_BY_10X10,
  REJECTION_REASON__CANDIDATE__PLACED_BY_RECRUITER,
  REJECTION_REASON__CANDIDATE__MAYBE_LATER,
  // EMPLOYER
  REJECTION_REASON__EMPLOYER__NOT_INTERESTED,
  REJECTION_REASON__EMPLOYER__PROJECT_COMPLEXITY,
  REJECTION_REASON__EMPLOYER__TECHNICAL,
  REJECTION_REASON__EMPLOYER__COMPANY_PEDIGREE,
  REJECTION_REASON__EMPLOYER__COMPANY_SIZE,
  REJECTION_REASON__EMPLOYER__EDUCATION_CREDENTIALS,
  REJECTION_REASON__EMPLOYER__LEVEL_SENIORITY,
  REJECTION_REASON__EMPLOYER__JOB_HOPPER,
  REJECTION_REASON__EMPLOYER__HOMEWORK,
  REJECTION_REASON__EMPLOYER__MIA,
  REJECTION_REASON__EMPLOYER__MAYBE_LATER,
  // RECRUITER
  REJECTION_REASON__RECRUITER__BAD_MATCH,
  REJECTION_REASON__RECRUITER__CONFLICT_IN_10x10_SYSTEM,
  REJECTION_REASON__RECRUITER__MIA,
];

export const ENGAGEMENT__EMPLOYER_REJECTION_REASON_ORDER = [
  REJECTION_REASON__EMPLOYER__NOT_INTERESTED,
  REJECTION_REASON__EMPLOYER__TECHNICAL,
  REJECTION_REASON__EMPLOYER__PROJECT_COMPLEXITY,
  REJECTION_REASON__EMPLOYER__HOMEWORK,
  REJECTION_REASON__EMPLOYER__COMPANY_PEDIGREE,
  REJECTION_REASON__EMPLOYER__COMPANY_SIZE,
  REJECTION_REASON__EMPLOYER__EDUCATION_CREDENTIALS,
  REJECTION_REASON__EMPLOYER__LEVEL_SENIORITY,
  REJECTION_REASON__EMPLOYER__JOB_HOPPER,
  REJECTION_REASON__EMPLOYER__MIA,
  REJECTION_REASON__EMPLOYER__MAYBE_LATER
];
