import moment from 'moment';
import AppUI from '../../../../dictionaries/AppUI.dic';
import {
  join
} from '../../../../lib/Array.lib';
import {
  PLAINTEXT__LOG__SEPARATOR
} from '../../../../lib/Constants';
import Core from '../../../../lib/Core';
import {
  DATE_FORMAT__DATETIME__US_24H,
  TIMEZONE__LA
} from '../../../../lib/Date.lib';
import Engagement from '../../../../lib/Engagement';
import {
  COLLECTION__ENGAGEMENTS,
  readLoopbackRecord
} from '../../../../lib/services/BE/loopback.api';
import {
  compileText,
  Str,
  trim
} from '../../../../lib/String.lib';
import {
  ACTION_TYPE__ACCEPT,
  ACTION_TYPE__ACCEPT_STRONG,
  ACTION_TYPE__ACCEPT_WEAK,
  ACTION_TYPE__REJECT,
  ACTION_TYPE__REJECT_STRONG,
  ACTION_TYPE__REJECT_WEAK
} from './EmployerPendings.dic';

/**
 *
 * @param {object} options
 * @param {string} options.engagementId
 * @param {object} options.context
 * @param {string} options.context.ACTION  [plaintext][required][option: ACTION_TYPE__ACCEPT | ACTION_TYPE__REJECT,...]
 * @param {string} options.context.USER  [plaintext][required]
 * @param {string} options.context.PRESETS  [plaintext][optional]
 * @param {string} options.context.NOTE  [plaintext][optional]
 * @returns {*} results  [object:engagement]
 */
export default async function prependLogEngagementFeedbackTo10x10(options) {
  console.debug('prependLogEngagementFeedbackTo10x10', options);
  try {
    const { engagementId, context } = options;
    const engagement = await readLoopbackRecord({
      collection: COLLECTION__ENGAGEMENTS,
      where: { id: engagementId },
      fields: [
        'id',
        'empFBText',
      ],
      limit: 1
    });
    const _isAccepted = [
      ACTION_TYPE__ACCEPT,
      ACTION_TYPE__ACCEPT_WEAK,
      ACTION_TYPE__ACCEPT_STRONG
    ].includes(context.ACTION);
    const _isRejected = [
      ACTION_TYPE__REJECT,
      ACTION_TYPE__REJECT_WEAK,
      ACTION_TYPE__REJECT_STRONG
    ].includes(context.ACTION);
    const _note = Str(context.NOTE).replace(/\n/g, ' ').trim();
    const _presets = trim(context.PRESETS);
    const _entry = compileText('{{ACTION}} - {{USER}} | {{DATE_TIME}}{{PRESETS}}{{NOTE}}', {
      ...context,
      DATE_TIME: `${(
        moment.tz(TIMEZONE__LA).format(DATE_FORMAT__DATETIME__US_24H)
      )} PT`,
      PRESETS: _presets ? (
        _isAccepted ? `\nNext step: ${_presets}`
          : _isRejected ? `\nReasons: ${_presets}` : ''
      ) : '',
      NOTE: _note ? `\nNote: ${_note}` : '',
    })
    const update = {
      empFBText: join(
        [_entry, trim(engagement.empFBText)],
        PLAINTEXT__LOG__SEPARATOR
      )
    };
    return await Engagement.update(engagement, update);
  }
  catch (error) {
    if (Core.isProduction()) {
      Core.showError(AppUI.unexpectedError.message, error);
    }
    else {
      Core.showError(error);
    }
    return 'prependEngagementFeedbackLog:NO entry added';
  }
}
