import {
  debounce
} from 'lodash';
import {
  NOT
} from '../../../lib/Boolean.lib';
import Core from '../../../lib/Core';
import useState from '../../../lib/hooks/useState.hook';
import {
  compileText,
  Str,
  trim
} from '../../../lib/String.lib';
import {
  mapWrapper
} from '../../Layout/Libraries/Theme.lib';
import Box from '../../Layout/Wrappers/Box';
import Icon from '../../Layout/Wrappers/Icon';
import Menu from '../../Layout/Wrappers/Menu';
import {
  fetchCandidateResumePdfFile,
  getLocalCandidateResumePdfUrl
} from './Libraries/CandidateResumePdf.lib';
import {
  fetchCandidateResumePdfHighlightsFile,
  getLocalUrlCandidateResumePdfHighlights
} from './Libraries/CandidateResumePdfHighlights.lib';
import getRemoteCandidateResumePdfUrl from './Libraries/getRemoteCandidateResumePdfUrl.fun';
import Ui3DotsMenu from '../../../dictionaries/Ui3DotsMenu.dic';
import { matchLocation } from '../../../lib/URL.lib';

export default function ActionComponentCandidateResumePdf({
  candidate = {},
  jobId = '',
  employerId = '',
  children,
  wrapperProps = {},
  ...props
}) {
  const [state, updateState] = useState();
  const {
    prev = {},
    busy = false,
    ready = false
  } = state;
  debounce(async () => {
    const url = getRemoteCandidateResumePdfUrl(candidate);
    if (NOT(url)) { return; }
    if (
      (url !== prev.url) ||
      (jobId !== prev.jobId) ||
      NOT(busy || ready)
    ) {
      await updateState({
        busy: true,
        prev: { url, jobId },
        loading: true,
        error: false,
        source: 'resume'
      });
      fetchCandidateResumePdfFile(
        candidate
      ).then(updateState);
      fetchCandidateResumePdfHighlightsFile(
        {
          candidate,
          jobId,
          employerId
        }
      ).then(
        () => updateState({ loading: false })
      ).catch(
        (error) => {
          updateState({ loading: false, error });
        }
      );
      await updateState({
        busy: false,
        ready: true
      });
    }
  })();
  const _addToNewWindow = (event) => {
    const localUrlCandidateResumePdf = (
      getLocalUrlCandidateResumePdfHighlights({
        candidate,
        jobId,
        employerId
      }) ||
      getLocalCandidateResumePdfUrl(candidate)
    );
    const newWindow = window.open(
      'about:blank',
      'new_window',
      'width=960,height=960,top=10,right=10,resizable=yes'
    );
    if (!newWindow) {
      Core.showError('Browser blocking popup, please allow and then retry, thanks');
      return;
    }
    newWindow.open();
    newWindow.document.write(`
      <html><head><title>New Window</title></head><body style="margin:0px;">
      <iframe
        title="action_component__candidate_resume_preview__iframe"
        src="${localUrlCandidateResumePdf}#view=fitH&toolbar=1"
        type="application/pdf"
        width="100%"
        height="100%"
      />
      </body>
    `);
    newWindow.document.write('</body>');
    newWindow.document.close();
  }
  const statusIcon = (
    (state.loading || state.error) &&
    (
      <Icon outlined
        icon={state.loading ? 'hourglass_empty' : 'error'}
        className='icon16'
        title={
          trim(Str(state.error).split(':').pop()) ||
          (state.loading && 'Loading...')
        }
      />
    )
  );
  mapWrapper({
    role: 'ActionComponentCandidateResumePdf',
    props: wrapperProps,
    assign: {
      ...props,
      onClick: _addToNewWindow,
      disabled: (state.loading || state.error)
    }
  });
  return (
    (
      children
    ) ? (
      <Box {...wrapperProps}>
        {statusIcon || children}
      </Box>
    ) : (
      <Menu.Item divider {...wrapperProps}>
        {compileText(Ui3DotsMenu.Candidate.viewResumeHighlights, {
          PREFIX: matchLocation(/\/(create|edit)/i) ? '' : Ui3DotsMenu.Candidate.PREFIX
        })}
        {statusIcon}
      </Menu.Item>
    )
  )
}
