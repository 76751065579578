import {
  Component
} from "react";
import Core from "../../../lib/Core";
import Definition, {
  ACCOUNT_ACTION__EDIT_EMPLOYER,
  ACCOUNT_ACTION__LIST_EMPLOYERS,
  ACCOUNT_ACTION__LIST_ENGAGEMENTS,
  STATE_ACTIVE, STATE_LEAD
} from "../../../lib/Definition";
import Employer from "../../../lib/Employer";
import HistoryLog from "../../../lib/HistoryLog";
import Job from "../../../lib/Job";
import Store from "../../../lib/Store";
import {
  getLocation,
  getParams
} from '../../../lib/URL.lib';
import {
  EMPLOYER__INTAKE_NOTE,
  getEmployerModel,
  mapEmployer
} from "../../../lib/models/employer";
import {
  openMessageEmailPreview
} from "../../../lib/services/Email/Email.lib";
import SuccessDialog from "../../Dialogs/Success";
import HistoryMenu from "../../HistoryMenu/HistoryMenu";
import MoreOptionsMenu from '../../Home/3DotsMenu/MoreOptionsMenu';
import {
  moreMenuOptionsEmployers
} from '../../Home/3DotsMenu/moreMenuOptionsEmployers';
import {
  THEME__COLOR__PRIMARY
} from '../../Layout/Libraries/Theme.lib';
import Page from '../../Layout/Page';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import IconButton from '../../Layout/Wrappers/IconButton';
import Navigate from '../../Layout/Wrappers/Navigate';
import Paper, {
  getDefaultPaperStyle
} from '../../Layout/Wrappers/Paper';
import RichTextBox from "../../Layout/Wrappers/RichTextBox";
import Snackbar from '../../Layout/Wrappers/Snackbar';
import Stepper, {
  stepperScrollToTop
} from "../../Layout/Wrappers/Stepper";
import AccessDenied from '../../Shared/AccessDenied';
import {
  EmployerJobsDropdown
} from '../EmployerJobsDropdown';
import {
  openEmployerPendings
} from '../EmployerPendings/Libraries/EmployerPendings.lib';
import {
  EmployerSettingsButton
} from '../EmployerSettings';
import EmployerUI from '../EmployerUI.dic';
import Basics from "./Steps/Basics";
import Contact from "./Steps/Contact";
import Engineering from "./Steps/Engineering";
import Process from "./Steps/Process";
import WhyUs from "./Steps/WhyUs";

const required = "This field is required";
const niceToHave = "Please enter to get better match";

class EmployerEdit extends Component {
  continue = 0;
  confirm = false;
  constructor() {
    super(...arguments);
    const { employerId } = getParams({ pattern: '/employer/edit/:employerId' });
    this.state = {
      ...getEmployerModel({ extended: true }),
      /** controller states */
      employers: [],
      snackbar: null,
      errorName: "",
      errorAddressCity: "",
      errorEmployerStage: "",
      errorEmployeeCount: "",
      step: 1,

      /** setting unknown as default value of remote */
      remote: Definition.getId("remote", "Unknown"),
      jobsCollection: [],
      updatedJobs: {},
    };
    Store.set("path", getLocation());
    /** GET EMPLOYERS LIST for the select box on basics */
    Employer.getList((employers) => this.setState({ employers }));
    /** LOAD EMPLOYER DATA to the current state */
    if (employerId) {
      Employer.get(employerId, (model) =>
        this.setState({
          ...model,
          /** @todo [ 2022-10-18 ][ MS: for future implementation ] */
          // __snapshot: snapshotState({ state: model, model: getEmployerModel() })
        }, (then) => {
          HistoryLog.set({ group: "employers", label: this.state._name });
          Core.log(this.state);
        })
      );
      Job.getWhere(
        {
          and: [
            { employerId },
            { state: { inq: [STATE_ACTIVE, STATE_LEAD] } }
          ],
        },
        (jobs) =>
          this.setState({
            jobsCollection: jobs,
          })
      );
    }
  }
  /** POST/UPDATE JOB */
  update = (success, final) => {
    if (this.isValid()) {
      const continueWith = (em) => {
        if (this.state.id) {
          Employer.update(this.state.id, this.state, success);
        } else {
          Employer.post(this.state, success);
        }
        const updatedJobIds = Object.keys(this.state.updatedJobs);
        const next = (em) => {
          if (!!updatedJobIds.length) {
            const job = this.state.updatedJobs[updatedJobIds.pop()];
            Job.update(job.id, job, Core.log);
            setTimeout(next);
          }
        };
        next();
      };
      if (!this.state.proxyName) {
        Employer.getAnonName((proxyName) =>
          this.setState({ proxyName }, (then) => continueWith())
        );
      } else {
        continueWith();
      }
    }
  };
  /** SAVE & NEXT BUTTON */
  submitThenGoNext = (ev) => {
    if (this.isValid()) {
      this.update((response) =>
        this.setState({ id: response.id }, (then) => Stepper.next())
      );
    }
  };
  /** BACK BUTTON */
  goBack = (ev) => this.cancel();
  /** CANCEL BUTTON */
  cancel = (ev) => Core.goBack(this.props);
  /** PREVIOUS BUTTON */
  previous = (ev) => {
    Stepper.previous();
  };
  /** NEXT BUTTON */
  next = (ev) => {
    if (this.state.step === 1 && !this.isValid()) {
      return;
    }
    Stepper.next();
  };
  /** DONE BUTTON */
  save = (ev) => {
    this.setState({ state: Definition.getId("state", "Draft") }, (then) =>
      this.submit()
    );
  };
  /**
   * SUBMIT; DONE BUTTON
   */
  submit = (ev) => {
    this.update((response) => {
      if (this.state.id) {
        this.successDialog.open("Employer has been successfully updated");
      } else {
        this.setState({ id: response.id }, (then) => {
          this.successDialog.open(
            "Employer has been successfully created"
          );
        });
      }
    }, true);
  };
  /**
   * VALIDATIONS
   */
  isValid = (event) => {
    const { employerId } = getParams({ pattern: '/employer/edit/:employerId' });
    /** NAME: required */
    if (String(this.state.name).trim().length < 1) {
      stepperScrollToTop();
      this.setState({
        snackbar: "Company Name is required",
        errorName: required,
      });
      return false;
    }
    const employer = this.state.employers.find(
      (employer) =>
        String(employer.name).toLowerCase() ===
        String(this.state.name).toLowerCase()
    );
    if (employer && employer.id !== employerId) {
      this.setState({
        snackbar: "Company Name should be unique",
        errorName: "Choose another name",
      });
      return false;
    }
    /** LOCATION: confirmation dialog */
    if (
      this.state.step === 5 &&
      !this.state.location &&
      this.confirm !== true
    ) {
      this.confirmDialog.open(
        "Location has not been entered, are you sure you want to activate the employer and make it visible to recruiters?"
      );
      return false;
    }
    /** CITY: nice to have */
    if (String(this.state.addressCity).trim().length < 1) {
      stepperScrollToTop();
      this.setState({
        snackbar: "City is empty",
        errorAddressCity: niceToHave,
      });
      if (this.continue === 0) {
        this.continue = 1;
      }
    }
    /** STAGE: nice to have */
    if (!this.state.stage) {
      stepperScrollToTop();
      this.setState({
        snackbar: "Stage is not selected",
        errorEmployerStage: niceToHave,
      });
      if (this.continue === 0) {
        this.continue = 1;
      }
    }
    /** EMPLOYEE COUNT: nice to have */
    if (!this.state.employeeCount) {
      stepperScrollToTop();
      this.setState({
        snackbar: "How many people works at the company?",
        errorEmployeeCount: niceToHave,
      });
      if (this.continue === 0) {
        this.continue = 1;
      }
    }
    /** NICE TO HAVE: preventing to advance just one time */
    if (this.continue === 1) {
      this.continue = 2;
      return false;
    }
    /** ALL TESTS PASSED */
    return true;
  };

  render() {
    if (Core.isLoggedOut()) {
      return <Navigate to="/login" />;
    }
    if (!Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_EMPLOYER })) {
      return <AccessDenied />;
    }

    const _headers = [
      "BASICS",
      "ENGINEERING",
      "WHY US",
      "CONTACT",
      "PROCESS",
    ];

    return (
      <Page
        title={
          (!!this.state.id ? "Edit " : "New ") +
          "Employer" +
          (!!this.state.name ? " - " + this.state.name : "")
        }
        contentRight={
          <Box role='EmployerEdit__AppBar__ContentRight' row noWrap alignRight
            className='flex-1'
          >

            <EmployerJobsDropdown
              employerId={this.state.id}
              className='mr-1'
            />

            <IconButton
              acl={Core.isAdmin({ action: ACCOUNT_ACTION__LIST_ENGAGEMENTS })}
              title='Open employer pendings page'
              onClick={(event) => openEmployerPendings({ employerId: this.state.id })}
              icon='thumbs_up_down'
            />

            <HistoryMenu />

            <IconButton
              title='Open a message box'
              onClick={ev => openMessageEmailPreview({ employerId: this.state.id })}
              icon='email'
            />

            <EmployerSettingsButton employerId={this.state.id} />

            {this.state.id && (
              <MoreOptionsMenu
                row={{ original: mapEmployer({ ...this.state }) }}
                context={{
                  config: { moreMenuOptions: moreMenuOptionsEmployers },
                }}
              />
            )}

          </Box>
        }
      >
        <Box row h100 w100
          className='flex-align-center-top'
        >

          <Box column h100 w100
            role='EmployerEdit__LeftSide'
            className='px-05'
            style={{ maxWidth: 800 }}
          >
            <Paper
              className='mx-auto'
              style={
                getDefaultPaperStyle({
                  height: '100%',
                  width: '100%',
                })
              }
            >
              <Stepper
                headers={_headers}
                toolBar={
                  <Button outlined minW120
                    label="SAVE"
                    onClick={this.submit}
                  />
                }
                views={[
                  <Basics parent={this} title={_headers[0]} />,
                  <Engineering parent={this} title={_headers[1]} />,
                  <WhyUs parent={this} title={_headers[2]} />,
                  <Contact parent={this} title={_headers[3]} />,
                  <Process parent={this} title={_headers[4]} />,
                ]}
                footerBar={
                  <Box row w100>
                    <Box row wAuto className='mr-auto'>
                      <Button outlined error minW120
                        onClick={this.goBack}
                      >
                        Cancel
                      </Button>
                    </Box>
                    <Box row w100 flex1 alignRight>
                      <Button acl={(this.state.step !== 1)}
                        variant='outlined'
                        color='secondary'
                        style={{ minWidth: 120 }}
                        className='mr-1'
                        onClick={this.previous}
                      >
                        Previous
                      </Button>
                      {
                        (
                          this.state.step === 5
                        ) ? (
                          <Button primary minW120
                            onClick={this.submit}
                          >
                            Done
                          </Button>
                        ) : (
                          <>
                            <Button outlined minW120 mr1
                              onClick={this.next}
                            >
                              Next
                            </Button>
                            <Button secondary minW120 mr1
                              onClick={this.submitThenGoNext}
                            >
                              Save & Next
                            </Button>
                          </>
                        )
                      }
                    </Box>
                  </Box>
                }
                step={this.state.step}
                onChange={(step) => this.setState({ step })}
              />
              <SuccessDialog Controller={this}
                actions={[
                  <Button outlined minW120
                    label="STAY"
                    onClick={(ev) => {
                      if (this.state.id) {
                        Core.go({ ...this.props, to: `/employer/edit/${this.state.id}` });
                      }
                    }}
                  />,
                  <Button primary autoFocus minW120
                    label="DONE"
                    onClick={(ev) => Core.go({ ...this.props, to: Core.isAdmin({ action: ACCOUNT_ACTION__LIST_EMPLOYERS }) ? '/employers/' : '/' })}
                  />,
                ]}
              />
              <SuccessDialog Controller={this} reference="confirmDialog"
                icon='help'
                iconColor={THEME__COLOR__PRIMARY}
                actions={[
                  <Button outlined minW120
                    label="CANCEL"
                  />,
                  <Button primary autoFocus minW120
                    label="CONFIRM"
                    onClick={(event) => {
                      this.confirm = true;
                      this.submit();
                    }}
                  />,
                ]}
              />
            </Paper>

          </Box>

          <Box column h100 w100
            role='EmployerEdit__RightSide'
            className='px-05 hidden-on-small-screen'
            style={{ maxWidth: 800 }}
          >
            <Paper
              className='mx-auto py-05'
              style={
                getDefaultPaperStyle({
                  height: '100%',
                  width: '100%',
                })
              }
            >

              <Fieldset
                title='Intake Note'
                actionBar={
                  <Button flat primary small
                    onClick={ev => {
                      this.setState({
                        intakeNote: `${EMPLOYER__INTAKE_NOTE}<br/>${this.state.intakeNote}`
                      });
                    }}
                    label='Insert template'
                    startIcon='arrow_downward'
                    className='c-purple-hover'
                  />
                }
                subtitle={EmployerUI.intakeNotes.warning}
              >

                <RichTextBox
                  value={this.state.intakeNote}
                  onChange={value => {
                    clearTimeout(Core.getKeyValue('employerStickyNote.timeout'));
                    this.setState({ intakeNote: value });
                    Core.setKeyValue('employerStickyNote.timeout', setTimeout(() => {
                      Employer.update(this.state.id, { intakeNote: String(value).trim() });
                    }, 1000));
                  }}
                  taller
                />

              </Fieldset>

            </Paper>

          </Box>

        </Box>

        <Snackbar
          open={!!this.state.snackbar}
          message={this.state.snackbar || ""}
          className="snack-bar"
          autoHideDuration={6000}
          onClose={(ev) => this.setState({ snackbar: null })}
        />
      </Page>
    );
  }
}

export default EmployerEdit;
