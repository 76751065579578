import {
  hasNoColor,
  hasNoMargin,
  hasNoSize,
  hasNoWeight,
  joinClassName
} from '../Libraries/Theme.lib';
import Typography from './Typography';

export default function Title({
  children = '',
  title = children,
  className = '',
  style = {},
  ...props
}) {
  return (
    <Typography
      className={joinClassName([
        hasNoColor({ className, style }) && 'c-black-darker',
        hasNoSize({ className, style }) && 'f-xl',
        hasNoMargin({ className, style }) && 'm-1',
        hasNoWeight({ className, style }) && 'fw-500',
        className
      ])}
      style={style}
    >
      {title}
    </Typography>
  )
}
