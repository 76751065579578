import {
  join
} from '../Array.lib';
import Definition, {
  DEFINITION_CATEGORY__TECHNICAL_SKILLS
} from '../Definition';


/**
 * If the job has an employer and jobTitle, they will be included in
 * the name string. If the job has roles, they will also be included
 * in the name string.
 * 
 * @param {Object} job - a job record
 * @returns {string} - ex. "Acme Corp - Software Engineer (Cloud Tech, Frontend)"
 */
export default function getJobEntityName(job) {
  const roles = Definition.getLabels(
    DEFINITION_CATEGORY__TECHNICAL_SKILLS,
    job.roles
  ).join(', ')
  return join([
    join([
      job.employer ? job.employer.name : '',
      job.jobTitle
    ], ' - '),
    roles ? `(${roles})` : ''
  ], ' ');
}
