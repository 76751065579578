import { isEqual } from 'lodash';
import {
  Component
} from "react";
import AppUI from '../../../dictionaries/AppUI.dic';
import {
  NOT
} from '../../../lib/Boolean.lib';
import Core from "../../../lib/Core";
import Definition from "../../../lib/Definition";
import ChipsFilterControl from '../../FilterControl/ChipsFilterControl';
import MenuBar from '../../FilterControl/MenuBar';
import {
  THEME__VARIANT__FLAT
} from '../../Layout/Libraries/Theme.lib';
import Autocomplete from '../../Layout/Wrappers/Autocomplete';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Checkbox from '../../Layout/Wrappers/Checkbox';
import DatePickerRange from '../../Layout/Wrappers/DatePickerRange';
import Dialog from '../../Layout/Wrappers/Dialog';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import Icon from '../../Layout/Wrappers/Icon';
import Menu from '../../Layout/Wrappers/Menu';
import Switch from '../../Layout/Wrappers/Switch';
import TextField from '../../Layout/Wrappers/TextField';
import Typography from '../../Layout/Wrappers/Typography';
import {
  MatchPageController
} from '../MatchPage';
import CompanySize from "./CompanySize";
import MenusSignals from './MenusSignals';

class NewFilterControl extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      chips: [],
      keywords: [],
      minimumSalary: this.props.minimumSalary,
      rangeExperience: this.props.rangeExperience,
      minimumCompanySize: this.props.minimumCompanySize || 0,
      sliderCandidateDuration: [0, 6],
      shouldShowBlacklisted: this.props.shouldShowBlacklisted,
      applyLooseMatch: true,
      toggleShouldShowBlacklisted: false,
      toggleApplyLooseMatch: false,
      stateFilter: [1],
    };
  }

  componentDidMount() {
    const { updateSliderValues } = this.props;
    const { applyLooseMatch } = this.state;

    if (applyLooseMatch) {
      updateSliderValues("applyLooseMatch", applyLooseMatch);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.minimumSalary !== prevProps.minimumSalary) {
      this.setState({ minimumSalary: this.props.minimumSalary });
    }
    if (
      NOT(
        isEqual(
          this.props.rangeExperience,
          prevProps.rangeExperience
        )
      )
    ) {
      this.setState({ rangeExperience: this.props.rangeExperience });
    }
    if (this.props.minimumCompanySize !== prevProps.minimumCompanySize) {
      this.setState({ minimumCompanySize: this.props.minimumCompanySize });
    }
  }

  checkLocations = ({ label, checked, cb }) => {
    cb();
  };

  jiraVER20 = ({
    label,
    menuKey,
    checked,
    fields = [],
    cb,
    shouldUpdateFilteredObjects = true,
  }) => {
    const { onChangeMenusHandler } = this.props;
    !!onChangeMenusHandler &&
      onChangeMenusHandler(
        label,
        menuKey,
        checked,
        [],
        cb,
        shouldUpdateFilteredObjects
      );
  };

  onChange = (e) => {
    //console.log("onchange");
  };

  cleanSearch = (ev) => {
    //console.log("clean search");
  };

  updateChip = (update) => {
    const { updateKeywordChip } = this.props;
    !!updateKeywordChip && updateKeywordChip(update);
  };
  ///////////////////////
  closeEventSalaryPopup = () => {
    this.setState({ showSalaryPopup: false });
  };

  applyEventSalaryPopup = (salary) => {
    const { updateSliderValues } = this.props;

    this.closeEventSalaryPopup();
    this.setState({ minimumSalary: salary }, () => {
      updateSliderValues("tempMinimumSalary", salary);
    });
  };

  onChangeEventSalaryPopup = (salary) => {
    this.setState({ minimumSalary: salary });
  };
  ///////////////////////
  closeEventExperiencePopup = () => {
    this.setState({ showExperiencePopup: false });
  };

  applyEventExperiencePopup = (rangeExperience) => {
    const { updateSliderValues } = this.props;
    this.closeEventExperiencePopup();
    this.setState({ rangeExperience }, () => {
      updateSliderValues("rangeExperience", rangeExperience);
    });
  };

  onChangeEventExperiencePopup = (rangeExperience) => {
    this.setState({ rangeExperience });
  };

  showSalaryPopup = () => {
    const { salaryPopup } = this.props;
    const { showSalaryPopup, minimumSalary } = this.state;

    if (!!showSalaryPopup && !!salaryPopup) {
      return salaryPopup(
        this.closeEventSalaryPopup,
        this.applyEventSalaryPopup,
        this.onChangeEventSalaryPopup,
        minimumSalary
      );
    }
    return null;
  };

  showExperiencePopup = () => {
    const { experiencePopup } = this.props;
    const { showExperiencePopup, rangeExperience } = this.state;

    if (!!showExperiencePopup && !!experiencePopup) {
      return experiencePopup(
        this.closeEventExperiencePopup,
        this.applyEventExperiencePopup,
        this.onChangeEventExperiencePopup,
        rangeExperience
      );
    }
    return null;
  };

  handlerMoreFiltersApply() {
    const {
      updateFilteredObjects,
      fetchMatches,
      handlerToggleShouldShowBlacklisted,
      updateSliderValues,
    } = this.props;
    const {
      toggleShouldShowBlacklisted,
      shouldShowBlacklisted,
      toggleApplyLooseMatch,
      applyLooseMatch,
      stateFilterChanged,
      stateFilter,
      stateFilterChangedCb,
      dateRange
    } = this.state;

    updateFilteredObjects();
    if (this.state.durationChanged) {
      fetchMatches({ dateRange });
    }

    if (toggleShouldShowBlacklisted) {
      handlerToggleShouldShowBlacklisted(shouldShowBlacklisted);
    }

    if (toggleApplyLooseMatch) {
      updateSliderValues("applyLooseMatch", applyLooseMatch);
    }

    if (stateFilterChanged) {
      fetchMatches({ state: stateFilter });
      stateFilterChangedCb && stateFilterChangedCb();
    }

    this.setState({
      showMoreFilter: false,
      durationChanged: false,
      toggleShouldShowBlacklisted: false,
      toggleApplyLooseMatch: false,
    });
  }

  render() {

    const {
      menus,
      more,
      chips,
      sources,
      fetchMatches
    } = this.props;

    const {
      showMoreFilter,
      shouldShowBlacklisted,
      applyLooseMatch,
      stateFilter,
      dateRange = []
    } = this.state;

    this.matchingPage = true;

    Core.setKeyValue('NewFilterControlController', this);

    const _setSelected = (name) => {
      if (
        !MatchPageController().state.keywords.find(
          (keyword) => (keyword.name === name)
        )
      ) {
        this.updateChip({
          name,
          keyword: true
        });
      }
    };

    const FieldsetCommonProps = {
      className: 'outlined rounded mr-1',
      style: { minWidth: '48%', minHeight: 120 }
    };

    const _onDelete = (item, index) => {
      setTimeout(() => {
        if (item.key === "state") {
          const stateId = +Definition.getId(item.key, item.name);
          const stateFilterNew = stateFilter.filter(
            (stateIdF) => +stateIdF !== +stateId
          );
          this.setState({ stateFilter: stateFilterNew }, () => {
            fetchMatches({ state: stateFilterNew });
          });
        }
      });
      this.props.deleteChip(item, index);
    }

    return (
      <Box column w100 className='px-3 border-bottom'>

        <Box row w100 noWrap scrollY spaceBetween
          className="bg-white outlined rounded-sm"
        >

          {/** CHIPS BAR */}
          <Box row noWrap scrollX className='py-05'>

            <Icon
              icon='filter_list'
              className='f-lg mx-1 mr-2'
              title={
                <Box row wrap className='px-1 py-05 bg-white-common rounded'>
                  <ChipsFilterControl
                    name='NewFilterControl__Chips__Tooltip'
                    list={chips}
                    onDelete={_onDelete}
                  />
                </Box>
              }
            />

            <Typography acl={NOT(chips.length)} className="placeholder">
              Select Filters
            </Typography>

            <ChipsFilterControl
              name='NewFilterControl__Chips'
              list={chips}
              onDelete={_onDelete}
            />

          </Box>

          <Box row noWrap scrollX style={{ width: 360 }}>

            <Autocomplete
              name="search"
              placeholder={AppUI.placeholder.filterBar}
              options={sources}
              onSelect={_setSelected}
              variant={THEME__VARIANT__FLAT}
              clearOnSelect
            />

            {/** SAVE AN CLEAN BUTTONS */}
            {!!this.state.chips.length && (
              <Box row wAuto noWrap className='p-05'>
                {Core.isAdminOrCoordinator() && (
                  <Button outlined minW120 mr1 small
                    label="Save Search"
                    onClick={this.storeState}
                  />
                )}
                <Button outlined minW120 small warning
                  label="Clear All"
                  onClick={this.cleanSearch}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box row w100 noWrap scrollX className='py-05'>

          <MenuBar FilterControlController={this} menus={menus}>

            {/** POSITIVE & NEGATIVE SIGNALS */}
            <MenusSignals />

            <Menu.Item
              key={`menu1`}
              className="f-sm"
              onClick={(ev) =>
                this.setState({ showSalaryPopup: true })
              }
            >
              Salary
              <Icon icon='arrow_drop_down' />
            </Menu.Item>
            {this.showSalaryPopup()}
            <Menu.Item
              key={`menu2`}
              className="f-sm"
              onClick={(ev) =>
                this.setState({ showExperiencePopup: true })
              }
            >
              Experience
              <Icon icon='arrow_drop_down' />
            </Menu.Item>

            {this.props.matchKey === "job" && (
              <CompanySize
                minimumCompanySize={this.state.minimumCompanySize}
                updateSliderValues={this.props.updateSliderValues}
                companySizePopup={this.props.companySizePopup}
              />
            )}
            {
              /** MORE FILTERS */
              !!more.length && (
                <Menu.Item
                  className="f-sm"
                  onClick={(event) => this.setState({ showMoreFilter: true })}
                >
                  More Filters
                  <Icon icon='arrow_drop_down' />
                </Menu.Item>
              )
            }
            {this.showExperiencePopup()}
          </MenuBar>

          <Dialog
            open={showMoreFilter}
            title='More filters'
            actionBar={
              <TextField disabledBlurExport required autoFocus
                name="searchFilter"
                className="my-05 min-w-240 mr-2"
                placeholder='Search a filter'
                value={this.state.search}
                onChange={(event, search) => this.setState({ search })}
              />
            }
            content={
              <Box row wrap h100 w100 scrollY
                className='flex-align-left-top'
              >

                {(this.props.matchKey === "candidate") &&
                  <Fieldset
                    key={`menu-duration`}
                    title='Duration'
                    wrapperProps={FieldsetCommonProps}
                  >
                    <DatePickerRange v2
                      values={dateRange}
                      onChange={(values) => {
                        this.setState({
                          dateRange: values,
                          durationChanged: true
                        });
                      }}
                    />
                  </Fieldset>
                }

                {(this.props.matchKey === "job") &&
                  <Fieldset
                    key={`menu-duration`}
                    title='State'
                    wrapperProps={FieldsetCommonProps}
                  >
                    <Box row wrap scrollX>
                      {more.filter(
                        (el) => (el.key === "state")
                      ).map(
                        (menu) => Object.keys(menu.items || {}).sort().filter(
                          (label) => new RegExp(this.state.search, "i").test(label)
                        ).map(
                          (label) => (
                            <Checkbox primary
                              key={`new_filter_control__menu__${menu.key}`}
                              className='mr-2'
                              checked={Object(menu.items)[label]}
                              label={label}
                              onChange={(event) => {
                                menu.items[label] = !menu.items[label];
                                const labelId = +Definition.getId(
                                  "state",
                                  label
                                );
                                this.setState((state) => state);
                                let stateFilter = [
                                  ...this.state.stateFilter,
                                  labelId,
                                ];
                                if (!menu.items[label]) {
                                  stateFilter = stateFilter.filter(
                                    (id) => id !== labelId
                                  );
                                }
                                this.setState({
                                  stateFilter,
                                  stateFilterChanged: true,
                                  stateFilterChangedCb: () => {
                                    this.checkLocations({
                                      label,
                                      checked: menu.items[label],
                                      cb: (then) =>
                                        this.jiraVER20({
                                          label,
                                          menuKey: menu.key,
                                          checked: menu.items[label],
                                          cb: this.onChange(),
                                          shouldUpdateFilteredObjects: false,
                                        }),
                                    });
                                  },
                                });
                              }}
                            />
                          )
                        )
                      )}
                    </Box>
                  </Fieldset>
                }

                {
                  more.filter((el) => (el.key !== "state")).map((menu) => {
                    return menu.visible === false ? '' : (
                      <Fieldset
                        key={`new_filter_control__more_menu__${menu.key}`}
                        title={menu.label}
                        wrapperProps={FieldsetCommonProps}
                      >
                        <Box row wrap scrollX>
                          {Object.keys(menu.items || {})
                            .sort()
                            .filter((label) =>
                              new RegExp(this.state.search, "i").test(label)
                            )
                            .map((label) => (
                              <Checkbox primary
                                key={`listItem${label}`}
                                className='mr-2'
                                checked={Object(menu.items)[label]}
                                label={
                                  label === "State: Lead" ? "Lead" : label
                                }
                                onChange={(event) => {
                                  menu.items[label] = !menu.items[label];
                                  this.setState((state) => state);

                                  this.checkLocations({
                                    label,
                                    checked: menu.items[label],
                                    cb: (then) =>
                                      this.jiraVER20({
                                        label,
                                        menuKey: menu.key,
                                        checked: menu.items[label],
                                        cb: this.onChange(),
                                        shouldUpdateFilteredObjects: false,
                                      }),
                                  });
                                }}
                              />
                            ))}
                        </Box>
                      </Fieldset>
                    );
                  })
                }

                <Fieldset
                  wrapperProps={FieldsetCommonProps}
                >
                  <Switch
                    checked={shouldShowBlacklisted}
                    onChange={(event) => {
                      this.setState({
                        shouldShowBlacklisted: event.target.checked,
                        toggleShouldShowBlacklisted: true,
                      });
                    }}
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    label='Show Blacklisted'
                  />
                  <Switch
                    name="checkedA"
                    title='secondary checkbox'
                    checked={applyLooseMatch}
                    onChange={(event) => {
                      this.setState({
                        applyLooseMatch: event.target.checked,
                        toggleApplyLooseMatch: true,
                      });
                    }}
                  >
                    Loose Match Expanded Search
                  </Switch>
                </Fieldset>

              </Box>
            }
            actions={[
              <Button outlined minW120
                label="Cancel"
                onClick={() => this.setState({ showMoreFilter: false })}
              />,
              <Button primary minW120
                label="Apply"
                onClick={(ev) => {
                  this.handlerMoreFiltersApply();
                }}
              />
            ]}
            paperStyle={{ width: 'calc(100vw - 2rem)' }}
          />

        </Box>
      </Box>
    );
  }
}

export default NewFilterControl;

export function NewFilterControl__state(update = {}, callback = () => null) {
  let state = Core.getKeyValue('NewFilterControlController')?.state;
  return state;
}

export function NewFilterControl__updateState(update = {}, callback = () => null) {
  let method = Core.getKeyValue('NewFilterControlController')?.setState;
  return (method instanceof Function ? method : (() => null))(update, callback);
}
