import {
  TextField as MuiTextField
} from '@mui/material';
import React from 'react';
import AppUI from '../../../dictionaries/AppUI.dic';
import {
  Fun
} from '../../../lib/Function.lib';
import useStateObserved from '../../../lib/hooks/useStateObserved.hook';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  Str
} from '../../../lib/String.lib';
import {
  joinClassName,
  mapWrapper,
  THEME__VARIANT__OUTLINED,
  THEME__VARIANT__STANDARD
} from '../Libraries/Theme.lib';

/**
 * TextField component for handling text input with various configurations.
 * 
 * @see https://v5.mui.com/material-ui/react-text-field/
 *
 * @param {Object} params - Parameters for the TextField component.
 * @param {boolean} [params.acl=true] - Access control flag, determines if the component should render.
 * @param {boolean} [params.debug=false] - Debug flag for logging additional information.
 * @param {string} [params.autoComplete=''] - Autocomplete behavior.
 * @param {boolean} [params.autoFocus=false] - Determines if the input should autofocus.
 * @param {string} [params.floatingLabelText=''] - Text for the floating label.
 * @param {number} [params.rows] - Number of rows for multiline input.
 * @param {number} [params.rowsMax] - Maximum number of rows for multiline input.
 * @param {number} [params.rowsMin] - Minimum number of rows for multiline input.
 * @param {number} [params.maxRows] - Maximum rows for auto-sizing textarea.
 * @param {number} [params.minRows] - Minimum rows for auto-sizing textarea.
 * @param {boolean} [params.multiLine] - Legacy prop to enable multiline input.
 * @param {boolean} [params.multiline] - Enables multiline input.
 * @param {Object} [params.autoSize={}] - Auto-sizing configuration for the textarea.
 * @param {string} [params.placeholder=''] - Placeholder text for the input.
 * @param {string} [params.label=''] - Label for the input field.
 * @param {string} [params.value=''] - Initial value of the input field.
 * @param {string} [params.type='text'] - Type of the input field.
 * @param {boolean} [params.fullWidth=true] - Determines if the input should span the full width.
 * @param {boolean} [params.outlined=false] - Flag to render the input with an outlined variant.
 * @param {Function} [params.onChange=(event, value) => null] - Callback for when the content changes.
 * @param {Function} [params.onBlur=null] - Callback for when the input loses focus.
 * @param {Function} [params.onKeyDown=null] - Callback for handling keydown events.
 * @param {Function} [params.onKeyPress=null] - Callback for handling keypress events.
 * @param {string} [params.errorText=''] - Error message displayed below the input.
 * @param {string} [params.variant] - Variant of the input (e.g., 'outlined', 'filled', 'standard').
 * @param {string} [params.size='medium'] - Size of the input field ('small' or 'medium').
 * @param {boolean} [params.small=false] - Flag to render a smaller version of the input field.
 * @param {string} [params.className=''] - Additional class names for the input.
 * @param {boolean} [params.exportOnBlur=true] - Determines if the value should be exported on blur instead on change.
 * @param {boolean} [params.disabledBlurExport=false] - Disables exporting on blur when true.
 * @param {Object} [params.wrapperProps={}] - Additional props for the wrapper element.
 * @param {...Object} props - Additional properties passed to the component.
 * @param {React.Ref} ref - Ref for the input field.
 *
 * @returns {JSX.Element|null} - The TextField component or null if acl is false.
 */
function TextField({
  acl = true,
  debug = false,
  autoComplete = '',
  autoFocus = false,
  floatingLabelText = '',
  rows,
  rowsMax,
  rowsMin,
  maxRows,
  minRows,
  multiLine,
  multiline,
  autoSize,
  placeholder,
  label,
  value: propsValue = '',
  type = 'text',
  fullWidth = true,
  outlined = false,
  onChange = (event, value) => null,
  onBlur = null,
  onKeyDown = null,
  onKeyPress = null,
  errorText = '',
  variant,
  size = 'medium',
  small,
  className = '',
  exportOnBlur = true,
  disabledBlurExport = false,
  wrapperProps = {},
  ...props
}, ref) {
  const defaultState = {
    value: propsValue
  };
  const [{
    value = ''
  }, updateState] = useStateObserved({
    state: defaultState,
    observed: defaultState
  });

  if (!acl) { return null; }

  if (disabledBlurExport) {
    exportOnBlur = false;
  }

  const _exportValue = async (event) => {
    const value = Str(event.target.value);
    console.debug(
      'TextField__exportValue...',
      '\n', event,
      '\n', (type === 'password') ? ''.padEnd(value.length, '*') : value,
    );
    await updateState({ value });
    if (value !== propsValue) {
      onChange(event, value);
    }
  };

  const _onChange = async (event) => {
    if (exportOnBlur) {
      return updateState({
        value: Str(event.target.value)
      });
    }
    else {
      return _exportValue(event);
    }
  };

  const _onBlur = async (event) => {
    console.debug(
      'BLUR',
      '\n', event
    );
    if (exportOnBlur) {
      await _exportValue(event);
    }
    Fun(onBlur)(event);
  };

  const _onKeyDown = async (event) => {
    // Stop the propagation of the event since some keys, like "b", trigger the blur event unexpectedly.
    event.stopPropagation();
    console.debug(
      'KEYDOWN',
      '\n', event
    );
    if (
      (
        exportOnBlur ||
        multiLine
      ) &&
      !!Str(event.key).match(/enter|tab/i)
    ) {
      await _exportValue(event);
    }
    Fun(onKeyDown)(event);
    Fun(onKeyPress)(event);
  };

  autoSize = Obj(autoSize);
  label = label || floatingLabelText
  maxRows = maxRows || rowsMax || autoSize.maxRows;
  minRows = minRows || rowsMin || rows || autoSize.minRows;
  rows = (minRows || maxRows) ? undefined : rows;
  multiline = multiline || multiLine || !!(minRows || maxRows);
  if (multiline) {
    placeholder = placeholder || AppUI.placeholder.multiline;
    variant = variant || THEME__VARIANT__OUTLINED;
  }
  else {
    placeholder = placeholder || AppUI.placeholder.text;
  }
  if (small) { size = 'small'; }
  if (errorText) { errorText = <i>{errorText}</i> }
  if (outlined) {
    variant = THEME__VARIANT__OUTLINED;
  }

  className = [
    (
      (
        multiLine &&
        (variant === THEME__VARIANT__STANDARD)
      ) &&
      'bg-transparent'
    ),
    className
  ];

  return (
    <MuiTextField
      {...mapWrapper(
        {
          role: 'TextField',
          props: wrapperProps,
          assign: {
            ...props,
            autoComplete,
            autoFocus,
            value,
            type,
            fullWidth,
            onChange: _onChange,
            onBlur: _onBlur,
            onKeyDown: _onKeyDown,
            onKeyPress: _onKeyDown,
            error: !!errorText,
            helperText: errorText,
            variant,
            size,
            label,
            multiline,
            rows,
            minRows,
            maxRows,
            placeholder,
            className: joinClassName(className)
          }
        }
      )}
      ref={ref}
    />
  );
}

/**
 * @function
 * @param {Object} props Component props
 * @param {Ref<TextFieldElement>} [ref] Reference to component
 * @returns {ReactElement} Component
 * @description
 * TextField component.
 * 
 * @prop {string} [autoComplete] Sets the autocomplete attribute. Default: 'off'
 * @prop {boolean} [autoFocus] If `true`, the input will be focused during the first mount. Default: `false`
 * @prop {string} [className] CSS class(es)
 * @prop {boolean} [disabled] If `true`, the input will be disabled. Default: `false`
 * @prop {boolean} [error] If `true`, the input will indicate an error. Default: `false`
 * @prop {string} [errorText] The text to display when `error` is set
 * @prop {boolean} [fullWidth] If `true`, the input will take up the full width of its container. Default: `false`
 * @prop {string} [label] The label content
 * @prop {boolean} [multiLine] If `true`, the input will be a textarea. Default: `false`
 * @prop {number} [maxRows] Maximum number of rows. Default: `6`
 * @prop {number} [minRows] Minimum number of rows. Default: `1`
 * @prop {string} [name] The input name
 * @prop {string} [placeholder] The short hint displayed in the input before the user enters a value. Default: `AppUI.placeholder.text`
 * @prop {boolean} [outlined] If `true`, the input will be outlined. Default: `false`
 * @prop {number} [rows] Number of rows. Default: `1`
 * @prop {boolean} [small] If `true`, compact padding designed for dense layouts. Default: `false`
 * @prop {string} [type] The input type. Default: `text`
 * @prop {string} [value] The input value
 * @prop {function} [wrapperProps] Additional props to be spread in the wrapper element
 */
export default React.forwardRef(TextField);
