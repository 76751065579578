import {
  YES
} from '../../../../../../../lib/Boolean.lib';
import Definition, {
  DEFINITION_CATEGORY__LOCATION,
  IN_OFFICE_REMOTE__FULL_WEEK_ID,
  IN_OFFICE_REMOTE__PART_WEEK_ID,
  IN_OFFICE_REMOTE__REMOTE_ONLY_ID
} from '../../../../../../../lib/Definition';
import LocationLib from '../../../../../../../lib/DefinitionLocation.lib';

export default function getLocationMatches({
  job = {},
  candidate = {}
}) {

  const notMatches = [];
  const matches = [];

  const isJobRemoteWork = YES(
    job.inOfficeRemoteFlags?.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)
  );

  const isJobInOfficeWork = YES(
    job.inOfficeRemoteFlags?.includes(IN_OFFICE_REMOTE__PART_WEEK_ID) ||
    job.inOfficeRemoteFlags?.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID)
  );

  const isCandidateRemoteWork = YES(
    candidate.inOfficeRemoteFlags?.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)
  );

  const isCandidateInOfficeWork = YES(
    candidate.inOfficeRemoteFlags?.includes(IN_OFFICE_REMOTE__PART_WEEK_ID) ||
    candidate.inOfficeRemoteFlags?.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID)
  );

  if (isJobInOfficeWork) {
    job.officeLocations?.forEach((jobOfficeLocationId) => {
      const label = `Office: ${Definition.getLabel(
        DEFINITION_CATEGORY__LOCATION,
        jobOfficeLocationId
      )}`;
      if (
        isCandidateInOfficeWork &&
        candidate.officeLocations?.length
      ) {
        candidate.officeLocations?.forEach(
          (candidateOfficeLocationId) => {
            if (
              LocationLib.evalLineage({
                memberA: jobOfficeLocationId,
                memberB: candidateOfficeLocationId
              })
            ) {
              matches.push(label);
            }
            else {
              notMatches.push(label);
            }
          }
        );
      }
      else {
        notMatches.push(label);
      }
    });
  }
  else {
    candidate.officeLocations?.forEach(
      (candidateOfficeLocationId) => {
        const label = `Office: ${Definition.getLabel(
          DEFINITION_CATEGORY__LOCATION,
          candidateOfficeLocationId
        )}`;
        notMatches.push(label);
      }
    );
  }

  if (isJobRemoteWork) {
    job.candidateLocations?.forEach((jobCandidateLocationId) => {
      const label = `WFH: ${Definition.getLabel(
        DEFINITION_CATEGORY__LOCATION,
        jobCandidateLocationId
      )}`;
      if (
        isCandidateRemoteWork &&
        candidate.candidateLocations?.length
      ) {
        candidate.candidateLocations?.forEach(
          (candidateCandidateLocationId) => {
            if (
              LocationLib.evalLineage({
                memberA: jobCandidateLocationId,
                memberB: candidateCandidateLocationId
              })
            ) {
              matches.push(label);
            }
            else {
              notMatches.push(label);
            }
          }
        );
      }
      else {
        notMatches.push(label);
      }
    });
  }
  else {
    candidate.candidateLocations?.forEach(
      (candidateCandidateLocationId) => {
        const label = `WFH: ${Definition.getLabel(
          DEFINITION_CATEGORY__LOCATION,
          candidateCandidateLocationId
        )}`;
        notMatches.push(label);
      }
    );
  }

  return {
    yes: matches,
    not: notMatches
  };

}
