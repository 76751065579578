import moment from 'moment';
import {
  join
} from '../../../../lib/Array.lib';
import {
  PLAINTEXT__LOG__SEPARATOR
} from '../../../../lib/Constants';
import {
  DATE_FORMAT__DATETIME__US_24H,
  TIMEZONE__LA
} from '../../../../lib/Date.lib';
import Engagement from '../../../../lib/Engagement';
import globalErrorHandler from '../../../../lib/Error/globalErrorHandler.fun';
import {
  COLLECTION__ENGAGEMENTS,
  readLoopbackRecord
} from '../../../../lib/services/BE/loopback.api';
import {
  compileText,
  Str,
  trim
} from '../../../../lib/String.lib';
import {
  ACTION_TYPE__ACCEPT,
  ACTION_TYPE__ACCEPT_STRONG,
  ACTION_TYPE__ACCEPT_WEAK,
  ACTION_TYPE__FEEDBACK_TO_RECRUITER,
  ACTION_TYPE__REJECT,
  ACTION_TYPE__REJECT_STRONG,
  ACTION_TYPE__REJECT_WEAK
} from './EmployerPendings.dic';



/**
 * Prepends a log entry of engagement feedback to the recruiter's feedback text.
 *
 * @param {object} options - The options for the function.
 * @param {string} options.engagementId - The ID of the engagement to update.
 * @param {object} options.context - The context for the feedback entry.
 * @param {string} options.context.ACTION - The action type, e.g., feedback to recruiter or other action types.
 * @param {string} [options.context.ROLE='Employer'] - The role of the person providing feedback.
 * @param {string} [options.context.PRESETS] - Optional presets for accepted or rejected actions.
 * @param {string} [options.context.NOTE] - Optional note to include in the feedback entry.
 * 
 * @returns {Promise<object|string>} The result of the engagement update operation or an error message if failed.
 * 
 * Note ex.
 * `Update - Employer | 01/01/2023 12:00 AM\nNote: This is a note.`
 */
export default async function prependLogEngagementFeedbackToRecruiter(options) {
  console.debug('prependLogEngagementFeedbackToRecruiter', options);
  try {
    const { engagementId, context } = options;
    const engagement = await readLoopbackRecord({
      collection: COLLECTION__ENGAGEMENTS,
      where: { id: engagementId },
      fields: [
        'id',
        'toRecruiterFBText',
      ],
      limit: 1
    });
    const ROLE = context.ROLE || 'Employer';
    const ACTION = (
      context.ACTION === ACTION_TYPE__FEEDBACK_TO_RECRUITER
        ? 'Update' : context.ACTION
    );
    const YES__ACCEPTED = [
      ACTION_TYPE__ACCEPT,
      ACTION_TYPE__ACCEPT_WEAK,
      ACTION_TYPE__ACCEPT_STRONG
    ].includes(context.ACTION);
    const YES__REJECTED = [
      ACTION_TYPE__REJECT,
      ACTION_TYPE__REJECT_WEAK,
      ACTION_TYPE__REJECT_STRONG
    ].includes(context.ACTION);
    const _note = Str(context.NOTE).replace(/\n/g, ' ').trim();
    const _presets = trim(context.PRESETS);
    const _entry = compileText('{{ACTION}} - {{ROLE}} | {{DATE_TIME}}{{PRESETS}}{{NOTE}}', {
      ...context,
      ROLE,
      ACTION,
      DATE_TIME: `${(
        moment.tz(TIMEZONE__LA).format(DATE_FORMAT__DATETIME__US_24H)
      )} PT`,
      PRESETS: _presets ? (
        YES__ACCEPTED ? `\nNext step: ${_presets}`
          : YES__REJECTED ? `\nReasons: ${_presets}` : ''
      ) : '',
      NOTE: _note ? `\nNote: ${_note}` : '',
    })
    const update = {
      toRecruiterFBText: join(
        [_entry, trim(engagement.toRecruiterFBText)],
        PLAINTEXT__LOG__SEPARATOR
      )
    };
    return await Engagement.update(engagement, update);
  }
  catch (error) {
    globalErrorHandler(error);
    return 'prependEngagementFeedbackLog:NO entry added';
  }
}
